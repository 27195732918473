import React from "react";
import Header2 from "../../layout/header2";
import Sidebar from "../../layout/sidebar";
import PageTitle from "../../element/page-title";
import ToasterContainer from "../../component/ToasterContainer";
import { gettingAddressFromWalletApi, gettingTransactionForCryptoCurrency} from "../../../store/BasicInfo/basicInfoSlice";
import useDocumentTitle from "../../../helper/useTitle";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import dummy from "../../../util/images/dummy.png"
import { Link, useLocation } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { capitalizeFirstLowercaseRest } from "../../component/helper";
import moment from "moment";
import OrderPlace from "../../component/placeOrder/orderPlace";

export default function AccountDepositCrypto() {
    useDocumentTitle("Wallet Currency | Exchange");
    const {state}=useLocation();
  
    const currency_id=state?.currency_id?.id;
    const currency_name=state?.currency_id?.name;
   
    const [walletAddress,setWalletAddress]=useState("");
    const [transaction,setAllTransction]=useState("");
    const dispatch=useDispatch();

    const {isWalletAddress, WalletAddressDetail, isTransactionStatus, cryptoTransactionDetail}=useSelector((state) => state.basic);

    const columns = [
        {
            dataField: currency_id,
            text: "Currency Name",
            sort: true,
            formatter: (cell, row) => {
                return (
                    <>{currency_name}</>
                )
                //return (<>{cell ? cell : 'N/A'}</>)
            }
        },
        {
            dataField: "amount_type",
            text: "Amount Type",
            sort: true,
            formatter: (cell, row) => {
                return (
                    <>{cell ? capitalizeFirstLowercaseRest(cell) : 'N/A'}</>
                )
                //return (<>{cell ? cell : 'N/A'}</>)
            }
        },
        {
            dataField: "value",
            text: "Value",
            formatter: (cell, row) => {
                return <>{cell ? cell : 'N/A'}</>
            }
        },
        {
            dataField: "type",
            text: "Type",
            formatter: (cell, row) => {
                return <>{cell ? capitalizeFirstLowercaseRest(cell) : 'N/A'}</>
            }
        },
        {
            dataField: "fee",
            text: "Fee",
            formatter: (cell, row) => {
                return <>{row?.fee}</>
            }
        },
        {
            dataField: "amount",
            text: "Amount",
            formatter: (cell, row) => {
                return <>{row?.amount}</>
            }
        },
        {
            dataField: "txid",
            text: "Transaction Id",
            formatter: (cell, row) => {
                return <>{cell?cell:'N/A'}</>
            }
        },
        {
            dataField: "createdAt",
            text: "Date",
            formatter: (cell, row) => {
                return <>
                {
                    cell ? moment(cell).format("DD/MM/YYYY hh:mm:ss") : 'N/A'
                }
                </>
            }
        },
        {
            dataField: "paid",
            text: "Paid Status",
            formatter: (cell, row) => {
                return <>
                {
                    +cell===0 ? (<div className="badge bg-danger">Unpaid</div>): +cell===1 ? (<div className="badge bg-success">Paid</div>) : ''
                }
                </>
            }
        },
        {
            dataField: "status",
            text: "Payment Status",
            formatter: (cell, row) => {
                return <>
                {
                    +cell===0 ? (<div className="badge bg-danger">Unpaid</div>): +cell===1 ? (<div className="badge bg-success">Paid</div>) : ''
                }
                </>
            }
        },
    ];

    //api consume for getting address detail is here
    useEffect(() => {
        dispatch(gettingAddressFromWalletApi({currency_id:currency_id}))
    },[])

    //update the state while getting the data from server
    useEffect(() => {
        if(isWalletAddress && WalletAddressDetail) {
            setWalletAddress(WalletAddressDetail?.data)
        }
    },[isWalletAddress, WalletAddressDetail])

    //consume the api transaction getting based on crypto currency
    useEffect(() => {
        dispatch(gettingTransactionForCryptoCurrency({currency_id:currency_id}));
    },[])
    //end of transaction api for based on crhypto currency

    //getting selector record from backend as per transaction rule
    useEffect(() => {
        if(isTransactionStatus && cryptoTransactionDetail) {
            setAllTransction(cryptoTransactionDetail?.data)
        }
    },[isTransactionStatus, cryptoTransactionDetail])

    console.log("________________________________________________transaction is here",transaction)
    return (
        <>
        <Header2 />
            <Sidebar />
            <PageTitle />
            <ToasterContainer />
            <div className="content-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-9">
                        <div class="card-header">
                                <h4 class="card-title">Wallet Deposit Address</h4>
                            </div>
            <div class="tab-content">
                                    <div class="tab-pane fade show active" id="tab1">
                                        <div class="qrcode">
                                            <img draggable={false} src={walletAddress?.qr_code_link || dummy} alt="wallet_qr_code" width="150" />
                                        </div>
                                        <form action="">
                                            <div class="input-group">
                                                <input type="text" class="form-control" value={walletAddress?.address} />
                                                <div class="input-group-append">
                                                    <span class="input-group-text bg-primary text-white">Copy</span>
                                                </div>
                                            </div>
                                        </form>

                                        <ul>
                                            <li>
                                                <i class="mdi mdi-checkbox-blank-circle"></i>
                                                TUSD network transfers will be credited to your Tradio account after
                                                25 network confirmations.
                                            </li>
                                            <li>
                                                <i class="mdi mdi-checkbox-blank-circle"></i>
                                                TUSD deposits to this address are unlimited. Note that you may not
                                                be able to withdraw all of your funds at once if you deposit more
                                                than your daily withdrawal limit.
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="tab-pane fade" id="tab2">
                                        <div class="qrcode">
                                            <img src="./images/qr.svg" alt="" width="150" />
                                        </div>
                                        <form action="">
                                            <div class="input-group">
                                                <input type="text" class="form-control" value="0xceb1b174085b0058201be4f2cd0da6a21bff85d4" />
                                                <div class="input-group-append">
                                                    <span class="input-group-text bg-primary text-white">Copy</span>
                                                </div>
                                            </div>
                                        </form>

                                        <ul>
                                            <li>
                                                <i class="mdi mdi-checkbox-blank-circle"></i>
                                                USDC network transfers will be credited to your Tradio account after
                                                25 network confirmations.
                                            </li>
                                            <li>
                                                <i class="mdi mdi-checkbox-blank-circle"></i>
                                                USDC deposits to this address are unlimited. Note that you may not
                                                be able to withdraw all of your funds at once if you deposit more
                                                than your daily withdrawal limit.
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="tab-pane fade" id="tab3">
                                        <div class="qrcode">
                                            <img src="./images/qr.svg" alt="" width="150" />
                                        </div>
                                        <form action="">
                                            <div class="input-group">
                                                <input type="text" class="form-control" value="0xceb1b174085b0058201be4f2cd0da6a21bff85d4" / >
                                                <div class="input-group-append">
                                                    <span class="input-group-text bg-primary text-white">Copy</span>
                                                </div>
                                            </div>
                                        </form>

                                        <ul>
                                            <li>
                                                <i class="mdi mdi-checkbox-blank-circle"></i>
                                                USDC network transfers will be credited to your Tradio account after
                                                25 network confirmations.
                                            </li>
                                            <li>
                                                <i class="mdi mdi-checkbox-blank-circle"></i>
                                                USDC deposits to this address are unlimited. Note that you may not
                                                be able to withdraw all of your funds at once if you deposit more
                                                than your daily withdrawal limit.
                                            </li>
                                        </ul>
                                    </div>
            </div>
           
            </div>
            <OrderPlace/>
            </div>
            <div className="container-fluid">
            <div className="col-md-12">
            <BootstrapTable keyField='id' classes="custom-ticket-code" noDataIndication={() => { return 'No Data Found' }} columns={columns} data={transaction && transaction} bordered={true} pagination={transaction && paginationFactory({
                                page: 1,
                                sizePerPage: 10,
                                firstPageText: '>>',
                                nextPageText: '<<',
                                prePageText: '<',
                                showTotal: true,
                                alwaysShowAllBtns: true,
                                onPageChange: function (page, sizePerPage) {
                                    console.log("page is here", page)
                                },
                                onSizePerPageChange: function (page, sizePerPage) {
                                    console.log("page", page)
                                }
                            })} />
            </div>
            </div>
            </div>
            
           
            </div>
       
        </>
    )
}