import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import show from "../../util/images/show.png"
import hide from "../../util/images/hide.png"
import { createRegister } from "../../store/BasicInfo/basicInfoSlice";
import { useDispatch, useSelector } from "react-redux";
import ToasterContainer from "../component/ToasterContainer";
import LoadingOverlay from 'react-loading-overlay';
import tick from "../../util/images/tick.png"
import square from "../../util/images/rectangle.png"
import useDocumentTitle from "../../helper/useTitle"

function Signup() {
    useDocumentTitle('Sign up | Exchange')
    const [values, setValues] = useState({ sponsor_code: "", first_name: "", last_name: "", middle_name: "", mobile_no: "", email: "", password: "", confirm_password: "" });
    const [Validations, setValidation] = useState({ sponsor_code: "", first_name: "", last_name: "", middle_name: "", mobile_no: "", email: "", password: "", confirm_password: "" });
    const [isActive, isSetActive] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [signUpBtn,setSignUpBtn]=useState("Sign Up")
    const navigate = useNavigate();
    const { isRegister, message, registerDetail } = useSelector((state) => state.basic);
    const [isVisible, setVisible] = useState(false);
    const [isVisible1, setVisible1] = useState(false);
    const [checkIcon, setCheckIcon] = useState(false);

    const togglehit = () => {
        setVisible(!isVisible);
    }
    const toggle1 = () => { setVisible1(!isVisible1); };

    const handleCheckBox = () => {
        setCheckIcon(!checkIcon)
    }

    const previousProps = useRef({ isRegister }).current;

    useEffect(() => {
        if (previousProps.isRegister != isRegister  && registerDetail?.message==="User has been register successfully") {
            setSignUpBtn("Processing Please wait...");
            isSetActive(true);
            setIsDisabled(true);
            
            setTimeout(() => {
                navigate("/signin")
            }, 3000)
        }
    }, [registerDetail, isRegister])

    const dispatch = useDispatch();

    function validateAll() {
        let Validations = {};
        let isValid = true;

        if (!values.first_name) {
            Validations.first_name = "Please enter the first name"
            isValid = false
        }
        if (!values.last_name) {
            Validations.last_name = "Please enter the last name"
            isValid = false
        }
        if (!values.email) {
            Validations.email = "Please enter the email address"
            isValid = false
        }

        if (!values.password) {
            Validations.password = "Please enter the password"
            isValid = false
        }

        if (!values.confirm_password) {
            Validations.confirm_password = "Please enter the confirm password"
            isValid = false
        }
        if (!values.mobile_no) {
            Validations.mobile_no = "Please enter the mobile number";
            isValid = false
        }
        if (values?.mobile_no?.length < 10) {
            Validations.mobile_no = "Please enter the mobile greater then 10 character"
            isValid = false
        }
        if (values.mobile_no && !values.mobile_no.match(/^[0-9]*$/)) {
            Validations.mobile_no = "Please enter the valid mobile number";
            isValid = false
        }

        if (values.email && !values.email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
            Validations.email = "Please enter the valid email address"
            isValid = false
        }

        if (values.password && !values.password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)) {
            Validations.password = "Please enter the valid password address eight character,uppercase,lowercase,spec char"
            isValid = false
        }
        if (values.confirm_password && !values.confirm_password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)) {
            Validations.confirm_password = "Please enter the valid confirm password address eight character,uppercase,lowercase,spec char"
            isValid = false
        }

        if (values.password !== values.confirm_password) {
            Validations.confirm_password = "Confirm Password should be matched with password";
            isValid = false
        }

        if (!isValid) {
            setValidation(Validations)
        }
        return isValid;
    }

    const validateOne = (e) => {
        const { name } = e.target
        const value = values[name];
        console.log("______________________________________________", value, name)
        let message = ''
        if (!value && name === 'first_name') {
            message = `Please enter the first name`
        }
        if (!value && name === 'last_name') {
            message = `Please enter the last name`
        }
        if (!value && name === 'password') {
            message = `Please enter the password`
        }
        if (!value && name === 'confirm_password') {
            message = `Please enter the confirm password`
        }
        if (!value && name === 'mobile_no') {
            message = `Please enter the mobile number`
        }

        setValidation({ ...Validations, [name]: message })
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value })
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        //validation implement all rules are check
        const isValid = validateAll();
        if (!isValid) { return false; };
        //enable loader while getting all things is fine

        //making the payload is for register
        const payload = {
            sponsor_code: values.sponsor_code,
            first_name: values.first_name,
            middle_name: values.middle_name,
            last_name: values.last_name,
            email: values.email,
            password: values.password,
            mobile_no: values.mobile_no
        }
        //dispatch the payload to the api
        await dispatch(createRegister(payload));

    }

    return (
        <>
         
            <LoadingOverlay active={isActive} spinner text='Loading...'>
            <ToasterContainer />
                <div className="authincation section-padding">
                    <div className="container h-100">
                        <div className="row justify-content-center h-100 align-items-center">
                            <div className="col-xl-7 col-lg-8 col-md-12 col-12">
                                <div class="authincation section-padding pt-5 pb-5">
            <div class="container h-100">
                <div class="row justify-content-center h-100 align-items-center">
                    <div class="col-xl-12 col-md-12">
                        <div class="mini-logo text-center mb-4">
                            <a href="index.html"><img src={require("../../images/crypot-exchange-logo.png")} alt="" /></a>
                        </div>
                        <div class="auth-form card">
                            <div class="card-header justify-content-center">
                                <h4 class="card-title">Sign up your account</h4>
                            </div>
                            <div class="card-body">
                                <form  onSubmit={(event) => handleSubmit(event)} class="signup_validate">
									<div class="row">
										<div class="col-md-12">
											<div class="form-group">
												<label>Sponsor Code (Optional)</label>
												<input type="text" class="form-control" placeholder="Sponsor Code (Optional)" onChange={handleChange} maxLength={50} value={values?.sponsor_code} name="sponsor_code" />
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-4">
											<div class="form-group">
												<label>First Name</label>
												<input type="text" class="form-control" placeholder="First Name"  onChange={handleChange} maxLength={100} value={values?.first_name} name="first_name" />
                                                <small style={{ color: 'red' }}>{Validations?.first_name}</small>	
                                            </div>
										</div>

                                        <div class="col-md-4">
											<div class="form-group">
												<label>Middle Name</label>
												<input type="text" class="form-control" placeholder="Middle Name (Optional)"  onChange={handleChange} maxLength={100} value={values?.middle_name} name="middle_name" />	
                                            </div>
										</div>

										<div class="col-md-4">
											<div class="form-group">
												<label>Last Name</label>
												<input type="text" class="form-control" placeholder="Last Name" onChange={handleChange} maxLength={100} value={values?.last_name} name="last_name" />
                                                <small style={{ color: 'red' }}>{Validations?.last_name}</small>	
                                            </div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-6">
											<div class="form-group">
												<label>Email</label>
												<input type="text" class="form-control" placeholder="hello@example.com" maxLength={160} onChange={handleChange} value={values?.email} name="email" />
                                                <small style={{ color: 'red' }}>{Validations?.email}</small>	
                                            </div>
										</div>
										<div class="col-md-6">
											<div class="form-group">
												<label>Phone</label>
												<input type="text" class="form-control" placeholder="Phone" maxLength={10} onChange={handleChange} value={values?.mobile_no} name="mobile_no" />
                                                <small style={{ color: 'red' }}>{Validations?.mobile_no}</small>	
                                            </div>
										</div>
									</div>
									<div class="row">
										<div class="col-md-6">
											<div class="form-group password-group">
												<label>Password</label>
												<input type={!isVisible ? "password" : "text"} onChange={handleChange} class="form-control" value={values?.password} placeholder="********" name="password" />
                                                {
                                                    !isVisible ? (<i class="mdi mdi-eye" onClick={togglehit}></i>) : ( <i class="mdi mdi-eye-off" onClick={togglehit}></i>)
                                                }
                                                <small style={{ color: 'red' }}>{Validations?.password}</small>													
												
											</div>
										</div>
										<div class="col-md-6">
											<div class="form-group password-group">
												<label>Confirm Password</label>
												<input type={!isVisible1 ? "password" : "text"} class="form-control" placeholder="********" onBlur={validateOne} onChange={handleChange} value={values?.confirm_password} name="confirm_password" />
                                                {
                                                    !isVisible1 ? (<i class="mdi mdi-eye" onClick={toggle1}></i> ) : (<i class="mdi mdi-eye-off" onClick={toggle1}></i>)
                                                }
                                                <small style={{ color: 'red' }}>{Validations?.confirm_password}</small>
											</div>
										</div>
									</div>
                                    <div class="text-center mt-4">
                                        <button type="submit" class="btn btn-success btn-block">{signUpBtn}</button>
                                    </div>
                                </form>
                                <div class="new-account mt-3">
                                    <p>Already have an account? <Link class="text-primary" to="/signin">Sign in</Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        </>
    );
}

export default Signup;
