import React, { useEffect, useRef, useState } from "react";
import Header2 from "../../layout/header2";
import Sidebar from "../../layout/sidebar";
import PageTitle from "../../element/page-title";
import ToasterContainer from "../../component/ToasterContainer";
import LoadingOverlay from 'react-loading-overlay';
import dummy from "../../../util/images/dummy.png"
import { Link, useNavigate } from "react-router-dom";
import { userCreateBankDetail } from "../../../store/BasicInfo/basicInfoSlice";
import { useDispatch, useSelector } from "react-redux";
import useDocumentTitle from "../../../helper/useTitle"
import OrderPlace from "../../component/placeOrder/orderPlace";

export default function CreateBankingDetail(){
    useDocumentTitle('Create Banking Detail | Exchange')

    const [values,setValues]=useState({bank_name:"",account_holder_name:"",ifsc_code:"",bank_passbook:""});
    const [Validations,setValidation]=useState({bank_name:"",account_holder_name:"",ifsc_code:"",bank_passbook:""});
    const [isActive,isSetActive]=useState(false);
    const [submit,setSubmit]=useState("Submit");
    const [image,setImage]=useState();
    const [imgName,setImgName]=useState("");
    const dispatch =useDispatch();
    const navigate=useNavigate();
    const {isSubmitBankDetail,submitBankDetail}=useSelector((state) => state.basic);

    const previousProps = useRef({isSubmitBankDetail}).current;

    useEffect(() => {
        if(previousProps?.isSubmitBankDetail!=isSubmitBankDetail && submitBankDetail.message === "Bank Detail has been saved successfully") {
            setSubmit("Processing...Please wait");
            isSetActive(true);
            setTimeout(() => {
                navigate("/gettingBankListing")
            }, 3000);
        }
    },[isSubmitBankDetail,submitBankDetail])


    const handleChange = (e) => {
        const {name,value}=e.target;
        setValues({...values,[name]:value})
    }

    function ValidationAll() {

        let Validations={};
        let isValid=true;
        if(!values.bank_name) {
            Validations.bank_name="Please enter the bank name"
            isValid=false
        }
        if(!values.account_holder_name){
            Validations.account_holder_name="Please enter the account holder name"
            isValid=false
        }
        
        if(values.account_number?.length < 10) {
            Validations.account_number="Accont number should be greater then 10 to 20 character"
            isValid=false
        }
        if (values.account_number && !values.account_number.match(/^[0-9]*$/)) {
            Validations.account_number = "Please enter the valid account number"
            isValid = false
        }

        if(!values.account_number){
            Validations.account_number="Please enter the account number"
            isValid=false
        }

        if(!values.ifsc_code){
            Validations.ifsc_code="Please enter the ifsc code"
            isValid=false
        }

        if(!isValid){
            setValidation(Validations)
        }
        return isValid;
    }

    const validateOne = (e) => {
        const { name } = e.target
        const value = values[name];
        let message = ''
        
        if (!value && name === 'bank_name') {
            message = `Please enter the bank name`
        }
        if (!value && name === 'account_holder_name') {
            message = `Please enter the account_holder_name`
        }
        if (!value && name === 'account_number') {
            message = `Please enter the account number`
        }
        if (!value && name === 'ifsc_code') {
            message = `Please enter the ifsc code`
        }
        setValidation({ ...Validations, [name]: message })
    }

    //image mapping along with images
    const handleImageChange =  (e) => {
        if(e.target.files.length!=0){
            setImage(URL.createObjectURL(e.target.files[0]));
            setImgName(e.target.files[0])
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const isValid=ValidationAll();
        if(!isValid){return false;}

        let payloadBankDetail={
            bank_name:values.bank_name,
            account_holder_name:values.account_holder_name,
            account_number:values.account_number,
            ifsc_code:values.ifsc_code,
            bank_passbook:imgName
        }
        dispatch(userCreateBankDetail(payloadBankDetail));
    }


    return (
        <>
           <LoadingOverlay active={isActive} spinner text='Loading...'>
         
         <Header2 />
            <Sidebar />
            <PageTitle />
            <ToasterContainer />

            <div className="content-body">
                <div className="container-fluid">
                    <div className="row">
             <div className="col-xl-9">
                        <div className="row">

                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Create Banking Detail</h4>
                                    </div>
                                    <div className="card-body">
                                        <form onSubmit={(e) => handleSubmit(e)} className="personal_validate">
                                            <div className="row">
                                                <div className="mb-3 col-xl-6 col-md-6">
                                                    <div className="form-group">
                                                    <label className="form-label">Bank Name*</label>
                                                    <input type="text" className="form-control" maxLength={200} onChange={handleChange} value={values?.bank_name || ''} onBlur={validateOne} placeholder="Please enter the bank name"
                                                        name="bank_name"/>
                                                        </div>
                                                        <small style={{color:'red'}}>{Validations.bank_name}</small>
                                                </div>
                                              
                                                <div className="mb-3 col-xl-6 col-md-6">
                                                    <div className="form-group">
                                                    <label className="form-label">Account Holder Name*</label>
                                                    <input type="text" className="form-control" maxLength={60} onChange={handleChange} value={values?.account_holder_name || ''} onBlur={validateOne} placeholder="Please enter the account holder name"
                                                        name="account_holder_name"/>
                                                        </div>
                                                    <small style={{color:'red'}}>{Validations.account_holder_name}</small>
                                                </div>

                                                <div className="mb-3 col-xl-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Account Number*</label>
                                                    <input type="text" className="form-control" maxLength={20} onChange={handleChange} value={values?.account_number || ''} onBlur={validateOne} placeholder="Please enter account number"
                                                        name="account_number"/>
                                                </div>
                                                        <small style={{color:'red'}}>{Validations.account_number}</small>
                                                </div>

                                                <div className="mb-3 col-xl-6 col-md-6">
                                                    <div className="form-group">
                                                    <label className="form-label">IFSC Code*</label>
                                                    <input type="text" className="form-control" maxLength={16} onChange={handleChange} value={values?.ifsc_code || ''} onBlur={validateOne} placeholder="Please enter ifsc code"
                                                        name="ifsc_code"/>
                                                    </div>
                                                        <small style={{color:'red'}}>{Validations.ifsc_code}</small>
                                                </div>

                                                <div className="mb-3 col-xl-12 col-md-12">
                                                    <div className="d-flex align-items-center mb-3">
                                                        <img className="me-3 rounded-circle me-0 me-sm-3" draggable={false} src={image?image:dummy} width="100" height="100" alt="Upload Passbook" />
                                                        <div className="flex-grow-1">
                                                        </div>
                                                    </div>
                                                   
                                                    <div className="file-upload-wrapper" data-text="Upload Bank Passbook">
                                                        <input name="upload_document" onChange={handleImageChange} type="file" className="file-upload-field"/>
                                                    </div>
                                                </div>


                                                <div className="mb-3 col-12 text-center">
                                                    <button type="submit" className="btn btn-success px-4">{submit}</button>
                                                    {/* <Link to="/support" className="btn btn-success">Back</Link> */}
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
            </div>
<OrderPlace/>
                    </div>
                    </div>
                    </div>
                    </LoadingOverlay>
            
        </>
    )
}