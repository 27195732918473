import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import Header2 from "../../layout/header2";
import Sidebar from "../../layout/sidebar";
import PageTitle from "../../element/page-title";
import ToasterContainer from "../../component/ToasterContainer";
import dummy from "../../../util/images/dummy.png"
import { useState } from "react";
import { capitalizeFirstLowercaseRest } from "../../component/helper";
import { Link } from "react-router-dom";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { getAllSupport, BASE_URL_URL, REACT_APP_S3_CLOUD_IMAGE_URL } from "../../../util/constant";
import { useDispatch, useSelector } from "react-redux";
import { getListingOfKyc } from "../../../store/BasicInfo/basicInfoSlice";
import { useEffect } from "react";
import { useRef } from "react";
import useDocumentTitle from "../../../helper/useTitle"
import aadharCardFront from "../../../images/adhaarFront.png"
import aadharCardback from "../../../images/aadharCardBack.png"
import identityProof from "../../../images/identityProof.png"
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import OrderPlace from "../../component/placeOrder/orderPlace";

export default function ListingKyc() {
    useDocumentTitle('Kyc Listing | Exchange')
    const [userData, setUserData] = useState("");
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleClose2 = () => setShow2(false);
  const customHandleClose = () => setShow(false)
  const customHandleClose1 = () => setShow1(false)
  const customHandleClose2 = () => setShow2(false)
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const handleShow2 = () => setShow2(true);
    const {isKyc,kycDetail}=useSelector((state) => state.basic);
    //getting listing of kyc
    useEffect(() => {
        dispatch(getListingOfKyc())
    },[]);

    
    //set in hook as listing of kyc data
    useEffect(() => {
            if(isKyc && kycDetail) {
                setUserData(kycDetail?.data)
            }
    },[isKyc,kycDetail])

 console.log("___________________________________________________user data is here",userData)

    return (
        <>
            <Header2 />
            <Sidebar />
            {/* <PageTitle /> */}
            <ToasterContainer />
            <div class="content-body">
            <div class="container-fluid">
                <div class="row">
					<div class="col-xl-12 col-lg-12 col-md-12 col-xxl-12">
						<div class="row">
							<div class="col-xl-12 col-lg-12 col-md-12 col-xxl-12">
								<div class="card">
									<div class="inner-card-header card-header">
										<h4>KYC</h4>
                                        {
            !userData ? <Link to="/createKyc" className="btn btn-success px-4 float-right">+ Create Kyc</Link> 
            : <Link to={`/editKyc/${userData?.id}`} className="btn btn-primary">Edit <i class="mdi mdi-square-edit-outline"></i></Link>
        }
										{/* <a href="javascript:void(0)" class="btn btn-primary" data-toggle="modal" data-target="#editkyc">Edit <i class="mdi mdi-square-edit-outline"></i></a> */}
									</div>
									<div class="card-body">
										<div class="row">
											<div class="col-md-6">
												<div class="row">
													<div class="col-md-3">
														<div class="form-group form-row">
															<label class="control-label"><span>First Name</span></label>
															
														</div>
													</div>

													<div class="col-md-3">
														<div class="form-group form-row">
														<h6>{userData && capitalizeFirstLowercaseRest(userData?.first_name) || 'N/A'}</h6>
															</div>
													</div>
													<div class="col-md-3">
														<div class="form-group form-row">
															<label class="control-label"><span>Last Name</span></label>
														</div>
													</div>

													<div class="col-md-3">
														<div class="form-group form-row">
														<h6>{userData && capitalizeFirstLowercaseRest(userData?.last_name) || 'N/A'}</h6>
															</div>
													</div>
													
                                                    <div class="col-md-3">
														<div class="form-group form-row">
															<label class="control-label"><span>Country Code</span></label>
														</div>
													</div>

													<div class="col-md-3">
														<div class="form-group">
														<h6>{userData && userData?.country_code || 'N/A'}</h6>
														</div>
														</div>

														<div class="col-md-3">
														<div class="form-group form-row">
															<label class="control-label"><span>Nationality</span></label>
														</div>
													</div>

													<div class="col-md-3">
														<div class="form-group">
														<h6>{userData && capitalizeFirstLowercaseRest(userData?.nationality) || 'N/A'}</h6>
														</div>
														</div>

                                                    <div class="col-md-3">
														<div class="form-group form-row">
															<label class="control-label"><span>Mobile</span></label>
                                                        </div>
													</div>

													<div class="col-md-3">
														<div class="form-group form-row">
														<h6>{userData && userData?.mobile_no || 'N/A'}</h6>
														</div>
													</div>
													<div class="col-md-3">
														<div class="form-group">
															<label class="control-label"><span>Email</span></label>
															
														</div>
													</div>

													<div class="col-md-3">
														<div class="form-group">
														<h6>{userData && userData?.email || 'N/A'}</h6>
														</div>
														</div>


													<div class="col-md-3">
														<div class="form-group">
															<label class="control-label"><span>City</span></label>
															
														</div>
													</div>

													<div class="col-md-3">
														<div class="form-group">
														<h6>{userData && capitalizeFirstLowercaseRest(userData?.city) || 'N/A'}</h6>
														</div>
														</div>

													<div class="col-md-3">
														<div class="form-group">
															<label class="control-label"><span>State</span></label>
															
														</div>
													</div>

													<div class="col-md-3">
														<div class="form-group">
														<h6>{userData && capitalizeFirstLowercaseRest(userData?.state) || 'N/A'}</h6>
														</div>
														</div>

                                                    <div class="col-md-3">
														<div class="form-group">
															<label class="control-label"><span>PanCard</span></label>
															
														</div>
													</div>

													<div class="col-md-3">
														<div class="form-group">
														<h6>{userData && userData?.pancard || 'N/A'}</h6>
															</div>
															</div>

                                                    <div class="col-md-3">
														<div class="form-group">
															<label class="control-label"><span>Postal Code</span></label>
														</div>
													</div>

													<div class="col-md-3">
														<div class="form-group">
														<h6>{userData && userData?.postal_code || 'N/A'}</h6>
														</div>
														</div>

														<div class="col-md-3">
														<div class="form-group">
															<label class="control-label"><span>Residentail Address</span></label>
														</div>
													</div>

													<div class="col-md-3">
														<div class="form-group">
														<h6>{userData && capitalizeFirstLowercaseRest(userData?.residential_address) || 'N/A'}</h6>
														</div>
														</div>

												</div>
										
											</div>
											<div class="col-md-3 pl-4">
												<div class="row">
													<div class="col-md-12">
														<div class="form-group">
															<label class="control-label"><span>Identity Proof</span></label>
															<div class="kyc-img-colm">
																<a href="javascript:void(0)" data-toggle="modal" data-target="#idproof">
																	<img id="address_proof_front" onClick={handleShow} draggable={false} height={200} width={200} src={userData && userData?.identity_proof ? userData?.identity_proof : identityProof} alt="identity-proof" class="img-responsive" />
																</a>
															</div>
														</div>
													</div>
													<div class="col-md-12">
														<div class="form-group">
															<label class="control-label"><span>Adhaar Card Front</span></label>
															<div class="kyc-img-colm">
																<a href="javascript:void(0)" data-toggle="modal" data-target="#adhaarfront">
																	<img id="address_proof_front" onClick={handleShow1} draggable={false} src={userData && userData?.aadhar_card_front ? userData?.aadhar_card_front : aadharCardFront} alt="aadhar_card_front" class="img-responsive" />
																</a>
															</div>
														</div>
													</div>
													<div class="col-md-12">
														<div class="form-group">
															<label class="control-label"><span>Adhaar Card Back</span></label>
															<div class="kyc-img-colm">
																<a href="javascript:void(0)" data-toggle="modal" data-target="#adhaarback">
																	<img id="address_proof_front" onClick={handleShow2} src={userData && userData?.aadhar_card_back ? userData?.aadhar_card_back : aadharCardback } alt="aadhar_card_back" class="img-responsive" />
																</a>
															</div>
														</div>
													</div>
												</div>
											</div>
											
												<OrderPlace />
												
										</div>
										
									</div>
								</div>
							</div>
							
						
                    
						</div>
					</div>
					
                    
                </div>

                
            </div>

        </div>




        {/* model open while showing the print preview */}
        <div className="proofdet">
        <Modal show={show} onHide={handleClose}>
        <Link onClick={customHandleClose} className="close" >&times;</Link>
        <Modal.Body><img src={userData && userData?.identity_proof} height={200} width={200} draggable={false}  className="w-100" /></Modal.Body>
      </Modal>
      </div>


      {/* second modal is open while print preveiw the image */}
      <Modal show={show1} onHide={handleClose1}>
        
            <Link onClick={customHandleClose1} className="close">&times;</Link>
        <Modal.Body><img src={userData && userData?.aadhar_card_front} height={200} width={200} draggable={false}  className="w-100" /></Modal.Body>
      </Modal>
      {/* end of second modal is close while print preview */}


         {/* third modal is open while print preveiw the image */}
      <Modal show={show2} onHide={handleClose2}>
   
            <Link onClick={customHandleClose2} className="close" >&times;</Link>
     
        <Modal.Body><img src={userData && userData?.aadhar_card_back} height={200} width={200} draggable={false} className="w-100" /></Modal.Body>
      </Modal>
      {/* end of second modal is close while print preview */}


        {/* end of model for print preview */}
        </>
    )
}