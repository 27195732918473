import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeForgotPassword } from '../../store/BasicInfo/basicInfoSlice';
import ToasterContainer from "../component/ToasterContainer";
import useDocumentTitle from "../../helper/useTitle"

export default function ForgotPassword() {
    useDocumentTitle('Forgot Password | Exchange')
    const [values,setValue]=useState({email:""});
    const [Validations,setValidation]=useState({email:""});
    const dispatch=useDispatch();

    const {isForgotPassword,message,forgotDetail}=useSelector((state) => state.basic);

    const previousProps=useRef({isForgotPassword}).current;
    useEffect(() => {
        if(previousProps.isForgotPassword!=isForgotPassword && message=="Password has been forgot successfully" && forgotDetail){
            //exec code

        }
    },[isForgotPassword,message,forgotDetail])

    const handleChange = (e) => {
        const {name,value}=e.target;
        setValue({...values,[name]:value});
    }
    
    function validateAll() {
        let Validations={};
        let isValid=true;
     
        if(!values.email) {
            Validations.email="Please enter the email address"
            isValid=false
        }
        if (!isValid) {
            setValidation(Validations)
        }
        return isValid;
    }


    
    const validateOne = (e) => {
        const { name } = e.target
        const value = values[name];
        console.log("______________________________________________",value, name )
        let message = ''
        if (!value && name === 'username') {
            message = `Please enter the username`
        }
        if (!value && name === 'email') {
            message = `Please enter the email`
        }
        if (!value && name === 'password') {
            message = `Please enter the password`
        }
        // if(!value && name==='email' && !value.match(/^[A-Z0-9. _%+-]+@[A-Z0-9. -]/)){
        //     message=`Please enter the valid email address`
        // }
        // if(value && name==='password' && !value.match(/^[A-Z0-9. _%+-]+@[A-Z0-9. -]/)){
        //     message=`Please enter the valid email address`
        // }

        setValidation({ ...Validations, [name]: message })
    }

    const handleSubmit = async(event) => {
        event.preventDefault();
        const isValid = validateAll();
        if (!isValid) { return false; };
        let payload = {email:values.email}
        await dispatch(makeForgotPassword(payload))
        if(isForgotPassword){
            setValue({email:""})
        }
    }

    return (
        <>
        <ToasterContainer/>
            <div className="authincation section-padding">
                <div className="container h-100">
                    <div className="row justify-content-center h-100 align-items-center">
                        <div className="col-xl-5 col-md-6">
                            <div className="mini-logo text-center my-5">
                                <Link to="/"><img src={require("../../images/crypot-exchange-logo.png")} alt="" /></Link>
                            </div>

                            <div class="auth-form card">
                            <div class="card-header justify-content-center">
                                <h4 class="card-title">Forgot password</h4>
                            </div>
                            <div class="card-body">
                                <form onSubmit={(event) => handleSubmit(event)}>
                                    <div class="form-group">
                                        <div className='form-group'>
                                        <label>Email</label>
                                        <input type="email" class="form-control" onBlur={validateOne} onChange={handleChange} name="email" placeholder='jhon@yopmail.com' value={values.email} />
                                        </div>
                                        <small style={{ color: 'red' }}>{Validations?.email}</small>
                                    </div>
                                    <div class="text-center">
                                        <button type="submit" class="btn btn-success btn-block">Forgot Password</button>
                                    </div>
                                </form>
                                <div class="new-account mt-3 d-flex">
                                    <p class="mb-1">Don't Received? </p>
                                    <Link class="text-primary ml-2" onClick={(event) => handleSubmit(event)}>Resend </Link>
                                    <Link class="text-primary ml-2" to="/signin">Sign in</Link>
                                </div>
                            </div>
                        </div>

                            {/* <div className="auth-form card">
                                <div className="card-header justify-content-center">
                                    <h4 className="card-title">Forgot password</h4>
                                </div>
                                <div className="card-body">
                              
                                    <form onSubmit={(event) => handleSubmit(event)}>
                                        <div className="mb-3">
                                            <label>Email</label>
                                            <input type="text" className="form-control" onBlur={validateOne} onChange={handleChange} name="email" placeholder='jhon@yopmail.com' value={values.email} />
                                            <small style={{ color: 'red' }}>
                                                {Validations.email}
                                                </small>
                                        </div>
                                        <div className="text-center">
                                            <button type="submit" className="btn btn-success btn-block">Submit</button>
                                            <Link to="/signin" className="btn btn-success btn-block">Back</Link>
                                        </div>

                                    </form>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

