import React, { useEffect, useState } from "react"
import layoutList from "../../../images/layout_list.svg"
import layoutRed from "../../../images/layout_red.svg"
import layoutGreen from "../../../images/layout_green.svg"
import { getActiveTopTenBuyOrder } from "../../../store/order/orderMatchingSlice"
import { useDispatch, useSelector } from "react-redux"
import { getActiveTopTenSellOrder } from "../../../store/order/orderMatchingSlice"

export default function RunningOrder({pairDynamicId}) {
    const [buyOrder,setBuyOrder]=useState("");
    const [sellOrder,setSellOrders]=useState("");
    const [filterRunningOrder,setFilterRunningOrder]=useState("all");
    const dispatch = useDispatch();

    const {getSellOrderDetail,getBuyActiveOrder}=useSelector((state) => state.order);

    //state update based on top ten buy order
    useEffect(() => {
        if(getBuyActiveOrder && getBuyActiveOrder.status===true) {
            setBuyOrder(getBuyActiveOrder?.data)
        }
    },[getBuyActiveOrder]);

    //state update based on top ten sell order
    useEffect(() => {
        if(getSellOrderDetail && getSellOrderDetail.status===true) {
            setSellOrders(getSellOrderDetail?.data)
        }
    },[getSellOrderDetail]);


    useEffect(() => {
        dispatch(getActiveTopTenBuyOrder())
        dispatch(getActiveTopTenSellOrder())
    },[]);
    
    useEffect(() => {
        if(pairDynamicId){
            dispatch(getActiveTopTenBuyOrder({pair_id:pairDynamicId}))    
        }
    },[pairDynamicId])
    
    useEffect(() => {
        if(pairDynamicId){
            dispatch(getActiveTopTenSellOrder({pair_id:pairDynamicId}))    
        }
    },[pairDynamicId])

    //api call here for buy and sell top ten order and based on sell
   

    var totalVal=0;
    console.log("sellOrder------------>>>>>> is here",sellOrder)
    return (
        <>
        	<div class="col-xl-3 col-lg-3 col-md-12 col-xxl-3">
                        <div class="card ordertradecard">
                            <div class="card-header">
								<ul class="nav nav-pills" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" data-toggle="pill" href="#order" role="tab"
                                            aria-selected="true">{ filterRunningOrder==="all" && "All Orders"} {filterRunningOrder==="green" && "Sell Order"} {filterRunningOrder==="red" && "Buy Order"}</a>
                                    </li>
                                    {/* <li class="nav-item">
                                        <a class="nav-link" data-toggle="pill" href="#history" role="tab"
                                            aria-selected="false">Trades</a>
                                    </li> */}
                                </ul>
								<div class="layoutdata">
									<span class={filterRunningOrder==="all" ? "custom-red-opacity" :"showalldata"}><img src={layoutList} alt="layoutList" title="All" onClick={() => setFilterRunningOrder("all")} /></span>
                                    <span class={filterRunningOrder==="green" ? "custom-red-opacity" :"showalldata"}><img src={layoutGreen} alt="layoutGreen" title="Bids" onClick={() => setFilterRunningOrder("green")} /></span>
									<span class={filterRunningOrder==="red" ? "custom-red-opacity" :"showalldata"}><img src={layoutRed} alt="layoutRed" title="Asks" onClick={() => setFilterRunningOrder("red")} /></span>
								</div>
                            </div>
                        {
                            filterRunningOrder==="all" && 
                            <div class="card-body p-0">
								<div class="tab-content">
                                    <div class="tab-pane fade show active" id="order" role="tabpanel">
										<div class="card-body order-book">
										<table class="table tabledatared">
											<thead>
												<tr>
													<th scope="col">Price (USD)</th>
													<th scope="col">Volume (BTC)</th>
													<th scope="col">Total</th>
												</tr>
											</thead>
											<tbody>
                                                {  
                                                    buyOrder.length > 0 ? buyOrder.map((val) => (
                                                        <tr class="red-bg">
                                                        <td class="text-danger">{val?.price ? val?.price : '0'}</td>
                                                        <td>{val?.vol ? val?.vol : '0'}</td>
                                                        <td>{totalVal=totalVal+val?.vol}</td>
                                                    </tr>
                                                    )) : <p className="text-center text-danger">Data not found</p>
                                                }
											</tbody>
										</table>

										{/* <div class="order-book-divider">
											<h6 class="text-danger"> <i class="la la-arrow-down"></i> 6587.35</h6>
											<span>6520.220 / 4835.00</span>
										</div> */}
                                                
										<table class="table tablegreendata">
											<thead>
												<tr>
													<th scope="col">Price (USD)</th>
													<th scope="col">Volume (BTC)</th>
													<th scope="col">Total</th>
												</tr>
											</thead>
											<tbody>
                                            {      
                                                    sellOrder.length > 0 ? sellOrder.map((vall) => (
                                                        <tr class="green-bg">
                                                        <td class="text-success">{vall?.price ? vall?.price : '0'}</td>
                                                        <td>{vall?.vol ? vall?.vol : '0'}</td>
                                                        <td>{totalVal=totalVal+vall?.vol}</td>
                                                    </tr>
                                                    )) : <p className="text-center text-danger">Data not found</p>
                                                }
											</tbody>
										</table>
										</div>
									</div>
								</div>
                            </div>
                        }
                        {// sell order is here
                            filterRunningOrder==="green" &&
                            <table class="table tablegreendata">
											<thead>
												<tr>
													<th scope="col">Price (USD)</th>
													<th scope="col">Volume(BTC)</th>
													<th scope="col">Total</th>
												</tr>
											</thead>
											<tbody>
                                            {      
                                                    sellOrder.length > 0 ? sellOrder.map((vall) => (
                                                        <tr class="green-bg" key={vall?.id}>
                                                        <td class="text-success">{vall?.price ? vall?.price : '0'}</td>
                                                        <td>{vall?.vol ? vall?.vol : '0'}</td>
                                                        <td>{totalVal=totalVal+vall?.vol}</td>
                                                    </tr>
                                                    )) : <p className="text-center text-danger">Data not found</p>
                                                }
											</tbody>
							</table>
                        }
                        {//Buy order start here
                            filterRunningOrder==="red" &&
                            <table class="table tabledatared">
											<thead>
												<tr>
													<th scope="col">Price (USD)</th>
													<th scope="col">Volume (BTC)</th>
													<th scope="col">Total</th>
												</tr>
											</thead>
											<tbody>
                                                {  
                                                    buyOrder.length > 0 ? buyOrder.map((val) => (
                                                        <tr class="red-bg">
                                                        <td class="text-danger">{val?.price ? val?.price : '0'}</td>
                                                        <td>{val?.vol ? val?.vol : '0'}</td>
                                                        <td>{totalVal=totalVal+val?.vol}</td>
                                                    </tr>
                                                    )) : <p className="text-center text-danger">Data not found</p>
                                                }
											</tbody>
										</table>
                        }
                        </div>
                    </div>
        </>
    )
}