import React, { useState, useEffect } from "react";
import { getInnerHeaderDetailExchange } from "../../../store/order/orderMatchingSlice";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeFirstLowercaseRest } from "../helper";

function TopInnerHeader({btcIcon,dynamicHeader}) {
    const [innerOrderDetail,setTopInnerOrderDetail]=useState("");
	const dispatch = useDispatch();
	const {isInnerHeaderOrderStatus, getInnerHeaderOrderDetail}=useSelector((state) => state.order);

	useEffect(() => {
		if(dynamicHeader){
			dispatch(getInnerHeaderDetailExchange({pair_id:3}))
		}
	},[])

	useEffect(() => {
		dispatch(getInnerHeaderDetailExchange())
	},[])

	useEffect(() => {
		if(getInnerHeaderOrderDetail && getInnerHeaderOrderDetail?.status==true){
			setTopInnerOrderDetail(getInnerHeaderOrderDetail?.data)
		}
	},[getInnerHeaderOrderDetail])

    return (
        <>
        <div class="col-xl-12 col-lg-12 col-md-12 col-xxl-12">
								<div class="card">
									<div class="card-body pt-2 pb-2">
										<div class="row align-items-center pairs-section">
											<div class="col-md-10 col-sm-10 col-xs-12">
												<div class="current_price_list">
												   <div class="market-pair">
														<img src={innerOrderDetail?.Pair?.term?.currency_icon ? innerOrderDetail?.Pair?.term?.currency_icon : btcIcon} alt="bitcoin" />
													  <div aria-expanded="false" aria-haspopup="true" role="button" class="paircurr">
														 <h4>{innerOrderDetail?.Pair?.pair_name.toUpperCase() || '-'}</h4>
														 <span id="lastTradePrice">{innerOrderDetail?.last_trade_price || '-'}&nbsp;<strong class="green">{innerOrderDetail?.change || '-'}</strong></span>
													  </div>
												   </div>
												   <ul class="list-pair">
													  <li>
														 <h6> Last Trade Price </h6>
														 <span class="update_last_price">{innerOrderDetail?.last_trade_price || '-'}</span>
													  </li>
													  <li>
														 <h6> 24h Change </h6>
														 <span id="update_change"><span class="green"><i class="fal fa-long-arrow-up"></i> {innerOrderDetail?.change || '-'}%
														 </span></span>
													  </li>
													  <li>
														 <h6> 24h High </h6>
														 <span class="price_normal"> <span class="green"><span id="update_high_price">{innerOrderDetail?.high}</span></span></span>
													  </li>
													  <li>
														 <h6>24h Low</h6>
														 <span class="price_normal"> <span class="red"><span id="update_low_price">{innerOrderDetail?.low || '-'}</span></span></span>
													  </li>
													  <li>
														 <h6> 24h Volume </h6>
														 <span class="price_normal" id="update_volume">{innerOrderDetail?.active_volume || '-'}</span>
													  </li>
												   </ul>
												</div>
											</div>
											<div class="col-md-2">
												<div class="socket-fixed" id="socket_connected">
													<i class="far fa-signal"></i>
													<h6>Socket Status
														<span class="exchange_status_connected" id="socket_status"> 
															<span id="socket_text"> Connected</span>
														</span>
													</h6> 
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
        </>
    )
}
export default TopInnerHeader;