import React, { useEffect, useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { getUserProfile, logout } from '../../store/BasicInfo/basicInfoSlice';
import { useDispatch, useSelector } from 'react-redux';
import dummy from "../../util/images/dummy.png"
import { capitalizeFirstLowercaseRest } from '../component/helper';
import logo from "../../images/crypot-exchange-logo.png"

// const ProfileToggle = React.forwardRef(({ children, onClick,auth }, ref) => (

//     <div  ref={ref} onClick= {e => { e.preventDefault(); onClick(e); }} >
//         {children}
//         <div className="profile_log">
//             <div className="user">

//                 <img src={auth.profile_image ? auth.profile_image : dummy} height={30} width={30} style={{borderRadius:'100px'}} />

//                 <span className="arrow"><i className="la la-angle-down"></i></span> 
//                  {/* <span className="thumb"><i className="mdi mdi-account"></i></span> */}

//             </div>
//         </div>
//     </div>
// ));

// const LanguageToggle = React.forwardRef(({ children, onClick }, ref) => (
//     <div
//         ref={ref}
//         onClick={e => {
//             e.preventDefault();
//             onClick(e);
//         }}

//     >
//         {children}
//         <div className="language">
//             <div className="icon">
//                 <i className="flag-icon flag-icon-us"></i>
//                 <span>English</span>
//             </div>
//         </div>
//     </div>
// ));



function Header2() {
    const [auth, setAuth] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { UserProfileGetting, isProfileGet, kycDetail } = useSelector((state) => state.basic);
    const kycStatus = kycDetail?.data?.status || '';

    useEffect(() => {
        dispatch(getUserProfile())
    }, [])

    useEffect(() => {
        if (isProfileGet && UserProfileGetting) {
            setAuth(UserProfileGetting.data)
        }
    }, [UserProfileGetting, isProfileGet])

    const shutDown = async () => {
        await dispatch(logout());
        localStorage.removeItem("authLogger");
        navigate("/signin");
    }

    return (
        <>
            <div class="header dashboard">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xl-12">
                            <nav class="navbar navbar-expand-lg navbar-light px-0">
                                <Link class="navbar-brand" to="/exchange"><img src={logo} alt="crypto-exchange" /></Link>
                                <div class="menutoggle">
                                    <i class="mdi mdi-menu"></i>
                                </div>
                                <div class="menuhead">
                                    <i class="mdi mdi-close"></i>
                                    <ul class="d-flex">
                                        {/* <li>
										<Link to="/exchange">
											 <i class="mdi mdi-tumblr-reblog"></i> Trade
										</Link>
									</li> */}
                                        <li>
                                            <Link to="/exchange">
                                                <i class="mdi mdi-view-dashboard"></i> Exchange
                                            </Link>
                                        </li>
                                        {
                                            localStorage.getItem("authLogger") &&
                                            <li>
                                                <Link to="/funds">
                                                    <i class="mdi mdi-face-profile"></i> Funds
                                                </Link>
                                            </li>
                                        }
                                        {
                                            localStorage.getItem("authLogger") &&
                                            <li>
                                                <Link to="/kycListing">
                                                    <i class="mdi mdi-database"></i>
                                                    Kyc
                                                    {
                                                        kycStatus.length !== "" &&
                                                            kycStatus == false ? (<sup><h6 className='badge badge-danger' style={{ fontSize: "10px" }}>PENDING</h6></sup>) :
                                                            kycStatus === true ? <sup><h4 className='badge badge-primary' style={{ fontSize: "10px" }}>SUCCESS</h4></sup> : ''
                                                    }
                                                </Link>
                                            </li>
                                        }
                                        {
                                            localStorage.getItem("authLogger") &&
                                            <li>
                                            <Link to="/settings">
                                                <i class="mdi mdi-settings"></i>  Settings
                                            </Link>
                                        </li> 
                                        }
                                    </ul>
                                </div>

                                <div class="header-right d-flex my-2 align-items-center ml-auto">
                                    <div class="notifications">
                                        <span class="circle-pulse"></span>
                                        <i class="mdi mdi-bell" data-toggle="dropdown" aria-expanded="true"></i>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <div class="dropdown-header d-flex align-items-center justify-content-between">
                                                <p class="mb-0 font-weight-medium text-white">3 New Notifications</p>
                                                <a to="#!" class="text-muted">Clear all</a>
                                            </div>
                                            <div class="dropdown-body">
                                                <a to="#!" class="dropdown-item">
                                                    <div class="content">
                                                        <p>Account password change</p>
                                                        <p class="sub-text text-muted">5 sec ago</p>
                                                    </div>
                                                </a>
                                                <a to="#!" class="dropdown-item">
                                                    <div class="content">
                                                        <p>Bitcoin price is high now</p>
                                                        <p class="sub-text text-muted">2 hrs ago</p>
                                                    </div>
                                                </a>
                                                <a to="#!" class="dropdown-item">
                                                    <div class="content">
                                                        <p>Payment completed</p>
                                                        <p class="sub-text text-muted">4 hrs ago</p>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="dropdown-footer d-flex align-items-center justify-content-center">
                                                <a to="#!">View all</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="helpsupp">
                                        <a to="#" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Help & Support">
                                            <i class="mdi mdi-help-circle-outline"></i>
                                        </a>
                                    </div>
                                    <div class="language">
                                        <div class="dropdown">
                                            <div class="icon" data-toggle="dropdown">
                                                <i class="flag-icon flag-icon-us"></i>
                                                <span>English</span>
                                            </div>
                                            <div class="dropdown-menu dropdown-menu-right">
                                                <a to="#" class="dropdown-item">
                                                    <i class="flag-icon flag-icon-bd"></i> Bengali
                                                </a>
                                                <a to="#" class="dropdown-item">
                                                    <i class="flag-icon flag-icon-fr"></i> French
                                                </a>
                                                <a to="#" class="dropdown-item">
                                                    <i class="flag-icon flag-icon-cn"></i> China
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="logsign">
                                        {
                                            !localStorage.getItem("authLogger") ? <Link to="/signin">Log In</Link> : <Link onClick={shutDown} className='mr-2'>Logout</Link>
                                        }
                                    </div>
                                    {
                                        localStorage.getItem("authLogger") &&
                                        <div classto="dashboard_log">
                                            <div class="profile_log dropdown">
                                                <div class="user" data-toggle="dropdown">
                                                    <img src={auth?.profile_image ? auth?.profile_image : dummy} height={32} width={32} className='rounded-circle mr-2' alt="user_profile_image" />
                                                    <span class="arrow"><i class="la la-angle-down"></i></span>
                                                </div>

                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <div class="user-email">
                                                        <div class="user">

                                                            <div class="user-info">
                                                                <h6>{auth?.first_name ? "Welcome to " + capitalizeFirstLowercaseRest(auth?.first_name) : 'N/A'}</h6>
                                                                <span>{auth?.email}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="user-balance">
                                                        <div class="available">
                                                            <p>Available</p>
                                                            <span>0.00 USD</span>
                                                        </div>
                                                        <div class="total">
                                                            <p>Total</p>
                                                            <span>0.00 USD</span>
                                                        </div>
                                                    </div>
                                                    <Link to="/kycListing" class="dropdown-item">
                                                        <i class="mdi mdi-account"></i> KYC
                                                    </Link>
                                                    <Link to="/support" class="dropdown-item">
                                                        <i class="mdi mdi-history"></i> SUPPORT
                                                    </Link>
                                                    <Link to="/settings" class="dropdown-item">
                                                        <i class="mdi mdi-settings"></i> Setting
                                                    </Link>
                                                    <Link to="/funds" class="dropdown-item">
                                                        <i class="mdi mdi-lock"></i> FUNDS
                                                    </Link>
                                                    <Link to="/gettingBankListing" class="dropdown-item">
                                                        <i class="mdi mdi-lock"></i> BANK DETAIL
                                                    </Link>
                                                    <Link to="/changePassword" class="dropdown-item">
                                                        <i class="mdi mdi-lock"></i> CHANGE PASSWORD
                                                    </Link>
                                                    {/* <Link to="/editProfile" class="dropdown-item">
                                                <i class="mdi mdi-lock"></i> EDIT PROFILE
                                            </Link> */}
                                                    <Link onClick={shutDown} class="dropdown-item logout">
                                                        <i class="mdi mdi-logout"></i> Logout
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header2;