import React, { useEffect, useRef, useState } from "react";
import Header2 from "../../layout/header2";
import Sidebar from "../../layout/sidebar";
import PageTitle from "../../element/page-title";
import ToasterContainer from "../../component/ToasterContainer";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import { capitalizeFirstLowercaseRest } from "../../component/helper";
import dummy from "../../../util/images/dummy.png"
import paginationFactory from 'react-bootstrap-table2-paginator';
import { getAllSupport, BASE_URL_URL, REACT_APP_S3_CLOUD_IMAGE_URL } from "../../../util/constant";
import { gettingBankingDetail } from "../../../store/BasicInfo/basicInfoSlice";
import { useDispatch, useSelector } from "react-redux";
import useDocumentTitle from "../../../helper/useTitle"
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import OrderPlace from "../../component/placeOrder/orderPlace";
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// const { SearchBar } = Search;


export default function BankingListing() {
    useDocumentTitle('Banking Listing | Exchange')
    const [userData, setUserData] = useState("");
    const dispatch=useDispatch();
    const {isBankDetail, bankDetail }=useSelector((state) => state.basic);
    
    //fill the api response to the state in hook
    useEffect(() => {
        if(isBankDetail && bankDetail){
            setUserData(bankDetail)
        }
    },[isBankDetail,bankDetail])

    //call the api for rendering the data field set in state
    useEffect(() => {
        dispatch(gettingBankingDetail())
    },[])

    // const columns = [
    //     {
    //         dataField: "bank_name",
    //         text: "Bank Name",
    //         filter: textFilter({
    //             placeholder: 'Enter the bank name',  // custom the input placeholder
    //             className: 'my-custom-text-filter', // custom classname on input
    //             //defaultValue: 'test', // default filtering value
    //             //comparator: Comparator.EQ, // default is Comparator.LIKE
    //             caseSensitive: false, // default is false, and true will only work when comparator is LIKE
    //             //style: { ... }, // your custom styles on input
    //             //delay: 1000, // how long will trigger filtering after user typing, default is 500 ms
    //             id: 'id', // assign a unique value for htmlFor attribute, it's useful when you have same dataField across multiple table in one page
    //         }),
    //         sort: true,
    //         formatter: (cell) => {
    //             return (<>{cell ? cell : 'N/A'}</>)
    //         }
    //     },
    //     {
    //         dataField: "account_holder_name",
    //         text: "Account Holder Name",
           
    //         className: 'd-flex align-items-center',
    //         formatter: (cell) => {
    //             return cell ? capitalizeFirstLowercaseRest(cell) : 'N/A'
    //         }
    //     },
    //     {
    //         dataField: "account_number",
    //         text: "Account Number",
    //         formatter: (cell) => { return <>{cell ? capitalizeFirstLowercaseRest(cell) : 'N/A'}</> }
    //     },
    //     {
    //         dataField: "ifsc_code",
    //         text: "IFSC Code",
    //         formatter: (cell) => { return <>{cell ? cell : 'N/A'}</> }
    //     },
    //     {
    //         dataField: "bank_passbook",
    //         text: "Bank Passbook",
    //         formatter: (data) => {
    //             if (data) {
    //                 return (<img src={data?data : dummy} height={50} width={50} alt={"bank_passbook"} />)
    //             } else {
    //                 return (<img src={dummy} height={50} width={50} alt={"dummy image"} />)
    //             }
    //         }
    //     },
    //     // {
    //     //     dataField: "status",
    //     //     text: "",
    //     //     headerFormatter: () => {
    //     //         return (<span>Action</span>);
    //     //     },
    //     //     formatter: (data, allData) => {
    //     //         if (+data === 0) {
    //     //             return (
    //     //                 <>
    //     //                     {/* <Link to="#" className='btn btn-primary me-2 fs-12 w-59' onClick={(e) => handleStatus(e,allData.id)}>Block</Link>
	// 	// 			<Link to={`/editUser/${allData.id}`} className='btn btn-outline-primary w-59 fs-12'>Edit</Link>
	// 	// 			<Link to="#" onClick={(e) => handleDelete(e,allData.id)} className='btn btn-outline-dark w-59 btn-outline-orange  me-2 mt-1 fs-12'>Delete</Link> */}
    //     //                     <Link to={`/viewTicket/${allData.id}`}  ><i class="fa fa-eye"></i></Link>
    //     //                 </>

    //     //             )
    //     //         } else if (+data === 1) {
    //     //             return (
    //     //                 <>
    //     //                     {/* <Link to="#" className='btn btn-outline-primary w-59 fs-12' onClick={(e) => handleStatus(e,allData.id)}>UnBlock</Link>
	// 	// 			<Link to={`/editUser/${allData.id}`} className='btn btn-outline-primary w-59 fs-12'>Edit</Link>
	// 	// 			<Link to="#" onClick={(e) => handleDelete(e,allData.id)} className='btn btn-outline-dark w-59 btn-outline-orange  me-2 mt-1 fs-12'>Delete</Link> */}
    //     //                     <Link to={`/viewTicket/${allData.id}`} className='btn btn-primary btn-orange w-59 mt-1 fs-12'>View</Link>
    //     //                 </>
    //     //             )
    //     //         }
    //     //     }
    //     // }

    // ];

    console.log("______________________________________userdata is here ",userData)
    return (
        <>
           <Header2 />
            <Sidebar />
            <PageTitle />
            <ToasterContainer />
            <div className="content-body">
                <div className="container">
                    <div className="row">
          
                        <div className="col-xl-12">
                        <h4>Banking Detail</h4>
                        <Link to="/createBankingDetail" className="btn btn-success px-4 float-right">+ Create Bank Detail</Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid d-flex flex-column mt-5">
                    <div className="row custom-height">
                    <div className="col-xl-9">
                        <table className="table">
                            <tr>
                                <th>Account Number</th>
                                <th>Account Holder Name</th>
                                <th>IFSC Code</th>
                                <th>Bank Name</th>
                                <th>Bank Passbook</th>
                            </tr>
                            <tr>
                                <td>{userData?.data?.account_number ? userData?.data?.account_number : 'N/A'}</td>
                                <td>{userData?.data?.account_holder_name ? capitalizeFirstLowercaseRest(userData?.data?.account_holder_name) : 'N/A'}</td>
                                <td>{userData?.data?.ifsc_code ? userData?.data?.ifsc_code  : 'N/A'}</td>
                                <td>{userData?.data?.bank_name ? userData?.data?.bank_name  : 'N/A'}</td>
                                <td><img src={userData?.data?.bank_passbook ? userData?.data?.bank_passbook : dummy} alt="user_bank_passbook" height={100} width={100}/> </td>
                            </tr>
                        </table>
                        {/* {
                            <BootstrapTable keyField='id' filter={filterFactory()} classes="custom-ticket-code dataTable" noDataIndication={() => { return 'No Data Found' }} columns={columns} data={userData?.data ? userData?.data : ''} bordered={false} />
                        } */}

                    </div>
                    <OrderPlace/>
                </div>
            </div>
            </>
    )
}