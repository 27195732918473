import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PageTitle from '../element/page-title';
import Footer2 from '../layout/footer2';
// import { Row, Col, Card } from 'react-bootstrap';
import Header2 from '../layout/header2';
import SettingsSubmenu from '../layout/settings-submenu';
import Sidebar from '../layout/sidebar';
import MultiFactorMenu from '../layout/multiFactorTabs';
import { Nav, Tab } from 'react-bootstrap';
import PerfectScrollbar from "react-perfect-scrollbar";
import multiAuthimg from "../../util/images/multiDownload.png"
import appStore from "../../util/images/appstorebutton.png"
import googlePlay from "../../util/images/googlestorebutton.png"
import { createLogin } from '../../store/BasicInfo/basicInfoSlice';
import { useDispatch, useSelector } from 'react-redux';
import { showMultiFactorRecord, MultiFactorAuthenticate } from '../../store/BasicInfo/basicInfoSlice';
import show from "../../util/images/show.png"
import hide from "../../util/images/hide.png"
import ToasterContainer from '../component/ToasterContainer';
import dummy from "../../util/images/dummy.png"
import OrderPlace from '../component/placeOrder/orderPlace';

function Security() {

    const [isVisible, setVisible] = useState(false);
    const [values, setValues] = useState({ password: "", token: "" })
    const [Validations, setValidation] = useState({ password: "", token: "" })
    const dispatch = useDispatch();
    const [multiAuth, setMultiAuth] = useState("");
    const [StatusMark, setStatusMark] = useState("");
    const navigate=useNavigate();

    const { isMultiFactor, multiFactorDetail, isTwoFactor, twoFactorDetail } = useSelector((state) => state.basic);


    const previousProps = useRef({ isTwoFactor }).current;
    useEffect(() => {
        if (previousProps?.isTwoFactor != isTwoFactor && twoFactorDetail?.message == "Multi Auth login successfully") {
                navigate("/dashboard")
        }
    }, [isTwoFactor, twoFactorDetail])

    const toggle = () => { setVisible(!isVisible); };
    const [active,setActive]=useState("first_phase")
    useEffect(() => {
        if (isMultiFactor && multiFactorDetail) {
            setMultiAuth(multiFactorDetail?.data)
        }
        setStatusMark("Enabled")
    }, [multiFactorDetail, isMultiFactor])

    useEffect(() => {
        dispatch(showMultiFactorRecord())
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value })
    }

    console.log("____________________________________________multi auth is here ************************=======>>>>>>",multiAuth)

    function ValidationAll() {
        let Validations = {};
        let isValid = true;
        if (!values.password) {
            Validations.password = "Please enter the password"
            isValid = false
        }
        if (!values.token) {
            Validations.token = "Please enter the token"
            isValid = false
        }
        if (values.password && !values.password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)) {
            Validations.password = "Please enter the valid password address eight character,uppercase,lowercase,spec char"
            isValid = false
        }
        if (!isValid) {
            setValidation(Validations)
        }
        return isValid;
    }


    const validateOne = (e) => {
        const { name } = e.target
        const value = values[name];

        let message = ''
        if (!value && name === 'password') {
            message = `Please enter the password`
        }
        if (!value && name === 'token') {
            message = `Please enter the token`
        }
        setValidation({ ...Validations, [name]: message })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const isValid = ValidationAll();
        if (!isValid) { return false; }

        let multiAuthLogin = {
            password: values.password,
            token: values.token,
        }
        setStatusMark("Enabled")
        dispatch(MultiFactorAuthenticate(multiAuthLogin))
    }

    const sethandleType = (phase) => {
        if(phase==="first_phase"){
            setActive("active")
        } else if(phase==="second_phase"){
            setActive("active")
        }
    }

    return (
        <>
            <ToasterContainer />
            <Header2 />
            <Sidebar />
            <PageTitle />

            <div className="content-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card sub-menu">
                                <div className="card-body">
                                    <SettingsSubmenu />
                                </div>
                            </div>
                        </div>

                        {/* new design start here by pankaj designer */}
                        <div class="col-xl-9 col-lg-9 col-md-12 col-xxl-9 security-col">
								<div class="card">
									<div class="inner-card-header card-header">
										<h4>Security</h4>
									</div>
									<div class="inner-card-header card-header border-0 justify-content-center mt-4">
										<ul class={multiAuth?.multi_factor_status===false ? "nav nav-pills" : "nav nav-pills custom-nav-pills"} role="tablist">
											<li class="nav-item">
												<a class={multiAuth?.multi_factor_status === true ? "d-none" : 'nav-link active'} data-toggle="pill" href="#downloadapp" role="tab" aria-selected="true"><label>1</label> <span>Download App</span></a>
											</li>
											<li class="nav-item">
												<a class={multiAuth?.multi_factor_status === true ? "d-none" : 'nav-link'}  data-toggle="pill" href="#scan" role="tab" aria-selected="false"><label>2</label> <span>Scan QR Code</span></a>
											</li>
											<li class="nav-item">
												<a class={multiAuth?.multi_factor_status === true ? "d-none" : 'nav-link'} data-toggle="pill" href="#backupkey" role="tab" aria-selected="false"><label>3</label> <span>Backup Key</span></a>
											</li>
											<li class="nav-item">
												<a class={multiAuth?.multi_factor_status===true ? 'active' : 'nav-link'} data-toggle="pill" href="#enablegoogle" role="tab" aria-selected="false"><label>4</label> <span>Enable Google 2FA</span></a>
											</li>
										</ul>
									</div>
									<div class="card-body">
										<div class="row">
											<div class="col-md-12">
												<div class="tab-content">
                                                {multiAuth?.multi_factor_status === false &&
													<div class="tab-pane fade show active" id="downloadapp" role="tabpanel">
														<div class="row mt-5 mb-5">
															<div class="col-md-6 m-auto text-center">
																<div class="id_info">
																	<img src={multiAuthimg} alt="Download App" class="img-fluid" />
																	<h4>Stronger security with Google Authenticator</h4>
																	<p>Download & Install Google Authentication App</p>
																</div>
																<div class="downapp d-flex justify-content-center">
																	<Link to="#"><img src={appStore} alt="Download App" class="img-fluid" /></Link>
																	<Link><img src={googlePlay} alt="Download App" class="img-fluid" /></Link>
																</div>
																<div class="btn-inline mt-4">
																<a data-toggle="pill" role="tab" aria-selected="false" href="#scan" class="btn btn-success" onClick={() => sethandleType("first_phase")}>Next</a>
																</div>
															</div>
														</div>
													</div>
                                                }
                                                {
                                                    multiAuth?.multi_factor_status === false &&
													<div class="tab-pane fade show" id="scan" role="tabpanel">
														<div class="row mt-5 mb-5">
															<div class="col-md-6 m-auto text-center">
																<div class="id_info">
																	<img src={multiAuth?.QrCodeLink ? multiAuth?.QrCodeLink :  dummy} alt="qrcode" class="img-fluid" />
																	<h4>{multiAuth?.secret || 'N/A'}</h4>
																	<p class="mt-2">If you have any problem with scanning the QR code enter this code manually into the APP.</p>
																</div>
																<div class="btn-inline mt-4">
																	<a data-toggle="pill" role="tab" aria-selected="false" href="#backupkey" class="btn btn-success">Next</a>
																</div>
															</div>
														</div>
													</div>
                                                }
												{
                                                    multiAuth?.multi_factor_status === false &&
                                                    <div class="tab-pane fade show" id="backupkey" role="tabpanel">
														<div class="row mt-5 mb-5">
															<div class="col-md-6 m-auto text-center">
																<div class="id_info">
																	<h4>{multiAuth?.secret || 'N/A'}</h4>
																	<p class="mt-2">Please save this Key on paper. This Key will allow you to recover your Google Authentication in case of phone loss.</p>
																	<p class="mt-2 price_red">Resetting your Google Authentication requires opening a support ticket and takes at least 7 days to process.</p>
																</div>
																<div class="btn-inline mt-4">
																	<a data-toggle="pill" role="tab" aria-selected="false" href="#enablegoogle" class="btn btn-success">Next</a>
																</div>
															</div>
														</div>
													</div>
                                                }

													<div class={multiAuth?.multi_factor_status===true ? 'show active' : 'tab-pane fade show'} id="enablegoogle" role="tabpanel">
														<div class="row mt-5 mb-5">
															<div class="col-md-5 m-auto">
																<form onSubmit={(e) => handleSubmit(e)}>
																	<div class="form-group password-group">
																		<label>Password</label>
																		<input type={!isVisible ? "password" : "text"} class="form-control" placeholder='Please enter the password' onBlur={validateOne} onChange={handleChange} name="password" id="pwd" />													
																		{
                                                                                !isVisible ? (<i class="mdi mdi-eye" onClick={toggle}></i>) : (<i class="mdi mdi-eye-off" onClick={toggle}></i>)
                                                                        } 
                                                                        <small style={{ color: 'red' }}>{Validations?.password}</small>  
																	</div>
																	<div class="form-group">
																		<label>Google Authentication Code:</label>
																		<input type="text" name="token" onBlur={validateOne} placeholder='Please enter the token' onChange={handleChange} class="form-control code2" />
                                                                        <small style={{ color: 'red' }}>{Validations?.token}</small>
                                                                    </div>
                                                                    <div class="btn-inline mt-4">
																	<button type="submit" class="btn btn-success w-100"> {multiAuth?.multi_factor_status === true ? "Disabled" : StatusMark} </button>
																</div>
                                                                </form>
															</div>
														</div>
													</div>

												</div>
											</div>
										</div>
										
									</div>
								</div>
							</div>
                        	<OrderPlace />
                        {/* end of new design is here */}

                    </div>
                </div>
            </div>

            <Footer2 />
        </>
    )
}

export default Security;