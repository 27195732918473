import axios from "axios";
import { BASE_URL_URL, placeOrder, cancelOrder, getOrderBook, getOrderHistory, getAllCurrenciesPair, getAllCurrenciesTabs, getTopTenActiveBuyOrders, getTopTenActiveSellOrders, getInnerHeaderDetail, getUserBalanceByAuthId, getDefaultTermId } from "../../util/constant";
import { toast } from "react-toastify"

//place order based on buy or sell
const placeOrderForExchangeOrder = async (tokenDetails, userData) => {
    try {
        const response = await axios.post(BASE_URL_URL + placeOrder , userData, tokenDetails);
        console.log("________________________________________response is here ****************",response)
        if (response?.data?.message) {
            toast.success(response?.data?.message);
        } else if(response?.data?.status==true){
            //if status getting true then that case set static message
            toast.success("Your Order has been Placed successfully")
        }
        return response?.data;
    } catch (error) {
        if (error) {
            toast.error(error?.response?.data?.message)
        }
        console.log("_______________________________________error", error);
        throw error;
    }
}
//cancel order of user
const cancelOrderForExchange = async (tokenDetail, userData) => {
    try {
        const response = await axios.post(BASE_URL_URL + cancelOrder, userData, tokenDetail);
        if (response) {
            toast.success(response?.data?.message);
        }
        return response?.data;
    } catch (error) {
        if (error) {
            toast.error(error?.response?.data?.message)
        }
        console.log("_______________________________________error", error);
        throw error;
    }
}
//get order book api call here for multiple cases
const getOrderBookForExchange = async (tokenDetail, userData) => {
    try {
        const response = await axios.post(BASE_URL_URL + getOrderBook, userData, tokenDetail);
        console.log("__________************************************respoinse is here >>>>>>>>>>>>>>>",response)
       if(response){
        return response?.data;
       }
    } catch (error) {
        console.log("_______________________________________error", error);
        throw error;
    }
}

//get top ten order history by pair id
const getTopOrderHistoryShow = async (userData) => {
    try {
        const response = await axios.post(BASE_URL_URL + getOrderHistory, userData);
        return response?.data;
    } catch (error) {
        console.log("_______________________________________error", error);
        throw error;
    }
}

//get all currencies by pair
const getAllCurrenciesByPairs = async (userData) => {
    try {
        const response = await axios.post(BASE_URL_URL + getAllCurrenciesPair,userData);
        return response?.data;
    } catch (error) {
        console.log("_______________________________________error", error);
        throw error;
    }
}
const getAllPairCurrenciesTabs = async () => {
    try {
        const response = await axios.get(BASE_URL_URL + getAllCurrenciesTabs);
        return response?.data;
    } catch (error) {
        console.log("_______________________________________error", error);
        throw error;
    }
}
//get buy top ten order and order by asc
const getActiveOrderByTopTen = async (userData) => {
    try {
        const response = await axios.post(BASE_URL_URL + getTopTenActiveBuyOrders,userData);
        return response?.data;
    } catch (error) {
        console.log("_______________________________________error", error);
        throw error;
    }
}

const getActiveSellOrderByTopTen = async (userData) => {
    try {
        const response = await axios.post(BASE_URL_URL + getTopTenActiveSellOrders,userData);
        return response?.data;
    } catch (error) {
        console.log("_______________________________________error", error);
        throw error;
    }
}


const getInnerHeaderDetailForExchange = async (userData) => {
    try {
        const response = await axios.post(BASE_URL_URL + getInnerHeaderDetail,userData);
        return response?.data;
    } catch (error) {
        console.log("_______________________________________error", error);
        throw error;
    }
}
//get user balance along with currencies detail
const getUserBalanceByUser = async (tokenDetail) => {
    try {
        const response = await axios.get(BASE_URL_URL + getUserBalanceByAuthId,tokenDetail);
        return response?.data;
    } catch (error) {
        console.log("__________________________________________________error",error);
        throw error;
    }
}

const getDefaultRunningPairId = async (tokenDetail) => {
    try {
        const response = await axios.get(BASE_URL_URL + getDefaultTermId,tokenDetail);
        return response?.data;
    } catch (error) {
        console.log("__________________________________________________error",error);
        throw error;
    }
}
const PlaceOrderService = {
	placeOrderForExchangeOrder,
    cancelOrderForExchange,
    getOrderBookForExchange,
    getTopOrderHistoryShow,
    getAllCurrenciesByPairs,
    getAllPairCurrenciesTabs,
    getActiveOrderByTopTen,
    getActiveSellOrderByTopTen,
    getInnerHeaderDetailForExchange,
    getUserBalanceByUser,
    getDefaultRunningPairId
}
export default PlaceOrderService;