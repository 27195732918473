import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import { GetAuthLoggerDetail } from "../component/helper";

export const useAuth = () => {
    const userData = JSON.parse(localStorage.getItem("authLogger"));
   
    if(userData){
        const { exp } = jwtDecode(userData)
        // Refresh the token a minute early to avoid latency issues
        const expirationTime = (exp * 1000) - 60000
        if (Date.now() >= expirationTime) {
            window.localStorage.clear();
            localStorage.removeItem("authLogger")
            window.location.href="/signin"
                toast.success("Session has benn expired now you need to login again")
        }
    }
    const user = userData ? { logger: true } : { logger: false };
    return user.logger;
}

export const ProtectedRouteCheck = ({ children }) => {
    const isAuth = useAuth();
    const authDetail=GetAuthLoggerDetail();
    const {loginDetail}=useSelector((state) => state.basic);
    return isAuth ? <Navigate to="/exchange" /> : children;
}

const ProtectedRoute = () => {
    const isAuth = useAuth();
    return isAuth ? <Outlet /> : <Navigate to="/signin" />;
};

export const ProtectedResetPassword = () => {
    // const {message}=useSelector((state) => state.forgotauth);
    // console.log('route message is here',message)
    // if(message) {
    //     return message.message = 'Token has been expired ,Please Try again' ? <Navigate to="/forgotPassword" /> : children
    // } 
}
export default ProtectedRoute;