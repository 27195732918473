import React from "react";
import { Link } from "react-router-dom";
// import { Row, Col, Card } from 'react-bootstrap'



function Footer1() {
    return (
        <>
             <div class="footer dashboard">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-8 col-12">
                        <div class="copyright">
                            <p>© Copyright 2023 <Link to="/exchange" class="text-white">Exchange</Link> I
                                All Rights Reserved</p>
                        </div>
                    </div>
                    <div class="col-sm-4 col-12">
                        <div class="footer-social">
                            <ul>
                                <li><Link href="#"><i class="fa fa-facebook"></i></Link></li>
                                <li><Link href="#"><i class="fa fa-twitter"></i></Link></li>
                                <li><Link href="#"><i class="fa fa-linkedin"></i></Link></li>
                                <li><Link href="#"><i class="fa fa-youtube"></i></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
        </>
    );
}

export default Footer1;
