import React from 'react'
import { ToastContainer, Toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

export default function ToasterContainer() {
  return (
    <>
    <ToastContainer
    position='top-right'
    autoClose={2500}
    hideProgressBar={false}
    newestOnTop={true}
    closeOnClick={true}
    rtl={false}
    />
    </>
  )
}
