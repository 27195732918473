import React, { useEffect, useRef, useState } from "react";
import Header2 from "../../layout/header2";
import Sidebar from "../../layout/sidebar";
import SettingsSubmenu from "../../layout/settings-submenu";
import ToasterContainer from "../../component/ToasterContainer";
import PageTitle from "../../element/page-title";
import { Link } from "react-router-dom";
import { getAllSupportDetailForUser } from "../../../store/BasicInfo/basicInfoSlice";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import useDocumentTitle from "../../../helper/useTitle"
import LoadingOverlay from 'react-loading-overlay';

import axios from "axios";
import { getAllSupport,BASE_URL_URL,REACT_APP_S3_CLOUD_IMAGE_URL } from "../../../util/constant";
import dummy from "../../../util/images/dummy.png"
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeFirstLowercaseRest } from "../../component/helper";
import OrderPlace from "../../component/placeOrder/orderPlace";

export default function Ticket() {
    useDocumentTitle("Support Listing | Exchange")
    const [userData,setUserData]=useState("");
    const dispatch=useDispatch();
    const [def,setDefault]=useState(0);
    const {isSupportTicket,getSupportTicketDetail,message}=useSelector((state) => state.basic);

    // console.log("____________",isSupportTicket,"getSupportTicketDetail",getSupportTicketDetail);

    //getting all detail for the support ticket
    useEffect(() => {
        dispatch(getAllSupportDetailForUser({type:0}))
    },[])

    const handeOpenEvent = (e,count) => {
        setDefault(count)
        e.preventDefault();
        if(count===0){
            dispatch(getAllSupportDetailForUser({type:0}))
        } else if(count===1){
            dispatch(getAllSupportDetailForUser({type:1}))
        } else if(count==2) {
            dispatch(getAllSupportDetailForUser({type:2}))
        } 
    }

    useEffect( () => {
        if(isSupportTicket && getSupportTicketDetail) {
             setUserData(getSupportTicketDetail)
        }
    },[isSupportTicket,getSupportTicketDetail?.data,message])

    const columns = [
        {
          dataField: "enquiry",
          text: "Enquiry",
          sort:true,
          className:'d-flex align-items-center',
		//    headerFormatter:function(){
        //     return <img src={dummy} height={50} />
        //    },
		  formatter:(cell)=> {
			return cell ? capitalizeFirstLowercaseRest(cell) :'N/A'
		  }
        },
        {
          dataField: "department",
          text: "Department",
		  formatter: (cell) => {return <>{cell ? capitalizeFirstLowercaseRest(cell) : 'N/A'}</>}
        },
		{
			dataField:"description",
			text:"Description",
			formatter: (cell) => {return <>{cell ? cell : 'N/A'}</>}
		},
        {
          dataField: "ticket_status",
          text: "Ticket Status",
		  formatter:(cell) => {
            return  cell===1 || cell===0 ? (<small className="badge bg-success">Open</small>) : (<small className="badge bg-danger">Close</small>) 
        }
        },
        {
            dataField: "upload_document",
            text: "Upload Document",
            formatter:(data,rowData)=>{
				if(data){
					return (<img src={data ? data : dummy} height={50} width={50} alt="upload document" />)
				} else {
					return (<img src={dummy} height={50} width={50} alt={"dummy image"}/>)
				}
			}
        },
        {
            dataField:"status",
            text:"",
			headerFormatter:()=> {
				 return (<span>Action</span>);
			},
            formatter:(data,allData)=> {
				if(+data===0) {
					return (
						<>
						{/* <Link to="#" className='btn btn-primary me-2 fs-12 w-59' onClick={(e) => handleStatus(e,allData.id)}>Block</Link>
					<Link to={`/editUser/${allData.id}`} className='btn btn-outline-primary w-59 fs-12'>Edit</Link>
					<Link to="#" onClick={(e) => handleDelete(e,allData.id)} className='btn btn-outline-dark w-59 btn-outline-orange  me-2 mt-1 fs-12'>Delete</Link> */}
					<Link to={`/viewTicket/${allData.id}`}  ><i class="fa fa-eye"></i></Link>
					</>
					
					)
				} else if(+data===1)
				{
					return (
						<>
					{/* <Link to="#" className='btn btn-outline-primary w-59 fs-12' onClick={(e) => handleStatus(e,allData.id)}>UnBlock</Link>
					<Link to={`/editUser/${allData.id}`} className='btn btn-outline-primary w-59 fs-12'>Edit</Link>
					<Link to="#" onClick={(e) => handleDelete(e,allData.id)} className='btn btn-outline-dark w-59 btn-outline-orange  me-2 mt-1 fs-12'>Delete</Link> */}
					<Link  to={`/viewTicket/${allData.id}`}  className='btn btn-primary btn-orange w-59 mt-1 fs-12'>View</Link>
					</>
					)
				}
			}
        }
		
    ];

    return (
        <>
            <Header2 />
            <Sidebar />
            <PageTitle />
            <ToasterContainer />
            <div className="content-body">
                <div className="container-fluid">
                    <div className="row">
                       
                        <div className="col-xl-12">
                            
                            <div className="card sub-menu">
                                <div className="card-body">
                                <ul className="d-flex">
                              
                <li className="nav-item">
                    <Link className={def===0 ? "nav-link active":"nav-link"} onClick={(e) => handeOpenEvent(e,0)}>
                        {/* <i className="mdi mdi-account-settin?gs-variant"></i> */}
                        <span>({userData?.message==="All Supports Fetched Successfully" ? userData?.data?.length : '0'}) All</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link  onClick={(e) => handeOpenEvent(e,1)} className="nav-link">
                        {/* <i className="mdi mdi-settings"></i> */}
                        <span>({userData?.message==="All open fetched support Successfully" ? userData?.data?.length : '0'}) Open</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link onClick={(e) => handeOpenEvent(e,2)} className="nav-link">
                        {/* <i className="mdi mdi-lock"></i> */}
                        <span>({userData?.message==="All closed fetch support Successfully" ? userData?.data?.length : '0'}) Close</span>
                    </Link>
                    
                </li>
            </ul>
            <Link to="/createTicket" className="btn btn-success px-4 float-right">+ Create Ticket</Link>
                                </div>
                                
                            </div>
                         
                        </div>
                        <div className="container-fluid d-flex flex-column">
                        <div className="row">
                        <div className="col-xl-9">
                        
                              
                                {
                                        // isLoading ? (<div className="custom-loading-func"><LoadingOverlay style={{position:'reset'}} className="custom-loading" active={true} spinner text='Loading...'></LoadingOverlay> </div>):
                                        <BootstrapTable keyField='id' classes="custom-ticket-code" noDataIndication={() => {return 'No Data Found'}} columns={columns} data={userData?.data ? userData?.data : '' } bordered={false} pagination={userData?.data && paginationFactory({
                                            page:1,
                                            sizePerPage:10,
                                            firstPageText:'>>',
                                            nextPageText:'<<',
                                            prePageText:'<',
                                            showTotal:true,
                                            alwaysShowAllBtns:true,
                                            onPageChange:function(page,sizePerPage) {
                                                console.log("page is here",page)
                                            },
                                            onSizePerPageChange:function(page,sizePerPage){
                                                console.log("page",page)
                                            }

                                        })} />
                                    }

                              
                            
                          
                        </div>
                        <OrderPlace/>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </>
    )
}