import React, {  } from 'react';
 // import { Link } from 'react-router-dom';
// import { Row, Col, Card } from 'react-bootstrap';
import Header2 from '../layout/header2';
import Sidebar from '../layout/sidebar';
import PageTitle from '../element/page-title';
import Footer2 from '../layout/footer2';



function Error404() {

    return (
        <>
            <Header2 />
            <Sidebar />
            <PageTitle />
            <h1 className='page-title-content'><p>Page Not Found</p></h1>
            <Footer2 />
        </>
    )
}

export default Error404;