import React, { element } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Homepage from './pages/index';
import Dashboard from './pages/dashboard';
import Exchange from './pages/exchange';
import Tbi from './pages/data-tbi';
import Settings from './pages/settings';
import AccountAffiliate from './pages/account-affiliate';
import AccountApi from './pages/account-api';
import AccountDeposit from './pages/account-deposit';
import AccountOverview from './pages/account-overview';
import AccountWithdraw from './pages/account-withdraw';
import BankAcc from './pages/add-bank-acc';
import DebitCard from './pages/add-debit-card';
import FundingRate from './pages/data-funding-rate';
import IndexPrice from './pages/data-index-price';
import InsuranceFund from './pages/data-insurance-fund';
import LastPrice from './pages/data-last-price';
import MarkPrice from './pages/data-mark-price';
import Demo from './pages/demo';
import Lock from './pages/lock';
import Otp1 from './pages/otp-1';
import Otp2 from './pages/otp-2';
import Reset from './pages/reset';
import SettingAccount from './pages/settings-account';
import Preferences from './pages/settings-preferences';
import Security from './pages/settings-security';
import Signin from './pages/signin';
import Signup from './pages/signup';
import VerifyStep1 from './pages/verify-step-1';
import VerifyStep2 from './pages/verify-step-2';
import VerifyStep3 from './pages/verify-step-3';
import VerifyStep4 from './pages/verify-step-4';
import VerifyStep5 from './pages/verify-step-5';
import VerifyStep6 from './pages/verify-step-6';
import Error404 from "./pages/404";
import ForgotPassword from './pages/forgotPassword';
import ProtectedRoute, { ProtectedRouteCheck } from './middleware/protectedRoute';
import Ticket from './pages/supportSection/tickets';
import ChangePassword from './pages/changePassword';
import CreateTicket from './pages/supportSection/createTicket';
import ViewTicket from './pages/supportSection/viewTicket';
import DownloadFactor from './component/MultiFactorAuth/downloadFactor';
import VerifyOtp from './pages/verifyotp';
import CreateKyc from './pages/kyc/createKyc';
import ListingKyc from './pages/kyc/kycListing';
import CreateBankingDetail from './pages/banking/createBanking';
import BankingListing from './pages/banking/bankingListing';
import Funds from './pages/currencies/funds';
import Deposit from './pages/deposit/deposit';
import EditKyc from './pages/kyc/editKyc';
import AccountDepositCrypto from './pages/deposit/accountDepositCrypto';

export default function Index() {

        return (
            <>
                {/* <BrowserRouter basename={'/demo/tradix_react'}> */}
                <BrowserRouter >
                    <div id="main-wrapper">
                        <Routes>
                       
                        {/* //out from the auth route is here */}
                        <Route path='/signin' element={
                        <ProtectedRouteCheck><Signin/></ProtectedRouteCheck>
                        }
                            />
                        
                        <Route path='/signup' element={<Signup/>} />
                        <Route path="/forgotPassword" element={< ForgotPassword />} />
                        <Route path='/resetPassword/:id' element={<Reset/>} />
                        <Route path='/exchange' element={<Dashboard/>} />

                        {/* <Route path="/dashboard" element={<Navigate to="/" />} /> */}
                        <Route exact path='/' element={ <Homepage/>} />
                        <Route path="/verifyOtp" element={<VerifyOtp/>} />
                        <Route element={< ProtectedRoute/>}>
                            <Route path="/accountDepositCrypto" element={<AccountDepositCrypto/>} />
                            <Route path="/createKyc" element={<CreateKyc/>} />
                            <Route path="/editKyc/:id" element={<EditKyc/>} />
                            <Route path="/deposit/:curr" element={<Deposit />} />
                            <Route path="/funds" element={<Funds/>} />
                            <Route path="/createBankingDetail" element={<CreateBankingDetail />} />
                            <Route path="/gettingBankListing" element={ <BankingListing/> } />
                           <Route path="/kycListing" element={<ListingKyc/>} />
                       
                            <Route path="/viewTicket/:id" element={<ViewTicket/>} />
                            <Route path="/createTicket" element={<CreateTicket/>} />
                            <Route path="/support" element={<Ticket/>}/>
                            <Route path="/changePassword" element={<ChangePassword/>} />

                            <Route path='/exchange' element={<Dashboard/>} />
                            {/* <Route path='/exchange' element={<Exchange/>} /> */}
                            <Route path='/account-overview' element={<AccountOverview/>} />
                            <Route path='/data-tbi' element={<Tbi/>} />
                            <Route path='/settings' element={<Settings/>} />
                            <Route path='/account-affiliate' element={<AccountAffiliate/>} />
                            <Route path='/account-api' element={<AccountApi/>} />
                            <Route path='/account-deposit' element={<AccountDeposit/>} />
                            <Route path='/account-withdraw' element={<AccountWithdraw/>} />
                            <Route path='/add-bank-acc' element={<BankAcc/>} />
                            <Route path='/add-debit-card' element={<DebitCard/>} />
                            <Route path='/data-funding-rate' element={<FundingRate/>} />
                            <Route path='/data-index-price' element={<IndexPrice/>} />
                            <Route path='/data-insurance-fund' element={<InsuranceFund/>} />
                            <Route path='/data-last-price' element={<LastPrice/>} />
                            <Route path='/data-mark-price' element={<MarkPrice/>} />
                            <Route path='/demo' element={<Demo/>} />
                            <Route path='/lock' element={<Lock/>} />
                            <Route path='/otp-1' element={<Otp1/>} />
                            <Route path='/otp-2' element={<Otp2/>} />
                         
                          
                            <Route path='/settings-account' element={<SettingAccount/>} />
                            <Route path='/settings-preferences' element={<Preferences/>} />
                            <Route path='/settings-security' element={<Security/>} />
                           
                          
                            <Route path='/verify-step-1' element={<VerifyStep1/>} />
                            <Route path='/verify-step-2' element={<VerifyStep2/>} />
                            <Route path='/verify-step-3' element={<VerifyStep3/>} />
                            <Route path='/verify-step-4' element={<VerifyStep4/>} />
                            <Route path='/verify-step-5' element={<VerifyStep5/>} />
                            <Route path='/verify-step-6' element={<VerifyStep6/>} />
                            </Route>
                            <Route path="*" element={<Error404/>}/>
              
                        </Routes>
                    </div>
                </BrowserRouter>

            </>
        );
    
}