import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ToasterContainer from "../component/ToasterContainer";
import { verificationOtp } from "../../store/BasicInfo/basicInfoSlice";
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from 'react-loading-overlay';
import useDocumentTitle from "../../helper/useTitle"

export default function VerifyOtp() {
    useDocumentTitle("Verify OTP | Exchange");
    const [values, setValues] = useState({ otp: "" });
    const [Validations, setValidation] = useState({ otp: "" });
    const [isActive,isSetActive]=useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {isVerifiedOtp, verificationDetail,message}=useSelector((state) => state.basic);

    const previousProps=useRef({isVerifiedOtp}).current;
    
    useEffect(() => {
        if(previousProps?.isVerifiedOtp!=isVerifiedOtp && message=="Otp has been verified Successfully" && verificationDetail?.message=="Otp has been matched successfully") {
            isSetActive(true)
            setTimeout(() => {
                navigate("/dashboard")
            }, 1000);
        }
    },[isVerifiedOtp,message,verificationDetail]);

    function ValidationAll() {
        let Validations = {};
        let isValid = true;

        if (!values.otp) {
            Validations.otp = "Please enter the otp"
            isValid = false
        }
        // if (values.otp && !values.otp.match(/^[0-9]*$/)) {
        //     Validations.otp = "Please enter the valid otp number"
        //     isValid = false
        // }
       
        if (!isValid) {
            setValidation(Validations)
        }
        return isValid;
    }
    const validateOne = (e) => {
        const { name } = e.target
        const value = values[name];
        let message = ''
        if (!value && name === 'otp') {
            message = `Please enter the otp`
        }
        setValidation({ ...Validations, [name]: message })
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value })
    }

    const handleSubmit =  (e) => {
        e.preventDefault();
        //check validation is fine or not
        const isValid = ValidationAll();
        if (!isValid) { return false; }
        let payloadOtp = {otp: values.otp}
      
         dispatch(verificationOtp(payloadOtp))
    }

    return (
        <>
        <LoadingOverlay active={isActive} spinner text='Loading...'>
            <ToasterContainer />
            <div className="authincation section-padding">
                <div className="container h-100">
                    <div className="row justify-content-center h-100 align-items-center">
                        <div className="col-xl-5 col-md-6">
                            <div className="mini-logo text-center my-5">
                                <Link to="/">
                                    <img src={require("../../images/crypot-exchange-logo.png")} alt="crypto-exchange" />
                                </Link>
                            </div>
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className="auth-form card">
                                    <div className="card-header justify-content-center">
                                        <h4 className="card-title">Verify Otp</h4>
                                    </div>
                                    <div className="card-body">

                                        <div className="mb-3">
                                            <label>OTP</label>
                                            <input type="text" className="form-control" maxLength={20} onBlur={validateOne} onChange={handleChange} placeholder="Authentication Code" name="otp" />
                                            <small style={{ color: 'red' }}>{Validations.otp}</small>
                                        </div>
                                        <div className="text-center">
                                            <button type="submit" className="btn btn-success btn-block">Verify</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
           </LoadingOverlay>         
        </>
    )
}