import React, { useState } from "react";
import Header2 from "../../layout/header2";
import Sidebar from "../../layout/sidebar";
import PageTitle from "../../element/page-title";
import ToasterContainer from "../../component/ToasterContainer";
import { useNavigate, useParams } from "react-router-dom";
import dummy from "../../../util/images/dummy.png"
import { gettingBankDetailForDepositAdmin, gettingFiatDepositCurrenciesRecord } from "../../../store/BasicInfo/basicInfoSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useRef } from "react";
import { capitalizeFirstLowercaseRest, getLastFileNameFromUrl } from "../../component/helper";
import { useLocation } from "react-router-dom";
import { createDepositForCryptoCurrencies } from "../../../store/BasicInfo/basicInfoSlice";
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment"
import LoadingOverlay from "react-loading-overlay";
import OrderPlace from "../../component/placeOrder/orderPlace";

export default function Deposit() {

    const location=useLocation();
    const {currency_id}=location?.state;
    const [isActive,isSetActive]=useState(false);

    const [values, setValues] = useState({ deposit: "", remark: "" });
    const [Validations, setValidation] = useState({ deposit: "", remark: "" });
    const [submit,setSubmit]=useState("Submit")
    const params = useParams();
    const [image, setImage] = useState("");
    const [imgName, setImgName] = useState("");
    const [bankDetail,setBankDetail]=useState("");
    const [fiatDepositListing,setFiatDepositListing]=useState("")
    const symbolName = params.curr;
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const {isDepositBankByAdmin, DepositBankByAdmin, isFiatDeposit, gettingFiatDepositDetail, isDepositStatus, depositDetail}=useSelector((state) => state.basic);

    const previousProps=useRef({isDepositStatus}).current;

    useEffect(() => {
        if(previousProps.isDepositStatus!==isDepositStatus && depositDetail) {
            console.log("inner call is here **************************************",isDepositStatus,depositDetail)
            isSetActive(true)
            setTimeout(() => {
                isSetActive(false)
            }, 2000);
            //navigate(`/deposit`)
        }
    },[isDepositStatus, depositDetail])
    //update the state while getting the data from api end
    useEffect(() => {
        if(isDepositBankByAdmin && DepositBankByAdmin) {
                setBankDetail(DepositBankByAdmin?.data)
        }
    },[isDepositBankByAdmin, DepositBankByAdmin])

    //consume api for getting bank detail by admin
    useEffect(() => {
        dispatch(gettingBankDetailForDepositAdmin())
    },[])

    console.log("length is here **************************",fiatDepositListing.length)
    //consume api for getting all deposit based on fiat currencies 
    useEffect(() => {
        dispatch(gettingFiatDepositCurrenciesRecord())
    },[])

    //update the state while getting the deposit response
    useEffect(() => {
        if(gettingFiatDepositDetail) {
            setFiatDepositListing(gettingFiatDepositDetail?.data)
        }
    },[isFiatDeposit, gettingFiatDepositDetail])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value })
    }

    console.log("__________________________Bank detail is here *************************",bankDetail)


    function ValidationAll() {
        let Validations = {};
        let isValid = true;
        if (!values?.amount) {
            Validations.amount = "Please enter the deposit amount"
            isValid = false
        }
        if (values?.amount && !values.amount.match(/^[0-9]+$/)) {
            Validations.amount="Please enter the valid amount"
            isValid=false
        }

        if (!values?.remark) {
            Validations.remark = "Please enter the remark"
            isValid = false
        }
        if(!image){
            Validations.receipt_name="Please upload the receipt Image"
            isValid=false
        }

        if (!isValid) {
            setValidation(Validations)
        }
        return isValid;
    }

    const validateOne = (e) => {
        const { name } = e.target
        const value = values[name];
      
        let message = ''
        if (!value && name === 'amount') {
            message = `Please enter the amount`
        }
        if (!value && name === 'remark') {
            message = `Please enter the remark`
        }
        setValidation({ ...Validations, [name]: message })
    }

    let bank_Detail = [{
        bank_name:bankDetail?.bank_name || 'N/A',
        account_holder_name:bankDetail?.account_holder_name || 'N/A',
        account_number:bankDetail?.account_number || 'N/A',
        IFSC_CODE:bankDetail?.ifsc_code || 'N/A',
    }]

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const isValid = ValidationAll();
        if (!isValid) { return false; }

        let depositPayload= { 
            currency_id:currency_id || '',
            amount:values.amount,
            remark:values.remark,
            attachment:imgName,
            bank_detail:JSON.stringify(bank_Detail)
        }

        await dispatch(createDepositForCryptoCurrencies(depositPayload));
        await dispatch(gettingFiatDepositCurrenciesRecord())
        setValues({amount:"",remark:"",setImage:{dummy}})
    }

    //image mapping along with images
    const handleImageChange = (e) => {
        if (e.target.files.length != 0) {
            setImage(URL.createObjectURL(e.target.files[0]));
            setImgName(e.target.files[0])
        }
    }

    const columns = [
        {
            dataField: "reference",
            text: "Reference Code",
            sort: true,
            formatter: (cell, row) => {
                return (
                    <>{cell ? cell : 'N/A'}</>
                )
            }
        },
        {
            dataField: "amount",
            text: "Amount",
            formatter: (cell, row) => {
                return (<>{cell ? cell+" "+symbolName : 'N/A'}</>)
            }
        },
        {
            dataField: "createdAt",
            text: "Date",
            formatter: (data, allData) => {
              return (<>{data ? moment(data).format("DD/MM/YYYY hh:mm:ss") : 'N/A'}</>)
            }
        },
        {
            dataField: "status",
            text: "Status",
            formatter: (data, allData) => {
              console.log("__________________________________Data is here",data)
                if(+data==0){
                    return (<span class="badge badge-primary" style={{backgroundColor:"#337ab7"}}>Processing</span>)
                } else if(+data==1) {
                    return (<span class="badge badge-success" style={{backgroundColor:"green"}}>success</span>)
                }
            
            }
        }
    ];



    return (
        <>
        <LoadingOverlay active={isActive} spinner text='Loading...'>
            <Header2 />
            <Sidebar />
            <PageTitle />
            <ToasterContainer />
            <div className="content-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <h4>Deposit Information</h4>
                            {/* <Link to="/createBankingDetail" className="btn btn-success px-4 float-right">+ Create Bank Detail</Link> */}
                        </div>
                    </div>
                </div>
            </div>
            <div>
            </div>

            <div className="content-body">
                <div className="container-fluid">
                    <div className="row">
                   
                        <div class="col-md-5">
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div class="panel-collapse customtable form-colm mt-2">

                                    <input type="hidden" name="type" value="bank" />
                                    <div class="form-group">
                                        <label class="text-left full-width">Deposit Amount*</label>
                                        <input type="text" id="amount" name="amount" class="form-control" onBlur={validateOne} value={values?.amount} onChange={handleChange} maxLength={50} placeholder={symbolName ? symbolName : ''} />
                                        <small style={{ color: 'red' }}>{Validations?.amount}</small>
                                        <span class="error" id="amount_err"></span><br />
                                    </div>
                                    <div class="form-group nongateway">
                                        <label class="text-left full-width">Remarks*</label>
                                        <textarea class="form-control" rows="3" name="remark" maxLength={200} onBlur={validateOne} value={values?.remark} onChange={handleChange} placeholder="Please enter the remark" style={{ height: '90px;resize:verticle;', resize: 'none' }}></textarea>
                                        <small style={{ color: 'red' }}>{Validations?.remark}</small>
                                        <span class="error" id="remarks_err"></span>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-left full-width">Payment Receipt Screenshot </label><br />
                <img className="me-3 rounded-circle me-0 me-sm-3" draggable={false} src={image ? image : dummy} width="100" height="100" alt="Upload Passbook" /><br/>
                <div className="file-upload-wrapper" data-text="Upload receipt screen short">
                                        <input name="receipt" onChange={handleImageChange} type="file" className="form-control" />
                                        </div>
                                        <small style={{ color: 'red' }}>{Validations?.receipt_name}</small>
                                        <span class="error" id="amt_ss_err"></span>
                                    </div>
                                    <div class="form-group nongateway">
                                        {/* <div class="depcode" style="display:none;">
<label class="text-left full-width">Your deposit code </label>
<input type="hidden" name="symbol" id="symbol" value="INR"/>
<input type="text" style="border:none; width:53px;" id="code" name="code" />
<span class="pull-right copy-float" id="copyAnchor" style="cursor:pointer;" onclick="copyFunction($(this))" alt="Copy"> <i class="fal fa-copy" aria-hidden="true"> </i> </span>
</div> */}
                                        <div class="form-group mt-3 notecolm bg-dark">
                                            <h5 style={{ color: '#5d78ff' }}>Important Notes:</h5>
                                            <ul class="mt-2">
                                                <li>Please Provide your Deposit Code in the bank slip - remarks field to recognize your Deposit.</li>
                                                <li>The amount you send will be automatically reflected in your account after the security checks. No other action from you is needed.</li>
                                                {/* <li>If deposit request before 10:00 am and after 07:00 pm will be processed next working day.</li>
    <li>The amount you send will be automatically reflected in your account after the security checks. No other action from you is needed.</li>
    <li >During the transfer, you must write the deposit code determined specifically for you in the description field.</li>
    <li>Transfers made without deposit code will be refunded by deducting the transaction fee.</li>
    <li> If deposit request before 10:00 am and after 07:00 pm will be processed next working day.</li> */}
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                                <div class="mt-3 text-center">
                                    <button class="btn btn-primary" type="submit" id="submit_btn">{submit}</button>
                                </div>
                            </form>
                        </div>
                        <div class="col-md-4">
                            <div class="card acc_balance">
                                <div class="card-header">
                                    <h4 class="card-title">Bank Detail</h4>
                                </div>
                                <div class="card-body">
                                    <div class="d-flex justify-content-between my-3">
                                        <div>
                                            <p class="mb-1">Bank Name</p>
                                            <h4>{bankDetail?.bank_name || '-'}</h4>
                                            </div>
                                            <div><p class="mb-1">Account Number</p>
                                            <h4>{bankDetail?.account_number || '-'}</h4>
                                            </div></div><div class="d-flex justify-content-between my-3">
                                                <div>
                                                <p class="mb-1">IFSC CODE</p>
                                                <h4>{bankDetail?.ifsc_code || '-'}</h4>
                                                </div>

                                                <div>
                                                <span>Account Holder Name</span>
                                    <h4>{ capitalizeFirstLowercaseRest(bankDetail?.account_holder_name) || '-'}</h4>
                                                </div>
                                                    </div>
                                                    <div class="btn-group mb-3">
                                    </div>
                                    </div>
                                    </div>
                        </div>
                      <OrderPlace/>
                    </div>
                </div>
                <h4>Deposit Record</h4>
                <BootstrapTable keyField='id' classes="custom-ticket-code" noDataIndication={() => { return 'No Data Found' }} columns={columns} data={fiatDepositListing && fiatDepositListing} bordered={true} pagination={fiatDepositListing && paginationFactory({
                                page: 1,
                                sizePerPage: 10,
                                firstPageText: '>>',
                                nextPageText: '<<',
                                prePageText: '<',
                                showTotal: true,
                                alwaysShowAllBtns: true,
                                onPageChange: function (page, sizePerPage) {
                                    console.log("page is here", page)
                                },
                                onSizePerPageChange: function (page, sizePerPage) {
                                    console.log("page", page)
                                }
                            })} />
            </div>
            </LoadingOverlay>
        </>
    )
}