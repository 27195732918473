
import React, { useEffect, useRef, useState } from 'react';
import logo from './logo.svg';
import './App.css';
//import "../src/css/style.css"
import "../src/css/custom.css"
//import "../src/css/master_style.css"

import Index from "../src/jsx/index"
import OffLineModal from './jsx/component/offlineModal';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import "../src/icons/material-design-iconic-font/css/materialdesignicons.min.css";
//import { socket } from './socket/socket';
//import {io} from "socket.io-client";

const queryClient = new QueryClient();


function App() {
//const socket=useRef(); 

// useEffect(() => {
//   socket.current=io("ws://192.168.0.233:5000");
  
//   socket.current.on("connection",(socket) => {
//     console.log("connected to server");
//   });
// },[]);

// const handleClick = () => {
//   socket.current.emit("message","hello good evening we are peoples");
// }

  const [online, isOnline] = useState(navigator.onLine);
  let version = React.version;

  const setOnline = () => {
  
    isOnline(true);
  };
  const setOffline = () => {
   
    isOnline(false);
  };

  useEffect(() => {
    window.addEventListener("online", setOnline);
    window.addEventListener("offline", setOffline);

    return () => {
      window.removeEventListener('offline', setOffline)
      window.removeEventListener('online', setOnline)

    }
  }, [])


  return (
 
    <QueryClientProvider client={queryClient}>
      
      <div className="App">
        {/* <button type="button" onClick={handleClick} style={{marginTop:"50px"}}>Emit a time messsage</button> */}
        <OffLineModal display={!online} />
        <Index />
      </div>
    </QueryClientProvider>
  );
}

export default App;
