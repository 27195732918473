import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import show from "../../util/images/show.png"
import hide from "../../util/images/hide.png"
import { createLogin } from "../../store/BasicInfo/basicInfoSlice";
import { useDispatch, useSelector } from "react-redux";
import ToasterContainer from "../component/ToasterContainer";
import LoadingOverlay from 'react-loading-overlay';
import useDocumentTitle from "../../helper/useTitle"
function Signin() {
    useDocumentTitle('Sign in | Exchange')
    const [values,setValues]=useState({email:"",password:""});
    const [Validations, setValidation]=useState({email:"",password:""});
    const [isVisible,setVisible]=useState(false);
    const dispatch=useDispatch();
    const toggle = () => {setVisible(!isVisible);};
    const [isChecked, setIsChecked] = useState(false);
    const navigate=useNavigate();
    const [submit,setSubmit]=useState("Sign in");
    const [isActive,isSetActive]=useState(false);

    const {isLogin,loginDetail,message}=useSelector((state) => state.basic)

    const previousProps=useRef({isLogin}).current;
    
    useEffect(() => {
        if(previousProps.isLogin!==isLogin && message=="User has been login successfully" && loginDetail){
            isSetActive(true)
            console.log("__________________________________login detail is here *******",localStorage.getItem("authLogger"))
            setSubmit("Loading...Please wait")
            navigate("/exchange")
        }
    },[isLogin,message,loginDetail])

//custom validation rule define in validation field
    function ValidationAll() {
        let Validations={};
        let isValid=true;
        if(!values.email) {
            Validations.email="Please enter the email"
            isValid=false
        }
        if(!values.password){
            Validations.password="Please enter the password"
            isValid=false
        }
        if (values.email && !values.email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
            Validations.email = "Please enter the valid email address"
            isValid = false
        }
        if (values.password && !values.password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)) {
            Validations.password = "Please enter the valid password address eight character,uppercase,lowercase,spec char"
            isValid = false
        }
        if(!isChecked){
            Validations.checkBoxError="Please accept remember me"
            isValid=false
        }
        if(!isValid){
            setValidation(Validations)
        }
        return isValid;
    }

    const validateOne = (e) => {
        const { name } = e.target
        const value = values[name];
        console.log("______________________________________________",value, name )
        let message = ''
        if (!value && name === 'email') {
            message = `Please enter the email`
        }
        if (!value && name === 'password') {
            message = `Please enter the password`
        }
        if(!isChecked){
            Validations.checkBoxError="Please accept remember me"
        }
        setValidation({ ...Validations, [name]: message })
    }

    const handleChange = (e) => {
        setIsChecked(e.target.checked);
        const { name, value } = e.target;
        setValues({ ...values, [name]: value })
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
      
        const isValid=ValidationAll();
        if(!isValid){return false;}

        let payload ={
            email:values.email,
            password:values.password,
            type:"AuthLogin"
        }
     
        await dispatch(createLogin(payload))
    }
    //end of validation rule for login here
    return (
        <>
        <LoadingOverlay active={isActive} spinner text='Loading...'>
        <ToasterContainer/>

        <div class="authincation section-padding- pt-5 pb-5">
            <div class="container h-100">
                <div class="row justify-content-center h-100 align-items-center">
                    <div class="col-xl-5 col-md-6">
                            <div class="mini-logo text-center mb-4">
                            </div>
                        <div class="auth-form card">
                            <div class="card-header justify-content-center">
                                <h4 class="card-title">Login in</h4>
                            </div>
                            <div class="card-body">
                                <form onSubmit={(event) => handleSubmit(event)}>
                                    <div class="form-group">
                                        <label>Email</label>
                                        <input type="text" class="form-control" placeholder="hello@example.com" onBlur={validateOne} name="email" onChange={handleChange} />
                                        <small style={{color:'red'}}>{Validations?.email}</small>
                                    </div>
                                    <div class="form-group password-group">
                                        <label>Password</label>
                        <input type={!isVisible ? "password" : "text"} class="form-control" placeholder="Password" name="password" onBlur={validateOne}  onChange={handleChange} />
                        {
                            !isVisible ? (<i class="mdi mdi-eye" onClick={toggle}></i>) : ( <i class="mdi mdi-eye-off" onClick={toggle}></i>)
                        }
                                            <small style={{color:'red'}}>{Validations?.password}</small>
                                    </div>
                                    <div class="form-row d-flex justify-content-between mt-4 mb-2">
                                        <div class="form-group mb-0">
                                            <label class="toggle">
                                                <input class="toggle-checkbox" onBlur={validateOne} onChange={handleChange} checked={isChecked} type="checkbox" />
                                               
                                                <span class="toggle-switch"></span>
                                                <span class="toggle-label">Remember me</span>
                                            </label>
                                            <small style={{color:'red'}}>{!isChecked &&  Validations?.checkBoxError}</small>
                                        </div>
                                        <div class="form-group mb-0">
                                            <Link to="/forgotPassword" style={{fontSize:'12px'}}>Forgot Password?</Link>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <button type="submit" class="btn btn-success btn-block">Sign in</button>
                                    </div>
                                </form>
                                <div class="new-account mt-3">
                                    <p>Don't have an account? <Link class="text-primary" to="/signup">Sign up</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            </LoadingOverlay>
        </>
    );
}

export default Signin;
