import React, { useEffect, useState } from "react";
import btcIcon from "../../../images/currency/btc.png"

import eth from "../../../images/currency/eth.png"
import doge from "../../../images/currency/doge.png"
import bnb from "../../../images/currency/bnb.png"
import xrp from "../../../images/currency/xrp.png"
import ltc from "../../../images/currency/ltc.png"
import arb from "../../../images/currency/arb.png"
import ftt from "../../../images/currency/ftt.png"
import gt from "../../../images/currency/gt.png"
import eos from "../../../images/currency/eos.png"
import shib from "../../../images/currency/shib.png"
import { useDispatch, useSelector } from "react-redux";
import { getDetailForOrderBook } from "../../../store/order/orderMatchingSlice";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { capitalizeFirstLowercaseRest } from "../helper";
import moment from "moment";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
import { cancelOrderExchange } from "../../../store/order/orderMatchingSlice";
import { token } from "../../../util/constant";
import OrderHistory from "./orderHistory";

function OrderBook({pairDynamicId}) {
    const [orderId, setOrderId] = useState("");
    const [userData, setUserData] = useState("");
    const [type, setType] = useState("active");
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const handleShow = (orderId) => {
        setShow(true);
        setOrderId(orderId)
    }

    const customHandleClose = () => setShow(false);

    //get record from api as an selector
    const { getOrderBookDetail, message } = useSelector((state) => state.order);

    console.log("_________________________________________getOrderBookDetail",getOrderBookDetail)

    useEffect(() => {
        if (getOrderBookDetail?.data) {
            setUserData(getOrderBookDetail?.data)
        }
    }, [getOrderBookDetail]);

    useEffect(() => {
        if(pairDynamicId){
            if (type === "active") {
                //open position as an active order book get api call here
                let payload = {
                    type:"active",
                    pair_id:pairDynamicId
                }
                dispatch(getDetailForOrderBook(payload))
            } else if (type === "completed") {
                //completed data call here
                dispatch(getDetailForOrderBook({ 
                    type: "completed",
                    pair_id:pairDynamicId
                 }))
            } else if (type === "cancel") {
                //cancel order api call here
                dispatch(getDetailForOrderBook({ 
                    type: "cancel",
                    pair_id:pairDynamicId
                 }))
            }    
        }
    },[pairDynamicId,type])
    //api call here for getting order book detail
    useEffect(() => {
        if (type === "active") {
            //open position as an active order book get api call here
            let payload = {
                type:"active"
            }
            dispatch(getDetailForOrderBook(payload))
        } else if (type === "completed") {
            //completed data call here
            dispatch(getDetailForOrderBook({ type: "completed" }))
        } else if (type === "cancel") {
            //cancel order api call here
            dispatch(getDetailForOrderBook({ type: "cancel" }))
        }
    }, [type])
    
    const handleCloseSubmit = async () => {
        let cancelPayload = {
            order_id: orderId,
            token: token
        }
        await dispatch(cancelOrderExchange(cancelPayload))
        await dispatch(getDetailForOrderBook({ type: "active" }))
        await dispatch(getDetailForOrderBook({ type: "cancel" }))
        setShow(false)
    }
    
    const columns = [
        {
            dataField: "volume",
            text: "Volume",
            sort: true,
            formatter: (cell) => {
                return (<>{cell ? cell : 'N/A'}</>)
            }
            // filter: textFilter({
            //     placeholder: 'Enter the bank name',  // custom the input placeholder
            //     className: 'my-custom-text-filter', // custom classname on input
            //     //defaultValue: 'test', // default filtering value
            //     //comparator: Comparator.EQ, // default is Comparator.LIKE
            //     caseSensitive: false, // default is false, and true will only work when comparator is LIKE
            //     //style: { ... }, // your custom styles on input
            //     //delay: 1000, // how long will trigger filtering after user typing, default is 500 ms
            //     id: 'id', // assign a unique value for htmlFor attribute, it's useful when you have same dataField across multiple table in one page
            // }),
        },
        {
            dataField: "price",
            text: "Price",

            className: 'd-flex align-items-center',
            formatter: (cell) => {
                return cell ? cell : 'N/A'
            }
        },
        {
            dataField: "total_value",
            text: "Total Value",
            formatter: (cell) => { return <>{cell ? cell : 'N/A'}</> }
        },
        {
            dataField: "fee",
            text: "FEE",
            formatter: (cell) => { return <>{cell ? cell : 'N/A'}</> }
        },
        {
            dataField: "order_type",
            text: "Order Type",
            formatter: (cell) => { return <>{cell ? capitalizeFirstLowercaseRest(cell) : 'N/A'}</> }
        },
        {
            dataField: "type",
            text: "Order Side",
            formatter: (cell) => { return <>{cell ? capitalizeFirstLowercaseRest(cell) : 'N/A'}</> }
        },
        {
            dataField: "placed_on",
            text: "Placed On",
            formatter: (cell) => { return <>{cell ? moment(cell).format("YYYY/MM/DD h:mm:ss") : 'N/A'}</> }
        },
        {
            dataField: "modified_on",
            text: "Modified On",
            formatter: (cell) => { return <>{cell ? moment(cell).format("YYYY/MM/DD h:mm:ss") : 'N/A'}</> }
        },
        type!== "completed" && {
            dataField: "cancel",
            text: "Cancel",
            //hidden: type=="completed" ? true:false,
            formatter: (cell, row) => {
                return <>{<i class={type=="completed" ? "la la-close btnDelete" : "la la-close btnDelete"} onClick={() => handleShow(row?.id)}></i>}</>
            }
        },
    ];

    return (
        <>
            <div className="col-xl-6 col-lg-6 col-xxl-6">
                <div className="card">
                    <div className="card-header">
                        <ul className="nav nav-pills" role="tablist">
                            {/* <li className="nav-item">
                    <a className="nav-link active" data-toggle="pill" href="#open-orders" role="tab"
                        aria-selected="true">Open Position</a>
                </li> */}
                            <li className="nav-item">
                                <a className="nav-link active" onClick={() => setType("active")} data-toggle="pill" href="#active-orders" role="tab"
                                    aria-selected="false">Active
                                    Orders</a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" data-toggle="pill" onClick={() => setType("completed")} href="#trade-fills" role="tab"
                                    aria-selected="false">Completed Orders</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-toggle="pill" href="#trade-liquidition" onClick={() => setType("cancel")} role="tab"
                                    aria-selected="false">Cancel Orders</a>
                            </li>
                        </ul>
                    </div>
                    <div className="card-body open-position-table h-auto">
                        <div className="market-history market-order">

                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="open-orders" role="tabpanel">

                                    <div className="table-responsive">
                                        {
                                            <BootstrapTable keyField='id' filter={filterFactory()} classes="custom-ticket-code dataTable" noDataIndication={() => { return 'No Data Found' }} 
                                            columns={columns} 
                                            data={userData && userData} bordered={false} 
                                            pagination={
                                                 paginationFactory({
                                                    paginationSize: 4,
                                                    pageStartIndex: 1,
                                                 //hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
                                                page: 1,
                                                sizePerPage: 10,
                                                firstPageText: 'previous',
                                                nextPageText: 'Next',
                                                // prePageText: '<',
                                                showTotal:  true,
                                                alwaysShowAllBtns: true,
                                                sizePerPageList: [{
                                                    text: '5', value: 5
                                                  }, {
                                                    text: '10', value: 10
                                                  }, {
                                                    text: 'All', value: userData?.length
                                                  }],
                                                onPageChange: function (page, sizePerPage) {
                                                    console.log("page is here", page)
                                                },
                                                onSizePerPageChange: function (page, sizePerPage) {
                                                    console.log("page", page)
                                                }
                                            })
                                            
                                            } />
                                        }

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <OrderHistory />

            {/* modal open while cancel the order is here */}

            <div className="proofdet">
                <Modal show={show} onHide={handleClose}>
                    <Link onClick={customHandleClose} className="close" >&times;</Link>
                    <h4 className="text-center cancel-custom-order mt-4">Are you sure you want to cancel this order ?</h4>
                    <Modal.Body>
                        <Button variant="primary" onClick={handleCloseSubmit}>
                            Cancel Order
                        </Button>
                    </Modal.Body>
                </Modal>
            </div>
            {/* end of modal is here while cancel the order */}
        </>
    )
}
export default OrderBook;