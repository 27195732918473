import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserBalanceAlongWithCurrenciesDetail } from "../../../store/order/orderMatchingSlice";
import { Link } from "react-router-dom";

function UserBalanceCurrency(){

    const [userBalance,setUserBalance]=useState("");
    const dispatch = useDispatch();

    const {userBalanceDetail, message}=useSelector((state) => state.order);

    console.log("__________________________________message is here ========>>>>>",message)
    useEffect(() => {
        if(userBalanceDetail && userBalanceDetail.message==="User balance fetched by auth" && message==="User balance fetched successfully") {
            setUserBalance(userBalanceDetail?.data)
        }
    },[userBalanceDetail,message]);

    //api call here for getting the user balance
    useEffect(() => {
        dispatch(getUserBalanceAlongWithCurrenciesDetail())
    },[]);

    return (
        <>
            <div class="col-xl-3 col-lg-3 col-xxl-3">
    <div class="card">
        <div class="card-header place-card-header">
            <h4>User Balance Assets</h4>
        </div>
        <div class="card-body">	
            <table class="assetstable w-100">
                <tbody>
                    {
                        userBalance?.length > 0 ? userBalance.map((val) => (
                                <>
                            <tr>
                        <td class="d-flex align-items-center">
                            <div class="currimg"><img src={val?.Currency?.currency_icon} alt={val?.Currency?.symbol} />{val?.Currency?.symbol}</div>
                        </td>
                        <td class="text-right text-white">
                            {val?.amount}
                            <span class="estimated_fiat">= 0.00000 USDT</span>
                        </td>
                        </tr>
                        </>
                        )) : <p className="text-center">Data not found</p>
                    }
                </tbody>
                <tfoot>
                    <tr>
                        <td>Total</td>
                        <td class="text-right">
                            <span class="total_estimated_fiat">Pending 296,670,856.71 USDT</span>
                        </td>
                    </tr>
                </tfoot>
            </table>
            
            <div class="btns-list d-flex">
                <Link class="greybtn" to="/accountDepositCrypto">Deposit</Link>
                <Link class="greybtn" to="/account-withdraw">Withdraw</Link>
                <Link class="greybtn" to="#">Transfer</Link>
            </div>
            
        </div>
    </div>
</div>
        </>
    )
}
export default UserBalanceCurrency;