const BASE_URL_URL = "https://api.clientproject.net/api/v1/crypto/";
///const BASE_URL_URL="http://192.168.0.203:4000/api/v1/crypto/"
const REACT_APP_S3_CLOUD_IMAGE_URL=process.env.REACT_APP_S3_CLOUD_IMAGE_URL
const token="FQjABCwkGrea8r53bRIdEBcpJ9oJSJfQ"

const register="register";
const login="login";
const forgotPassword="forgotPassword"
const resetPassword="resetPassword"
const changePassword="changePassword"
const editProfile="editProfile"
const getProfile="getProfile"
const getAllSupportDepartment="getAllSupportDepartment"
const getAllSupport="getAllSupport"
const createTicket="createTicket"
const viewTicket="viewTicket"
const createThread="createThread"
const getAllThread="getAllThread"
const getAdminThread="getAdminThread"
const verifyOtp="verifyOtp"
const multiAuth="multiAuth"
const getMultiAuth="getMultiAuth"
const getKyc="getKyc"
const createKyc="createKyc"
const createBankDetail="createBankDetail"
const getBankDetail="getBankDetail"
const getFiatCurrencies="getFiatCurrencies"
const getCryptoCurrencies="getCryptoCurrencies"
const gettingDeposit="gettingDeposit"
const autoPopulateKyc="autoPopulateKyc"
const editKyc="editKyc"
const createDeposit="createDeposit"
const gettingBankDetailByAdmin="gettingBankDetailByAdmin"
const saveCryptoDepositAddress="saveCryptoDepositAddress"
const gettingTransactionBasedOnCurrency="gettingTransactionBasedOnCurrency"
const placeOrder="orderPlace"
const cancelOrder="cancelOrder"
const getOrderBook="getOrderBook"
const getOrderHistory="getOrderHistory"
const getAllCurrenciesPair="getAllCurrenciesPair"
const getAllCurrenciesTabs="getAllCurrenciesTabs"
const getTopTenActiveBuyOrders="getTopTenActiveBuyOrders"
const getTopTenActiveSellOrders="getTopTenActiveSellOrders"
const getInnerHeaderDetail="getInnerHeaderDetail"
const getDefaultTermId="getDefaultTermId"
const getUserBalanceByAuthId="getUserBalanceByAuthId"

export {
    register,
    BASE_URL_URL,
    login,
    forgotPassword,
    resetPassword,
    changePassword,
    editProfile,
    getProfile,
    getAllSupportDepartment,
    getAllSupport,
    createTicket,
    REACT_APP_S3_CLOUD_IMAGE_URL,
    viewTicket,
    createThread,
    getAllThread,
    getAdminThread,
    verifyOtp,
    multiAuth,
    getMultiAuth,
    getKyc,
    createKyc,
    createBankDetail,
    getBankDetail,
    getFiatCurrencies,
    createDeposit,
    gettingDeposit,
    autoPopulateKyc,
    editKyc,
    gettingBankDetailByAdmin,
    saveCryptoDepositAddress,
    getCryptoCurrencies,
    gettingTransactionBasedOnCurrency,
    placeOrder,
    cancelOrder,
    token,
    getOrderBook,
    getOrderHistory,
    getAllCurrenciesPair,
    getAllCurrenciesTabs,
    getTopTenActiveBuyOrders,
    getTopTenActiveSellOrders,
    getInnerHeaderDetail,
    getDefaultTermId,
    getUserBalanceByAuthId
}