import jwt from "jwt-decode";
import React,{useState,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../store/BasicInfo/basicInfoSlice";


export const capitalizeFirstLowercaseRest = str => {
    return (
      str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase()
    );
  };
export const getLastFileNameFromUrl = str => {
  return (
    str?.substring(str.lastIndexOf('/')+1)
  )
}

export const randomNumber = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
export const getAuthDetail =  () => {
  try {
    if(localStorage.getItem("authLogger")){
      const token=localStorage.getItem("authLogger");
      const decode=jwt(token);
      return decode;
    } 
  } catch (error) {
   
    throw error;
  }
}
export const GetAuthLoggerDetail  = ()  => {
  const dispatch=useDispatch();
  const [authDetail,setAuthDetail]=useState("");

  const {UserProfileGetting}=useSelector((state) => state.basic);

  useEffect(() => {
    if(UserProfileGetting) {
        //update state as per auth detail
        setAuthDetail(UserProfileGetting?.data)
    }
  },[UserProfileGetting])

  useEffect(() => {
    dispatch(getUserProfile())
},[])
  return authDetail;
}
export const getFilterData = (array,key) => {
  const lowercasedFilter = key?.toLowerCase();
  const filteredData = array && array.length > 0 && array?.filter(item => {
      console.log("_________________item is here *********", item)
      return Object.keys(item).some(key =>
          item[key]?.symbol?.toLowerCase().includes(lowercasedFilter)
      );
  });
  return filteredData;
}