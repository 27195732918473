import React from "react";
import Modal from "react-modal";

//custom modal open when the internet connection is off case
const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      zIndex:'999999999999999',

    },
  };
  Modal.setAppElement('#root');

function OffLineModal({display}){
    return (
        <Modal isOpen={display} style={customStyles} contentLabel="Network loss duo to internet connection">
            <div>You have to appear loss the interner connection</div>
        </Modal>
    )
}
export default OffLineModal;