import React, { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-rangeslider/lib/index.css";
import { Link } from "react-router-dom";
import TradingViewWidget, { Themes } from "react-tradingview-widget";
import DepthChart from "../charts/depth";
import RangeSlider from "../element/range-slider";
import Footer2 from "../layout/footer2";
import Header2 from "../layout/header2";
import Sidebar from "../layout/sidebar";
import useDocumentTitle from "../../helper/useTitle"
import btcIcon from "../../images/currency/btc.png"

import eth from "../../images/currency/eth.png"
import doge from "../../images/currency/doge.png"
import bnb from "../../images/currency/bnb.png"
import xrp from "../../images/currency/xrp.png"
import ltc from "../../images/currency/ltc.png"
import arb from "../../images/currency/arb.png"
import ftt from "../../images/currency/ftt.png"
import gt from "../../images/currency/gt.png"
import eos from "../../images/currency/eos.png"
import shib from "../../images/currency/shib.png"

import Footer1 from "../layout/footer1";

import {Helmet} from "react-helmet";
import OrderPlace from "../component/placeOrder/orderPlace";
import ToasterContainer from "../component/ToasterContainer";
import OrderBook from "../component/placeOrder/orderbook";

import { useDispatch } from "react-redux";
import OrderHistory from "../component/placeOrder/orderHistory";
import PairCurrency from "../component/placeOrder/pairCurrency";
import RunningOrder from "../component/placeOrder/runningOrder";
import ErrorBoundary from "../component/ErrorBoundary";
import TopInnerHeader from "../component/header/innerHeader";
import UserBalanceCurrency from "../component/userBalance/userBalanceCurrency";

function Dashboard() {
    useDocumentTitle('Dashboard | Exchange');
    const dispatch =useDispatch();
    const [pairId,setPairIdDynamic]=useState("");
    const [dynamicHeader,setDynamicHeader]=useState("");

        console.log("______________________________________*****************dynamicHeaderdynamicHeader",dynamicHeader)
    return (
        <>
            {/* <Sidebar /> */}
          
            <Header2/>
            <ToasterContainer/>
            <div className="content-body" id="dashboard">
                <div className="container-fluid">
                    <div className="row">
                   <PairCurrency setPairIdDynamic={setPairIdDynamic} setDynamicHeader={setDynamicHeader}/>
                <div class="col-xl-9 col-lg-9 col-md-12 col-xxl-9">
						<div class="row">
							<TopInnerHeader btcIcon={btcIcon} dynamicHeader={dynamicHeader} />
							<div class="col-xl-6 col-lg-6 col-md-12 col-xxl-6">
								<div class="tradingview-widget-container card">
                                    <TradingViewWidget
                                    symbol="NASDAQ:AAPL"
                                    theme={Themes.DARK}
                                    locale="fr"
                                    autosize
                                />
									<div id="tradingview_e8053"></div>

								</div>
							
							</div>
							
						{/* Running order start here top ten along with buy and sell along with desc and asc */}
						<RunningOrder pairDynamicId={pairId}/>
						{/* end of running order top 10 based on buy and sell */}
					<OrderPlace pairDynamicId={pairId}/>
                    
						</div>
					</div>
              
                </div>
                {/* bottom inner footer call here */}
                <div class="row largetablerow">

{
    !localStorage.getItem("authLogger") ? 
    <>
    
    <div class="col-xl-3 col-lg-3 col-xxl-3">
    <div class="card">
        <div class="card-header">
        <div class="table_login"><span><Link to="/signin">Log In </Link> &nbsp; or &nbsp; <Link to="/signup">Create Account</Link> &nbsp; Go to trade</span></div>
    </div>
    </div>
    </div> 
    <OrderHistory pairDynamicId={pairId} />
    </>
    : 
    <OrderBook pairDynamicId={pairId}/>
}

            <UserBalanceCurrency  />
                </div>
                {/* end of inner footer call here */}
                    
                </div>
            </div>

            {/* footer call here */}
           <Footer1/>
            {/* end of footer call here */}
        </>
    );
}

export default Dashboard;
