import React,{useState,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrderHistoryByPairId } from "../../../store/order/orderMatchingSlice";
import moment from "moment";

function OrderHistory() {
    const [orderHistory,setOrderHistory]=useState("");
    const dispatch=useDispatch();

    const {orderHistoryDetail, isOrderHistoryStatus}=useSelector((state) => state.order)
    //getting record from here
    useEffect(() => {
        if(isOrderHistoryStatus && orderHistoryDetail?.data){
            setOrderHistory(orderHistoryDetail?.data)
        }
    },[isOrderHistoryStatus, orderHistoryDetail])

    console.log("______________________________________________________orderHistoy detail is here",orderHistory.price)
    //consume get order history api here...
    useEffect(() => {
        dispatch(getOrderHistoryByPairId())
    },[])

    return (
        <>
            <div class="col-xl-3 col-lg-3 col-xxl-3">
                <div class="card">
                    <div class="card-header place-card-header">
                        <h4>Order History</h4>
                    </div>
                    <div class="card-body">
                        <table className="table">
                            <tr><th>Trade Price</th><th>Volume</th><th>Time</th></tr>
                             {
                                orderHistory.length > 0 ? orderHistory.map((val) => (
                                    <>
                                    <tr>
                                    <td>{val?.price}</td>
                                    <td>{val?.volume}</td>
                                    <td>{moment(val?.modified_on).format("YYYY/MM/DD hh:mm:ss")}</td>
                                    </tr>
                                    </>
                                )) : <p className="text-center">Data not found</p>
                            }
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
export default OrderHistory; 