import React,{useState,useEffect} from "react"
import { getAuthDetail } from "../helper";
import { token } from "../../../util/constant";
import { useDispatch, useSelector } from "react-redux";
import { orderPlaceForExchange } from "../../../store/order/orderMatchingSlice";
import { useNavigate } from "react-router-dom";
import { getDetailForOrderBook } from "../../../store/order/orderMatchingSlice";
import { Link } from 'react-router-dom';

export default function OrderPlace({pairDynamicId}) {
	const [price,setPrice]=useState("");
	const dispatch=useDispatch();
	const navigate=useNavigate();

	const {isOrderBookStatus, getOrderBookDetail} =useSelector((state) => state.order);
	useEffect(() => {
		if(isOrderBookStatus && getOrderBookDetail && getOrderBookDetail.message === "maximum and minimum price for pair id") {
			//state update for max and min price while placing the order
			setPrice(getOrderBookDetail?.data)
		}
	},[isOrderBookStatus,getOrderBookDetail]);

	//api call here for getting max and min price
	useEffect(() => {
        dispatch(getDetailForOrderBook({type:"market_watch_price",pair_id:"1"}))
    },[]);

	//get auth detail is here
	const auth=getAuthDetail();
	//getting type dynamic for know which order type...
	const [type,setType]=useState("market");
	//getting side which order will make buy or sell...
	const [side,setSide]=useState("buy");

	//this is for buyer and sell side params set
	const [values,setValues]=useState({price:"",qty:"",lprice:"",lqty:"",qamount:"",qvol:"",
	sprice:"",
	sqty:"",
	slprice:"",
	slqty:"",
	sqamount:"",
	sqvol:""
	});

	const [Validations, setValidation]=useState({price:"",qty:"",lprice:"",lqty:"",ltotal:"",qamount:"",qvol:""
	});
	const[Validations1,setValidation1]=useState({
		sprice:"",
		sqty:"",
		slprice:"",
		slqty:"",
		sqamount:"",
		sqvol:""
	});
	//end of buyer side is here


	const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value })
    }
	const buyTotalAmount=parseFloat(values.price) * parseFloat(values.qty) 
	
	const limitTotalAmount=parseFloat(values.lprice) * parseFloat(values.lqty);

	const sellBuyTotalAmount=parseFloat(values.sprice) * parseFloat(values.sqty);

	console.log("________________________________________sellBuyTotalAmount",values.sprice);

	const sellLimitTotalAmount=parseFloat(values.slprice) * parseFloat(values.slqty);

	//extract record from redux for order place state would be called here...
	const {isOrderPlaceStatus, orderPlaceDetail,message}=useSelector((state) => state.order);
	console.log("slector record is here ***************************",limitTotalAmount);

	//----------------------------------------------------checks maintain for getting selector record temp comment code --------------------------
	// useEffect(() => {
	// if(isOrderPlaceStatus===true && message==="order has been placed successfully"){
	// 	console.log("inside call here **********************")
	// 	navigate("/exchange")
	// }
	// },[isOrderPlaceStatus,message])
 
	//------------------------------------------------------------this is called for buy order-----------------------------------------------------------

	//this is buyer validation is write here
	//market validatino start here
	function marketValidation() {
        let Validations = {};
        let isValid = true;

        if (!values?.price && price?.buy_price=="") {
            Validations.price = "Please enter the price"
            isValid = false
        }

		
        if (!values?.qty) {
            Validations.qty = "Please enter qty"
            isValid = false
        }

		if (!isValid) {
            setValidation(Validations)
        }
        return isValid;
	}
	//limit validatin start here...
	function limitValidation() {
        let Validations = {};
        let isValid = true;

        if (!values.lprice) {
            Validations.lprice = "Please enter the price"
            isValid = false
        }

        if (!values.lqty) {
            Validations.lqty = "Please enter qty"
            isValid = false
        }

		if (values.lqty && !values.lqty.match(/^[0-9]*$/)) {
            Validations.lqty = "Please enter the valid quantity";
            isValid = false
        }

		if (!isValid) {
            setValidation(Validations)
        }
        return isValid;
	}
	//quick validatino is here...
	function quickValidation() {
        let Validations = {};
        let isValid = true;

        if (!values.qamount) {
            Validations.qamount = "Please enter the price"
            isValid = false
        }

        if (!values.qvol) {
            Validations.qvol = "Please enter qty"
            isValid = false
        }

		if (values.qvol && !values.qvol.match(/^[0-9]*$/)) {
            Validations.lqty = "Please enter the valid quantity";
            isValid = false
        }

		if (!isValid) {
            setValidation(Validations)
        }
        return isValid;
	}
	//end of buyer validation is here...

	//seller validation start here..
	function marketValidation1() {
        let Validations1 = {};
        let isValid = true;

        if (!values.sprice && price?.sell_price=="") {
            Validations1.sprice = "Please enter the market price"
            isValid = false
        }

        if (!values.sqty) {
            Validations1.sqty = "Please enter qty"
            isValid = false
        }

		if (!isValid) {
            setValidation1(Validations1)
        }
        return isValid;
	}
	//limit validatin start here...
	function limitValidation1() {
        let Validations1 = {};
        let isValid = true;

        if (!values.slprice) {
            Validations1.slprice = "Please enter the seller price"
            isValid = false
        }

        if (!values.slqty) {
            Validations1.slqty = "Please enter the  seller qty"
            isValid = false
        }

		if (!isValid) {
            setValidation1(Validations1)
        }
        return isValid;
	}
	//quick validatino is here...
	function quickValidation1() {
        let Validations1 = {};
        let isValid = true;

        if (!values.sqamount) {
            Validations1.sqamount = "Please enter the quick price"
            isValid = false
        }

		if (values.sqamount && !values.sqamount.match(/^[0-9]*$/)) {
            Validations1.qamount = "Please enter the valid price";
            isValid = false
        }
        if (!values.sqvol) {
            Validations1.sqvol = "Please enter the quick sell qty"
            isValid = false
        }

		if (values.sqvol && !values.sqvol.match(/^[0-9]*$/)) {
            Validations1.lqty = "Please enter the valid quantity";
            isValid = false
        }

		if (!isValid) {
            setValidation1(Validations1)
        }
        return isValid;
	}
	//end of seller validation is here...


	
	const validateOne = (e) => {
        const { name } = e.target
        const value = values[name];
        console.log("______________________________________________", value, name)
        let message = ''
        if (!value && name === 'price') {
            message = `Please enter the price`
        }
		setValidation({ ...Validations, [name]: message })
	}

	const handleBuySubmit = (e) => {
		e.preventDefault();
		if(side==="buy") {
			if(type==="market") {
				const isValid = marketValidation();
				if (!isValid) { return false; };

				let marketPayload  = {
					pair_id:pairDynamicId || 1,
					side:side,
					order_type:type,
					price:values.price ? values?.price : price?.buy_price,
					volume:values.qty,
					token:token
				}
				console.log("___________________________________________________market payload is here",marketPayload)
				
				dispatch(orderPlaceForExchange(marketPayload))
				setValues({qty:""})
				//order place based on market here...
			} else if(type==="limit") {
				// order place based on limit here...
				const isValid = limitValidation();
				if (!isValid) { return false; };
				let limitOrder = {
					pair_id:pairDynamicId || 1,
					side:side,
					order_type:type,
					price:values.lprice,
					volume:values.lqty,
					token:token
				}
				console.log("___________________________________________limitorder is here ",limitOrder)
				dispatch(orderPlaceForExchange(limitOrder))
				setValues({lqty:""})
			} else if(type==="quickbuy"){
				//quick buy order here
				const isValid = quickValidation();
				if (!isValid) { return false; };
				let quickOrder = {
					pair_id:1, //this is statically passed on now
					side:side,
					order_type:type,
					price:values.qamount,
					volume:values.qvol,
					token:token
				}
				console.log("quickOrder is here ******************************",quickOrder)
				dispatch(orderPlaceForExchange(quickOrder))
				setValues({qvol:""})
			} else {
				window.confirm("type is not acceptable")
			}
		} else {
			window.confirm("side is not acceptable")
		}	
	}
	//-----------------------------------------------------------------end of order place based on buy ------------------------------------------------------------


//---------------------------------------------------------------------sell order place start here -----------------------------------------------------------------
		
//populate value in input field
useEffect(() => {
setValues({price:price.buy_price,sprice:price.sell_price})
},[price])

const handleSellSubmit = (e) => {
	e.preventDefault();
	if(side==="sell") {
		if(type==="market") {
			const isValid = marketValidation1();
			if (!isValid) { return false; };				
			let marketPayload  = {
				pair_id:1,
				side:side,
				order_type:type,
				price:values?.sprice ? values?.sprice : price?.sell_price,
				volume:values.sqty,
				token:token
			}
			
			dispatch(orderPlaceForExchange(marketPayload))
			setValues({sqty:""})
			//order place based on market here...
		} else if(type==="limit") {
			// order place based on limit here...
			const isValid = limitValidation1();
			if (!isValid) { return false; };
			let limitOrder = {
				pair_id:1,
				side:side,
				order_type:type,
				price:values.slprice,
				volume:values.slqty,
				token:token
			}
			console.log("___________________________________________limitorder is here ",limitOrder)
			dispatch(orderPlaceForExchange(limitOrder))
			setValues({slqty:""})
		} else if(type==="quickbuy"){
			//quick buy order here
			const isValid = quickValidation1();
			if (!isValid) { return false; };
			let quickOrder = {
				pair_id:1, //this is statically passed for now
				side:side,
				order_type:type,
				price:values.sqamount,
				volume:values.sqvol,
				token:token
			}

			dispatch(orderPlaceForExchange(quickOrder));
			setValues({sqvol:""})
		} else {
			window.confirm("type is not acceptable")
		}
	} else {
		window.confirm("side is not acceptable")
	}
}
//---------------------------------------------------------------------end of sell order place here-----------------------------------------------------------------








	return (
		<>
<div class="col-xl-3 col-lg-3 col-md-12 col-xxl-3">
                        <div class="card market-limit">
                            <div class="card-header place-card-header">
								<h4>Place Order</h4> 
							</div>
                            <div class="card-header pt-0 pb-0 border-0">
								<ul class="nav nav-pills buysellpills" role="tablist">									
									<li class="nav-item">
										<a class="nav-link active buypill" onClick={() => setSide("buy")} data-toggle="pill" href="#buy" role="tab"
											aria-selected="false">Buy</a>
									</li>
									<li class="nav-item">
										<a class="nav-link sellpill" data-toggle="pill" href="#sell" onClick={() => setSide("sell")} role="tab"
											aria-selected="true">Sell</a>
									</li>
								</ul>
							</div>
                            <div class="card-body ">
								<div class="tab-content mt-2">
									<div class="tab-pane fade show active" id="buy" role="tabpanel">
										<div class="card-header p-0 pb-3 border-0">
											<ul class="nav nav-pills" role="tablist">									
												<li class="nav-item">
													<a class="nav-link active" onClick={() => setType("market")} data-toggle="pill" href="#market" role="tab"
														aria-selected="false">Market</a>
														
												</li>
												<li class="nav-item">
													<a class="nav-link" onClick={() => setType("limit")} data-toggle="pill" href="#limit" role="tab"
														aria-selected="true">Limit</a>
												</li>
												<li class="nav-item">
													<a class="nav-link" data-toggle="pill" onClick={() => setType("quickbuy")} href="#quickbuy" role="tab"
														aria-selected="false">Quick Buy</a>
												</li>
											</ul>
										</div>

										<div class="tab-content">
									<div class="tab-pane fade show active" id="market" role="tabpanel">
										<div class="display_pair_price">
											<span>Avl Bal. </span>
											<div class="d-flex">
												<span class="display_price" id="current_btc_balance">{price?.buy_price ? price?.buy_price : '0'}</span>
												<span>BTC </span>
											</div>
										</div>
										{/* this is fire for buy form */}
                                        <form onSubmit={(e) => handleBuySubmit(e)} class="currency_limit">
                                            <div class="form-group-custom d-flex">
												<h6>Price</h6>
												<input onChange={handleChange}  disabled={!localStorage.getItem("authLogger") ? "disabled" : ""} maxLength={50} class="form-control"  type="text" data-ordertype="market" value={
													values?.price ? values?.price :  ''
												} name="price" />
												<span>USDT</span>
											</div>
											<small style={{ color: 'red' }}>{Validations?.price}</small>
											
											<div class="form-group-custom d-flex">
												<h6>Quantity</h6>
												<input autocomplete="off" disabled={!localStorage.getItem("authLogger") ? "disabled" : ""} maxLength={10} class="form-control qty-vol" onChange={handleChange} value={values?.qty ? values?.qty  : ''} name="qty" id="amount_buy" data-ordertype="market" data-side="buy" type="text" />
												<span>BTC</span>
											</div>
											<small style={{ color: 'red' }}>{Validations?.qty}</small>
											
											<div class="form-group-custom d-flex">
												<h6>Total</h6>
												<input autocomplete="off" class="form-control qty-vol" id="total" onChange={handleChange} readOnly value={side==="buy" && buyTotalAmount ? buyTotalAmount : parseFloat(price.buy_price) * parseFloat(values?.qty) ? parseFloat(price.buy_price) * parseFloat(values?.qty) : '0'} data-ordertype="market" data-side="buy" type="text" />
												<span>USDT</span>
											</div>

                                            <div class="btn-group btn-block mt-4 pt-2">
                                               {localStorage.getItem("authLogger") && <button type="submit" name="submit" class="btn btn-success">{side==="buy" && "BUY"}</button> }
                                            </div>
                                        </form>
										<div>
												{
													!localStorage.getItem("authLogger") && <Link to="/signin" class="btn btn-danger w-100">Log In Or Sign Up</Link>
												}
										</div>
                                    </div>
                                    
                                    <div class="tab-pane fade" id="limit" role="tabpanel">
                                        <div class="display_pair_price">
											<span>Avl Bal. </span>
											<div class="d-flex">
												<span class="display_price" id="current_btc_balance">{price?.buy_price ? price?.buy_price : '0'}</span>
												<span>BTC </span>
											</div>
										</div>
                                        <form onSubmit={(e) => handleBuySubmit(e)} class="currency_limit">

                                            <div class="form-group-custom d-flex">
												<h6>Price</h6>
												<input autocomplete="off" class="form-control" disabled={!localStorage.getItem("authLogger") ? "disabled" : ""} onChange={handleChange} type="text" data-ordertype="market" value={values?.lprice} name="lprice" />
												<span>USDT</span>
											</div>
											<small style={{ color: 'red' }}>{Validations?.lprice}</small>
											
											<div class="form-group-custom d-flex">
												<h6>Quantity</h6>
												<input autocomplete="off" name="lqty" class="form-control qty-vol" disabled={!localStorage.getItem("authLogger") ? "disabled" : ""} value={values.lqty} id="amount_buy" onChange={handleChange} data-ordertype="market" data-side="buy" type="text" />
												<span>BTC</span>
											</div>
											<small style={{ color: 'red' }}>{Validations?.lqty}</small>
											
											<div class="form-group-custom d-flex">
												<h6>Total</h6>
												<input autocomplete="off" class="form-control qty-vol" id="total" data-ordertype="market" data-side="buy" value={limitTotalAmount ? limitTotalAmount : '0'} readOnly type="text" name="ltotal" />
												<span>USDT</span>
											</div>

											<div class="btn-group btn-block mt-4 pt-2">
                                               {localStorage.getItem("authLogger") && <button type="submit" name="submit" class="btn btn-success">{side==="buy" && "BUY"}</button> }
                                            </div>
                                        </form>
										<div>
												{
													!localStorage.getItem("authLogger") && <Link to="/signin" class="btn btn-danger w-100">Log In Or Sign Up</Link>
												}
										</div>

                                    </div>
                                    <div class="tab-pane fade" id="quickbuy" role="tabpanel">

                                        <div class="display_pair_price">
											<span>Avl Bal. </span>
											<div class="d-flex">
												<span class="display_price" id="current_btc_balance">{price?.buy_price ? price?.buy_price : '0'}</span>
												<span>BTC </span>
											</div>
										</div>
                                        <form onSubmit={(e) => handleBuySubmit(e)} class="currency_limit">

                                            <div class="form-group-custom d-flex">
												<h6>Amount</h6>
												<input autocomplete="off" class="form-control" id="price_buy" onChange={handleChange} disabled={!localStorage.getItem("authLogger") ? "disabled" : ""}  type="text" data-ordertype="market" name="qamount" value={values?.qamount} />
												<span>USDT</span>
											</div>
											<small style={{ color: 'red' }}>{Validations?.qamount}</small>
											
											<div class="form-group-custom d-flex">
												<h6>Volume</h6>
												<input disabled={!localStorage.getItem("authLogger") ? "disabled" : ""} autocomplete="off" class="form-control qty-vol" name="qvol" onChange={handleChange} data-ordertype="market" data-side="buy" type="text" />
												<span>BTC</span>
											</div>
											<small style={{ color: 'red' }}>{Validations?.qvol}</small>
											<div class="btn-group btn-block mt-4 pt-2">
                                               {localStorage.getItem("authLogger") && <button type="submit" name="submit" class="btn btn-success">{side==="buy" && "BUY"}</button> }
                                            </div>
                                        </form>
										<div>{!localStorage.getItem("authLogger") && <Link to="/signin" class="btn btn-danger w-100">Log In Or Sign Up</Link>}
										</div>

                                    </div>
                                    
                                		</div>
                           
									</div>
									<div class="tab-pane fade" id="sell" role="tabpanel">
										<div class="card-header p-0 pb-3 border-0">
											<ul class="nav nav-pills" role="tablist">									
												<li class="nav-item">
													<a class="nav-link active" onClick={() => setType("market")} data-toggle="pill" href="#marketsell" role="tab"
														aria-selected="false">Market</a>
												</li>
												<li class="nav-item">
													<a class="nav-link" onClick={() => setType("limit")} data-toggle="pill" href="#limitsell" role="tab"
														aria-selected="true">Limit</a>
												</li>
												<li class="nav-item">
													<a class="nav-link" data-toggle="pill" onClick={() => setType("quickbuy")} href="#quicksell" role="tab"
														aria-selected="false">Quick Sell</a>
												</li>
											</ul>
										</div>
										<div class="tab-content">
									<div class="tab-pane fade show active" id="marketsell" role="tabpanel">
										<div class="display_pair_price">
											<span>Avl Bal. </span>
											<div class="d-flex">
												<span class="display_price" id="current_btc_balance">{price?.sell_price ? price?.sell_price : '0' }</span>
												<span>BTC </span>
											</div>
										</div>
                                        
											<form onSubmit={(e) => handleSellSubmit(e)} class="currency_limit market-nested">

												<div class="form-group-custom d-flex">
													<h6>Price</h6>
													<input autocomplete="off" class="form-control" disabled={!localStorage.getItem("authLogger") ? "disabled" : ""} onChange={handleChange}   name="sprice" type="text" data-ordertype="market" value={
														values?.sprice ? values?.sprice :''
														} />
													<span>USDT</span>
												</div>
												<small style={{ color: 'red' }}>{Validations1?.sprice}</small>

												<div class="form-group-custom d-flex">
													<h6>Quantity</h6>
													<input autocomplete="off" name="sqty" disabled={!localStorage.getItem("authLogger") ? "disabled" : ""} onChange={handleChange} class="form-control qty-vol"  value={values.sqty} data-ordertype="market" data-side="buy" type="text" />
													<span>BTC</span>
												</div>
												<small style={{ color: 'red' }}>{Validations1?.sqty}</small>
												
												<div class="form-group-custom d-flex">
													<h6>Total</h6>
													<input autocomplete="off" class="form-control qty-vol" id="total" data-ordertype="market" data-side="buy" type="text" readOnly value={side==="sell" && sellBuyTotalAmount ? sellBuyTotalAmount : parseFloat(price.sell_price) * parseFloat(values?.sqty) ? parseFloat(price.sell_price) * parseFloat(values?.sqty) : '0'  }  />
													<span>USDT</span>
												</div>

												<div class="btn-group btn-block mt-4 pt-2">
													{
														localStorage.getItem("authLogger") && 
														<button type="submit" name="submit" class="btn btn-danger">{side==="sell" && "SELL"}</button> 
													}
												</div>
											</form>
											<div>
												{
													!localStorage.getItem("authLogger") && <Link to="/signin" class="btn btn-danger w-100">Log In Or Sign Up</Link>
												}
											</div>
                                    </div>
                                    
                                    <div class="tab-pane fade" id="limitsell" role="tabpanel">
									
										<div class="display_pair_price">
											<span>Avl Bal. </span>
											<div class="d-flex">
												<span class="display_price" id="current_btc_balance">{price?.sell_price ? price?.sell_price : '0'}</span>
												<span>BTC </span>
											</div>
										</div>
                                        <form onSubmit={(e) => handleSellSubmit(e)} class="currency_limit limit-nested">

                                            <div class="form-group-custom d-flex">
												<h6>Price</h6>
												<input autocomplete="off" disabled={!localStorage.getItem("authLogger") ? "disabled" : ""} class="form-control" id="price_buy" name="slprice" onChange={handleChange} type="text" data-ordertype="market" value={values.slprice} />
												<span>USDT</span>
											</div>
											<small style={{ color: 'red' }}>{Validations1?.slprice}</small>
											
											<div class="form-group-custom d-flex">
												<h6>Quantity</h6>
												<input autocomplete="off" disabled={!localStorage.getItem("authLogger") ? "disabled" : ""} onChange={handleChange} name="slqty" value={values.slqty} class="form-control qty-vol"  data-ordertype="market" data-side="buy" type="text" />
												<span>BTC</span>
											</div>
											<small style={{ color: 'red' }}>{Validations1?.slqty}</small>
											
											<div class="form-group-custom d-flex">
												<h6>Total</h6>
												<input autocomplete="off" class="form-control qty-vol" id="total" data-ordertype="market" type="number" readOnly value={sellLimitTotalAmount  ? sellLimitTotalAmount : '0'} data-side="buy" />
												<span>USDT</span>
											</div>

                                            <div class="btn-group btn-block mt-4 pt-2">
											{
												localStorage.getItem("authLogger") && <button type="submit" name="submit" class="btn btn-danger">{side==="sell" && "SELL"}</button>  
											}
                                            </div>
                                        </form>
										<div>
												{
													!localStorage.getItem("authLogger") && <Link to="/signin" class="btn btn-danger w-100">Log In Or Sign Up</Link>
												}
										</div>

                                    </div>
                                    <div class="tab-pane fade" id="quicksell" role="tabpanel">
										<div class="display_pair_price">
											<span>Avl Bal. </span>
											<div class="d-flex">
												<span class="display_price" id="current_btc_balance">{price.sell_price ? price.sell_price : '0'}</span>
												<span>BTC </span>
											</div>
										</div>
                                        <form onSubmit={(e) => handleSellSubmit(e)} class="currency_limit stop-limit-nested">
                                            <div class="form-group-custom d-flex">
												<h6>Amount</h6>
												<input disabled={!localStorage.getItem("authLogger") ? "disabled" : ""} autocomplete="off" class="form-control" onChange={handleChange} type="text" data-ordertype="market" value={values?.sqamount} name="sqamount" />
												<span>BTC</span>
											</div>
											<small style={{ color: 'red' }}>{Validations1?.sqamount}</small>
											
											<div class="form-group-custom d-flex">
												<h6>Volume</h6>
												<input autocomplete="off" disabled={!localStorage.getItem("authLogger") ? "disabled" : ""} class="form-control qty-vol" value={values?.sqvol}  name="sqvol" onChange={handleChange} data-ordertype="market" data-side="buy" type="text" />
												<span>USDT</span>
											</div>
											<small style={{ color: 'red' }}>{Validations1?.sqvol}</small>
											
                                            <div class="btn-group btn-block mt-4 pt-2">
											{
												localStorage.getItem("authLogger") && <button type="submit" name="submit" class="btn btn-danger">{side==="sell" && "SELL"}</button> 
											}
                                            </div>
                                        </form>
										<div>
												{
													!localStorage.getItem("authLogger") && <Link to="/signin" class="btn btn-danger w-100">Log In Or Sign Up</Link>
												}
										</div>
                                    </div>
                                </div>
									</div>
								
								</div>
								
                            </div>
                        </div>
                    </div>
		</>
	)
}