import React,{useEffect, useRef, useState} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import show from "../../util/images/show.png"
import hide from "../../util/images/hide.png"
import { useDispatch, useSelector } from 'react-redux';
import ToasterContainer from '../component/ToasterContainer';
import { changePasswordUser } from "../../store/BasicInfo/basicInfoSlice";
import { logout } from "../../store/BasicInfo/basicInfoSlice";
import LoadingOverlay from 'react-loading-overlay';
import useDocumentTitle from "../../helper/useTitle"
import Header2 from "../layout/header2";
import Sidebar from "../layout/sidebar";
import PageTitle from "../element/page-title";
import OrderPlace from "../component/placeOrder/orderPlace";

function ChangePassword() {
    useDocumentTitle('Change Password | Exchange')
    const [values,setValue]=useState({old_password:"",new_password:"",confirm_password:""});
    const [isActive,isSetActive]=useState(false);
    const [Validations,setValidation]=useState({old_password:"",new_password:"",confirm_password:""});
    const params=useParams();
    const [isVisible,setVisible]=useState(false);
    const [isVisible1,setVisible1]=useState(false);
    const [isVisible2,setVisible2]=useState(false);
    const dispatch=useDispatch();
    const [submit,setSubmit]=useState("Submit")

    const toggle = () => {setVisible(!isVisible);};
    const toggle1 = () => {setVisible1(!isVisible1);};
    const toggle2 = () => {setVisible2(!isVisible2);};
    const navigate=useNavigate();

    const {isChangedPassword,changedPasswordDetail,message}=useSelector((state) => state.basic);

    const previousProps=useRef({isChangedPassword}).current;

   
    useEffect(() => {
        if(previousProps.isChangedPassword != isChangedPassword && message=="Password has been changed successfully" && changedPasswordDetail){
            dispatch(logout())
            localStorage.removeItem("authLogger");
            setSubmit("Loading...Please wait")
            setTimeout(() => {
                navigate("/signin")
            },3000)
        }
    },[isChangedPassword,message,changedPasswordDetail])   
   
    const handleChange = (e) => {
        const {name,value}=e.target;
        setValue({...values,[name]:value});
    }

    const validateAll = () => {
		let isValid=true;
		const Validations={};

        if(!values.old_password) {
			Validations.old_password='Please enter the new Password'
			isValid=false
		}

		if(!values.new_password) {
			Validations.new_password='Please enter the new Password'
			isValid=false
		} 

        if (values.new_password && !values.new_password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)) {
            Validations.new_password = "Please enter the valid password address eight character,uppercase,lowercase,spec char"
            isValid = false
        }
       
		 if(!values.confirm_password) {
			Validations.confirm_password='Please enter the confirm password'
			isValid=false
		}

        if (values.confirm_password && !values.confirm_password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)) {
            Validations.confirm_password = "Please enter the valid password address eight character,uppercase,lowercase,spec char"
            isValid = false
        }

		if(!isValid) {
			setValidation(Validations)
		}
		return isValid;
	}

    const validateOne = (e) => {
        const {name}=e.target;
        const value=values[name];
        let message="";
        if (!value && name === 'old_password') {
            message = `Please enter the new password`
        }
        if (!value && name === 'new_password') {
            message = `Please enter the new password`
        }
        if (!value && name === 'confirm_password') {
            message = `Please enter the confirm password`
        }

        
        setValidation({ ...Validations, [name]: message })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
      
        const isValid = validateAll();
        if (!isValid) { return false; };
        isSetActive(true);
    
        let payload={old_password:values.old_password,new_password:values.new_password,confirm_password:values.confirm_password}
         await dispatch(changePasswordUser(payload));
    }

    return (
        <>
         <LoadingOverlay active={isActive} spinner text='Loading...'>
         <Header2 />
            <Sidebar />
            <PageTitle />
        <ToasterContainer/>
            <div className="authincation section-padding">
                <div className="container-fluid h-100">
                    <div className="row justify-content-center h-100 align-items-center">
                        <div className="col-xl-6 col-md-6">
                            {/* <div className="mini-logo text-center my-5">
                                <Link to="/"><img src={require("../../images/crypot-exchange-logo.png")} alt="" /></Link>
                            </div> */}
                            <div className="auth-form card">
                                <div className="card-header justify-content-center">
                                    <h4 className="card-title">Change password</h4>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={(e) => handleSubmit(e)}>

                                    <div className="mb-3 form-group password-group">
                                            <label>Old Password</label>
                                            <input type={!isVisible2 ? "password" : "text"} onBlur={validateOne} className="form-control" placeholder='Please enter the old password' onChange={handleChange} value={values.old_password} name="old_password" />
                                            {
                                                !isVisible2 ? (<i class="mdi mdi-eye" onClick={toggle2}></i>) : ( <i class="mdi mdi-eye-off" onClick={toggle2}></i>)
                                            }
                                               <small style={{ color: 'red' }}>{Validations.old_password}</small>
                                        </div>

                                        <div className="mb-3 form-group password-group">
                                            <label>New Password</label>
                                            <input type={!isVisible ? "password" : "text"} onBlur={validateOne} className="form-control" placeholder='Please enter the new password' onChange={handleChange} value={values.new_password} name="new_password" />
                                            {
                                                !isVisible ? (<i class="mdi mdi-eye" onClick={toggle}></i>) : ( <i class="mdi mdi-eye-off" onClick={toggle}></i>)
                                            }
                                               <small style={{ color: 'red' }}>{Validations.new_password}</small>
                                        </div>
                                        <div className="mb-3 form-group password-group">
                                            <label>Confirm Password</label>
                                            <input type={!isVisible1 ? "password" : "text"} onBlur={validateOne} className="form-control" placeholder='Please enter the confirm password' onChange={handleChange} value={values.confirm_password} name="confirm_password" />
                                            { 
                                                !isVisible1 ? (<i class="mdi mdi-eye" onClick={toggle1}></i>) : ( <i class="mdi mdi-eye-off" onClick={toggle1}></i>)
                                            }
                                             <small style={{ color: 'red' }}>{Validations.confirm_password}</small>
                                        </div>
                                        <div className="text-center">
                                            <button type="submit" className="btn btn-success ">{submit}</button>   
                                            <Link to="/exchange" className="btn btn-success ">Back</Link>
                                        </div>
                                    </form>
                                    {/* <div className="new-account mt-3">
                                        <p className="mb-1">Don't Received? </p>
                                        <Link className="text-primary" to="/reset">Resend </Link>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <OrderPlace/>
                    </div>
                </div>
            </div>
            </LoadingOverlay>
        </>
    )
}

export default ChangePassword;