import React, { useEffect, useRef, useState } from "react";
import dummy from "../../../util/images/dummy.png"
import Header2 from "../../layout/header2";
import Sidebar from "../../layout/sidebar";
import PageTitle from "../../element/page-title";
import ToasterContainer from "../../component/ToasterContainer";
import { getDepartments } from "../../../store/BasicInfo/basicInfoSlice";
import { useDispatch, useSelector } from "react-redux";
import { createTicket } from "../../../store/BasicInfo/basicInfoSlice";
import { capitalizeFirstLowercaseRest } from "../../component/helper";
import { Link, useNavigate } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import useDocumentTitle from "../../../helper/useTitle"

export default function CreateTicket(){
    useDocumentTitle('Create Ticket | Exchange')
    const [values,setValues]=useState({enquiry:"",department:"",description:"",uploadDocument:""});
    const [Validations, setValidation]=useState({enquiry:"",department:"",description:"",uploadDocument:""});
    const [image,setImage]=useState();
    const [submit,setSubmit]=useState("Submit")
    const [imgName,setImgName]=useState("");
    const dispatch=useDispatch();
    const [dept,setDepartment]=useState("");
    const [isActive,isSetActive]=useState(false);
    const navigate=useNavigate();
    const {allDepartment,isDepartmentCall,message,  isMakeTicket, createTicketDetail}=useSelector((state) => state.basic);

    const previosProps1=useRef({isMakeTicket}).current;

    useEffect(() => {
            if(previosProps1.isMakeTicket!=isMakeTicket && createTicketDetail){
                isSetActive(true)
                setTimeout(() => {
                    navigate("/support")
                },3000)
            }
    },[isMakeTicket,createTicketDetail])

    let previosProps=useRef({isDepartmentCall}).current;
    useEffect(() => {
        if(previosProps.isDepartmentCall!=isDepartmentCall && allDepartment){
                setDepartment(allDepartment?.data)
        }
    },[isDepartmentCall,allDepartment,message])


    useEffect(() => {dispatch(getDepartments())},[]);

    const handleChange = (e) => {
        const {name,value}=e.target;
        setValues({...values,[name]:value})
    }

    function ValidationAll() {
        let Validations={};
        let isValid=true;
        if(!values.enquiry) {
            Validations.enquiry="Please enter the enquiry"
            isValid=false
        }
        if(!values.department){
            Validations.department="Please enter the department"
            isValid=false
        }

        if(!values.description){
            Validations.description="Please enter the description"
            isValid=false
        }

        if(!isValid){
            setValidation(Validations)
        }
        return isValid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const isValid=ValidationAll();
        if(!isValid){return false;}
        //isSetActive(true);
        let payloadCrateTicket ={
            enquiry:values.enquiry,
            department:values.department,
            description:values.description,
            imageName:imgName
        }
        console.log("active paylaod is here ***************************",payloadCrateTicket)
       await dispatch(createTicket(payloadCrateTicket))
    }

    const handleImageChange =  (e) => {
        if(e.target.files.length!=0){
            setImage(URL.createObjectURL(e.target.files[0]));
            setImgName(e.target.files[0])
        }
    }
    
    const department=dept && dept.filter(n => {return n.department!=null});


    const validateOne = (e) => {
        const { name } = e.target
        const value = values[name];
        let message = ''
        
        if (!value && name === 'enquiry') {
            message = `Please enter the enquiry`
        }
        if (!value && name === 'department') {
            message = `Please enter the department`
        }
        if (!value && name === 'description') { 
            message = `Please select the date of birth`
        }
        setValidation({ ...Validations, [name]: message })
    }
    return (
        <>
        <LoadingOverlay active={isActive} spinner text='Loading...'>
         <Header2 />
            <Sidebar />
            <PageTitle />
            <ToasterContainer />

            <div className="content-body">
                <div className="container">
                    <div className="row">
             <div className="col-xl-12">
                        <div className="row">

                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Create Ticket</h4>
                                    </div>
                                    <div className="card-body">
                                        <form onSubmit={(e) => handleSubmit(e)} className="personal_validate">
                                            <div className="row">
                                                <div className="mb-3 col-xl-12 col-md-12">
                                                    <div className="form-group">
                                                    <label className="form-label">Your Enquiry</label>
                                                    <input type="text" className="form-control" maxLength={100} onChange={handleChange} value={values?.enquiry=='undefined' ? '': values?.username} onBlur={validateOne} placeholder="Please enter enquiry"
                                                        name="enquiry"/>
                                                        </div>
                                                        <small style={{color:'red'}}>{Validations.enquiry}</small>
                                                </div>
                                              
                                                <div className="mb-3 col-xl-12 col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label">Department</label>
                                                    <select className="form-control" onChange={handleChange} value={values?.department} onBlur={validateOne} name="department">
                                                        <option value="Select the department">Select the department</option>
                                                       {
                                                        department && department.map((val) => 
                                                           <option key={val.id} value={capitalizeFirstLowercaseRest(val.department)}>{val?.department ? capitalizeFirstLowercaseRest(val.department) : ''}</option>
                                                        )
                                                       }
                                                    </select>
                                                    </div>
                                                    <small style={{color:'red'}}>{Validations.department}</small>
                                                </div>

                                                <div className="mb-3 col-xl-12 col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label">Description</label>
                                                    <textarea type="text" className="form-control" rows={5} maxLength={500} onChange={handleChange} value={values?.description} onBlur={validateOne} placeholder="Please enter description"
                                                        name="description"/>
                                                        </div>
                                                        <small style={{color:'red'}}>{Validations.description}</small>
                                                </div>


                                                <div className="mb-3 col-xl-12 col-md-12">
                                                    <div className="d-flex align-items-center mb-3">
                                                        <img className="me-3 rounded-circle me-0 me-sm-3" draggable={false} src={image?image:dummy} width="100" height="100" alt="upload document" />
                                                        <div className="flex-grow-1">
                                                        </div>
                                                    </div>
                                                   
                                                    <div className="file-upload-wrapper" data-text="Upload Document">
                                                        <input name="upload_document" onChange={handleImageChange} type="file" className="file-upload-field"/>
                                                    </div>
                                                </div>


                                                <div className="mb-3 col-12">
                                                    <button type="submit" className="btn btn-success px-4">{submit}</button>
                                                    <Link to="/support" className="btn btn-success">Back</Link>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div></div></div>
                    </LoadingOverlay>
                    
        </>
    )
}