import React, { useEffect, useState } from "react";

import { getAllCurrenciesByPair, getDetailForOrderBook, getOrderHistoryByPairId } from "../../../store/order/orderMatchingSlice";
import { useDispatch, useSelector } from "react-redux";
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from "react-bootstrap-table-next";
import { getTabAllCurrencyPair } from "../../../store/order/orderMatchingSlice";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { getFilterData } from "../helper";
import { getInnerHeaderDetailExchange } from "../../../store/order/orderMatchingSlice";

function PairCurrency({setPairIdDynamic,setDynamicHeader}) {
    console.log("_______________***************************************props is here _________________________",setPairIdDynamic)
    const [pair, setPair] = useState("");
    const [defaultFlagId,setDefaultFlagId]=useState("")
    const [tabs, setTabs] = useState("");
    const dispatch = useDispatch();
    const [termId, setTermId] = useState(1); //just for usdt would be active for temporary
    const [activeTab, setActiveTab] = useState("active");
    const [pairId, setPairId] = useState("");
    const [filter, setFilter] = useState("");
    const [currentId,dynamicId]=useState("");
    const [selectedRow, setSelectedRow] = useState(-1);
    const { isPairCurrencyTabStatus, getPairCurrencyTabDetail, getAllCurrenciesPair, getInnerHeaderOrderDetail } = useSelector((state) => state.order);

 
    //api hit for getting all the pair along with currencies
    useEffect(() => {
        dispatch(getTabAllCurrencyPair())
    }, []);

    useEffect(() => {
        dispatch(getAllCurrenciesByPair({ term_id: termId }))
    }, [termId])

    //state update when currency pair response will get from the server
    useEffect(() => {
        if (getAllCurrenciesPair?.data && getAllCurrenciesPair?.status === true) {
            setPair(getAllCurrenciesPair?.data)
        }
    }, [getAllCurrenciesPair]);

    //state update for currency tabs response will get from the server
    useEffect(() => {
        if (getPairCurrencyTabDetail && getPairCurrencyTabDetail?.status === true) {
            setTabs(getPairCurrencyTabDetail?.data)
        }
    }, [getPairCurrencyTabDetail])

    //column define here for pair
        const columns = [
        {
            dataField: "base",
            text: "Pair",
            sort: true,

            formatter: (cell, row) => {

                return (
                    <>
                        {
                            <div class="d-flex align-items-center">
                                <i class="mdi mdi-star"></i>
                                <div class="currimg">
                                    <img src={cell.currency_icon} width="16px" alt={cell.symbol} />&nbsp;
                                    <><strong>{cell.symbol}</strong>/{row?.term?.symbol}</>
                                </div>
                            </div>
                        }
                    </>
                )
            },
            filter: textFilter({
                className: ""
            }),
        },
        {
            dataField: "amount",
            text: "Last Price",
            formatter: (cell, row) => {
                return <div>{row?.market?.last_trade_price ? row?.market?.last_trade_price : '0'}</div>
            }
        },
        {
            dataField: "market",
            text: "Change",
            formatter: (data, allData) => {
                return data?.change > 0 ? <div class={data?.change ? "green" : ""}>{data?.change ? data?.change + " %" : '0'} </div> :
                    <div class={data?.change ? "red" : ''}>{data?.change ? data?.change + " %" : '0'}</div>
            }
        },
    ];
    useEffect(() => {
        if(getInnerHeaderOrderDetail) {
            setDynamicHeader(getInnerHeaderOrderDetail)
        }
    },[getInnerHeaderOrderDetail])

    useEffect(() => {
        if(currentId){
            dispatch(getDetailForOrderBook({type:"active",pair_id:parseInt(currentId)}))
            dispatch(getDetailForOrderBook({type:"completed",pair_id:parseInt(currentId)}))
            dispatch(getDetailForOrderBook({type:"cancel",pair_id:parseInt(currentId)}))
        }
    },[currentId]);

    const handleRowImpact = (id) => {
        setSelectedRow(id)
        setPairIdDynamic(id)
        dynamicId(id)
        if(id){
            dispatch(getInnerHeaderDetailExchange({pair_id:parseInt(id)}))
            dispatch(getOrderHistoryByPairId({pair_id:parseInt(id)}))
        }
    }

    //filter call here for
    const filteredData=getFilterData(pair,filter);
    return (
        <>
            <div class="col-xl-3 col-lg-3 col-md-12 col-xxl-3">
                <div class="card mt-4 pairCurrency-batches">

                    <div class="card-header border-0 pb-2">
                        <div class="search-coin-container">
                            <i class="fa fa-search"></i> <input type="text" onChange={(e) => setFilter(e.target.value)} class="form-control" placeholder="Search" />
                        </div>
                    </div>

                    <div class="card-header border-0 pb-0">
                        <ul class="nav nav-pills currencypill" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="pill" href="#STAR" role="tab"
                                    aria-selected="true"><i class="mdi mdi-star"></i></a>
                            </li>

                            {
                                tabs.length > 0 ? tabs.map((val) => (
                                    <li class='nav-item'>
                                        <a class={`nav-link ${val?.symbol=="USDT" ? 'active' : ''}`} key={val?.id} data-toggle="pill" onClick={() => 
                                        {setTermId(val?.term_id)
                                        setDefaultFlagId(val?.default_flag_pair_id)}
                                    } href={`#${val?.symbol}`} role="tab"
                                            aria-selected="true">{val?.symbol}</a>
                                    </li>
                                )) : <p className="text-center">Data not found</p>
                            }
                        </ul>
                    </div>

                    <div className="card-body price-pair ps ps--active-y">
                        <div className="tab-content">
                            <div class="tab-pane fade show active" id="BTC" role="tabpanel">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>Pairs</th>
                                            <th>Last Price</th>
                                            <th>Change</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            filteredData?.length > 0 ? filteredData?.map((val) => (
                                                <tr key={val?.id} className={"clikable-row".concat(selectedRow===val.id ? " selected" : "")} onClick={() => handleRowImpact(val?.id)}>
                                                    <td class="d-flex align-items-center"><i class="mdi mdi-star"></i>
                                                        <div class="currimg">
                                                            <img src={val?.base.currency_icon} width="16px" alt={val?.symbol} /><strong>{val.base.symbol}</strong>/{val.term.symbol}</div>
                                                    </td>
                                                    <td>{val?.market?.last_trade_price || '0'}</td>
                                                    <td class={val?.market?.change > 0 && "green" || val?.market?.change < 0 && "red"}>{val?.market?.change ? val?.market?.change + " %" : '0'}</td>
                                                </tr>
                                            )) : <p className="red text-center">Data not found</p>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
export default PairCurrency;