import React, { useRef, useState } from "react";
import Header2 from "../../layout/header2";
import Sidebar from "../../layout/sidebar";
import PageTitle from "../../element/page-title";
import ToasterContainer from "../../component/ToasterContainer";
import { viewTicket, BASE_URL_URL, REACT_APP_S3_CLOUD_IMAGE_URL, createThread } from "../../../util/constant";
import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import dummy from "../../../util/images/dummy.png"
import { makeThreadMessages } from "../../../store/BasicInfo/basicInfoSlice";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import LoadingOverlay from 'react-loading-overlay';
import JoditEditor from 'jodit-react';
import { useMemo } from "react";
import { getAllSupportedThread, getAllAdminThread } from "../../../store/BasicInfo/basicInfoSlice";
import { capitalizeFirstLowercaseRest } from "../../component/helper";
import user_1 from "../../../images/user_1.jpg";
import user_2 from "../../../images/user_2.jpg";
import OrderPlace from "../../component/placeOrder/orderPlace";
import moment from "moment";


export default function ViewTicket() {


    const { postThreadDetail, isThread, isAllThreadGet, getAllThreadSupport, isAdminThread, adminThreadDetail } = useSelector((state) => state.basic);
    const [isActive, isSetActive] = useState(false);
    const editor = useRef(null);
    const [content, setContent] = useState('');
    const [thread, setThread] = useState("")
    const [adminThread, setAdminThreat] = useState("")
    const params = useParams();
    const [values, setValues] = useState({ thread_messages: "" });
    const [Validations, setValidation] = useState({ thread_messages: "" })
    const dispatch = useDispatch();
    const [adminChat,setAdminChat]=useState("")

    //render all thread is here for user
    useEffect(() => {
        if (isAllThreadGet && getAllThreadSupport) {
            setThread(getAllThreadSupport?.data)
        }
    }, [getAllThreadSupport, isAllThreadGet]);

    //render all thread is here for user
    useEffect(() => {
        if (isAdminThread && adminThreadDetail) {
            setAdminThreat(adminThreadDetail?.data)
        }
    }, [adminThreadDetail, isAdminThread]);

    //fetch all record is here 
    useEffect(() => {
        setInterval(() => {
            dispatch(getAllSupportedThread({ id: params.id }))
        }, 1000);
        setInterval(() => {
            dispatch(getAllAdminThread({id:params.id}))
        }, 1000);
    },[]);


    async function viewDetail() {
        let token = await JSON.parse(localStorage.getItem("authLogger"))
        const tokenDetails = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        const { data } = await axios.get(BASE_URL_URL + viewTicket + "/" + parseInt(params.id), tokenDetails);
        return data && data?.data;
    }
    const { data, error, isError, isLoading, isSuccess, refetch } = useQuery({
        queryKey: ['viewDetail'],
        queryFn: viewDetail,
        //refetchInterval:1000 //5s
    });

    useEffect(() => {
        if (postThreadDetail?.message === "Thread has been created successfully") {
            console.log("inner call is here **********************************************")
            refetch()
            isSetActive(true)
        }
    }, [postThreadDetail?.message])



    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value })
    }

    function ValidationAll() {
        let Validations = {};
        let isValid = true;

        if (!values.thread_messages) {
            Validations.thread_messages = "Please enter the thread messages"
            isValid = false
        }
        if (!isValid) {
            setValidation(Validations)
        }
        return isValid;
    }


    const handleSubmit = async (e) => {
        e.preventDefault();

        const isValid = ValidationAll();
        if (!isValid) { return false; }
        isSetActive(true)
        let threadPayload = {
            ticket_id: params.id,
            thread_messages: values.thread_messages
        }
        await dispatch(makeThreadMessages(threadPayload))
        await dispatch(getAllSupportedThread({ id: params.id }))
        setValues({thread_messages:""})
    }

    function removeTags(str) {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();
        return str.replace(/(<([^>]+)>)/ig, '');
    }


    //jodit implement is here

    return (
        <>
            <LoadingOverlay spinner text='Loading...'>
                <Header2 />
                <Sidebar />
                <PageTitle />
                <ToasterContainer />
                <div className="content-body">
                    <div className="container-fluid">
                        <div className="row">

                        <div className="col-xl-12">
                           <table className="table table-striped">
                            <tr>
                                <th>Enquiry</th>
                                <th>Department</th>
                                <th>Description</th>
                                <th>Upload Document</th>
                            </tr>   
                            <tr>
                                <td>{capitalizeFirstLowercaseRest(data?.enquiry) || 'N/A'}</td>
                                <td>{ capitalizeFirstLowercaseRest(data?.department) || 'N/A'}</td>
                                <td>{data?.description || 'N/A'}</td>
                                <td><img height={100} width={100} src={data?.upload_document ? data?.upload_document : dummy}  /></td>
                            </tr>
                           </table>
                        </div>


                            <div class="col-xl-9 col-lg-9 col-md-12 col-xxl-9">
                                <div class="card">
                                    <div class="inner-card-header card-header">
                                        <h4>Chat Box</h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-8 m-auto">

                                                <div class="box box-primary direct-chat direct-chat-primary">

                                                    <div class="box-body">
                                                        <div class="direct-chat-messages">
                                                            {/* for user thread show is here show here */}
                                                            {
                                                                thread && thread?.map((val) => (
                                                                    <div class="direct-chat-msg" key={val?.id}>

                                                                        <div class="direct-chat-info clearfix">
                                                                            <span class="direct-chat-name pull-left">{val?.User?.first_name ? capitalizeFirstLowercaseRest(val?.User?.first_name) + " "+capitalizeFirstLowercaseRest(val?.User?.last_name) : 'N/A'}</span>
                                                                            <span class="direct-chat-timestamp pull-left ml-2">{moment(val?.createdAt).format("DD/MM/YYYY hh:mm:ss a")}</span>
                                                                        </div>

                                                                        <img class="direct-chat-img" draggable={false} src={val?.User?.profile_image ? val?.User?.profile_image : user_1} alt="Me" />
                                                                        <div class="direct-chat-text">
                                                                        {capitalizeFirstLowercaseRest(removeTags(val?.thread_messages)) || 'N/A'}
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }

                                                            {/* end of user thread is here */}
                                                            {
                                                                adminThread && adminThread?.map((vall) => (
                                                            <div class="direct-chat-msg right" key={vall.id}>
                                                                <div class="direct-chat-info clearfix">
                                                                    <span class="direct-chat-name pull-right">{vall?.Admin?.firstname ? capitalizeFirstLowercaseRest(vall?.Admin?.firstname) +" " + capitalizeFirstLowercaseRest(vall?.Admin?.lastname) :'N/A'}</span>
                                                                    <span class="direct-chat-timestamp pull-right mr-2">{moment(vall?.createdAt).format("DD/MM/YYYY hh:mm:ss a")}</span>
                                                                </div>

                                                                <img class="direct-chat-img" draggable={false} src={vall?.Admin?.image ? vall?.Admin?.image  :dummy} alt="Message User Image" />
                                                                <div class="direct-chat-text">
                                                                    {capitalizeFirstLowercaseRest(removeTags(vall?.thread_messages)) || 'N/A'}
                                                                </div>
                                                            </div>
                                                                ))
                                                           }

                                                        </div>

                                                    </div>
                                                    <div class="box-footer">
                                                    {/* <form onSubmit={(e) => handleSubmit(e)}>
                                <div className="col-xl-12">
                                    <JoditEditor onSubmit={(e) => handleSubmit(e)} name="thread_messages" ref={editor}
                                        value={content}
                                        //config={config}
                                        theme= 'default'
                                        tabIndex={1} // tabIndex of textarea
                                        onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                                        onChange={newContent => { }}
                                    />

                                    <small style={{ color: 'red' }}>{Validations.thread_messages}</small>

                                </div>
                                <div className="col-xl-12">
                                    <button type="submit" class="btn btn-success btn-block mt-4" disabled={isActive}>Submit</button>
                                    <Link to="/support" className="btn btn-info">Back</Link>
                                </div>
                            </form> */}
                                                        <form onSubmit={(e) => handleSubmit(e)}>
                                                            <div class="input-group">
                                                                <textarea  name="thread_messages" maxLength={500} onChange={handleChange} value={values?.thread_messages} placeholder="Type Message ..." class="form-control"></textarea>
                                                                <small style={{ color: 'red' }}>{Validations.thread_messages}</small>
                                                                <span class="input-group-btn">
                                                                    <button type="submit" class="btn btn-success">Send</button>
                                                                </span>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <OrderPlace />

                            

                        </div>

                    </div>


                </div>
            </LoadingOverlay>
        </>
    )
}