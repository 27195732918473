import React, { useEffect, useRef, useState } from 'react';

import PageTitle from '../element/page-title';
import Footer2 from '../layout/footer2';
// import { Link } from 'react-router-dom';
// import { Row, Col, Card } from 'react-bootstrap';
import Header2 from '../layout/header2';
import SettingsSubmenu from '../layout/settings-submenu';
import Sidebar from '../layout/sidebar';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getUserProfile, profileUser } from '../../store/BasicInfo/basicInfoSlice';
import { useDispatch, useSelector } from 'react-redux';
import dummyImage from "../../images/profile/2.png"
import moment from "moment"
import { useNavigate } from 'react-router-dom';
import ToasterContainer from '../component/ToasterContainer';
import dummy from "../../util/images/dummy.png"
import { capitalizeFirstLowercaseRest } from '../component/helper';
import useDocumentTitle from "../../helper/useTitle"
import LoadingOverlay from 'react-loading-overlay';
import OrderPlace from '../component/placeOrder/orderPlace';

function Settings() {
    useDocumentTitle('Setting | Exchange')
    const [values,setValues]=useState({first_name:"",email:"",date_of_birth:"",present_address:"",permanent_address:"",city:"",postel_code:"",country:""})
    const [Validations,setValidation]=useState({first_name:"",email:"",date_of_birth:"",present_address:"",permanent_address:"",city:"",postel_code:"",country:""})
    const [startDate, setStartDate] = useState();
    const [image,setImage]=useState();
    const [updateProfile,setUpdateProfile]=useState("Update Profile")
    const [imgName,setImgName]=useState("");
    const [isActive, isSetActive] = useState(false);
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const [populate,setAutoPopulate]=useState("");

    const {ProfileDetail,message,isUserProfile,UserProfileGetting,isProfileGet}=useSelector((state) => state.basic);

    const previousProps=useRef({isUserProfile}).current;

    useEffect(() => {
         dispatch(getUserProfile())
    },[])

    useEffect(() => {
        if(isProfileGet && UserProfileGetting){
            setAutoPopulate(UserProfileGetting.data)
        }
    },[UserProfileGetting,isProfileGet])


    useEffect(() => {
        if(previousProps?.isUserProfile!==isUserProfile && ProfileDetail?.message==="Profile has been updated Done"){
            isSetActive(true);
            setUpdateProfile("Processing...Please wait")
            setTimeout(() => {
                navigate("/signin")
            }, 3000);
        }
    },[message,isUserProfile,ProfileDetail])

console.log(populate);

    useEffect(() => {
        if(populate?.date_of_birth){setValues(setStartDate(new Date(populate?.date_of_birth)))}
        
		populate && setValues({first_name:populate?.first_name,email:populate?.email,present_address:populate?.present_address,country:populate?.country,permanent_address:populate?.permanent_address,city:populate?.city,postel_code:populate?.postel_code})
	}, [populate])

    //console.log("profile detail is here  ***************************",UserProfileGetting)

    // useEffect( () => {
    //      dispatch(getUserProfile())
    // },[])




    const handleChange = (e) => {
        const {name,value}=e.target;
        setValues({...values,[name]:value})
    }

    const handleImageChange =  (e) => {
        if(e.target.files.length!=0){
            setImage(URL.createObjectURL(e.target.files[0]));
            setImgName(e.target.files[0])
        }
    }


    function ValidationAll() {
        let Validations={};
        let isValid=true;
        if(!values.first_name) {
            Validations.first_name="Please enter the first_name"
            isValid=false
        }
        if(!values.date_of_birth){
            Validations.date_of_birth="Please enter the date of birth"
            isValid=false
        }
        // if (values.email && !values.email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
        //     Validations.email = "Please enter the valid email address"
        //     isValid = false
        // }
        // if (values.password && !values.password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)) {
        //     Validations.password = "Please enter the valid password address eight character,uppercase,lowercase,spec char"
        //     isValid = false
        // }
        if(!isValid){
            setValidation(Validations)
        }
        return isValid;
    }

    const validateOne = (e) => {
        const { name } = e.target
        const value = values[name];
        console.log("______________________________________________",value, name )
        let message = ''
        
        if (!value && name === 'first_name') {
            message = `Please enter the first_name`
        }
        if (!value && name === 'date_of_birth') {
            message = `Please select the date of birth`
        }
        if (!value && name === 'present_address') {
            message = `Please enter the present address`
        }
        if (!value && name === 'permanent_address') {
            message = `Please enter the present address`
        }
        if (!value && name === 'city') {
            message = `Please enter the city`
        }
        if (!value && name === 'postel_code') {
            message = `Please enter the postel code`
        }
        if (!value && name === 'country') {
            message = `Please enter the country`
        }

        
        // if(!value && name==='email' && !value.match(/^[A-Z0-9. _%+-]+@[A-Z0-9. -]/)){
        //     message=`Please enter the valid email address`
        // }
        // if(value && name==='password' && !value.match(/^[A-Z0-9. _%+-]+@[A-Z0-9. -]/)){
        //     message=`Please enter the valid email address`
        // }

        setValidation({ ...Validations, [name]: message })
    }



    const handleSubmit = (e) => {
        e.preventDefault();
      
        let payload ={
            first_name:values.first_name,
            date_of_birth:moment(startDate).format("YYYY/MM/DD"),
            present_address:values.present_address,
            permanent_address:values.permanent_address,
            city:values.city,
            postel_code:values.postel_code,
            country:values.country,
            profile_image:imgName
        }
    
       dispatch(profileUser(payload));
    }

    return (
        <>
        <LoadingOverlay active={isActive} spinner text='Loading...'>
            <Header2 />
            <Sidebar />
            <PageTitle />
            <ToasterContainer/>
            <div className="content-body">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card sub-menu">
                            <div className="card-body">
                            <SettingsSubmenu/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-9">
                        <div className="row">

                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Personal Information</h4>
                                    </div>
                                    <div className="card-body">
                                        <form onSubmit={(e) => handleSubmit(e)} className="personal_validate">
                                            <div className="row">
                                                <div className="mb-3 col-xl-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Your Name</label>
                                                    <input type="text" className="form-control" onChange={handleChange} value={values?.first_name==="null" ? '' : values?.first_name} onBlur={validateOne} placeholder="Jhon Marrit"
                                                        name="first_name"/>
                                                    </div>
                                                        <small style={{color:'red'}}>{Validations.first_name}</small>
                                                </div>
                                                <div className="mb-3 col-xl-6 col-md-6">
                                                    <div className="form-group">
                                                    <label className="form-label">Email</label>
                                                    <input type="text" className="form-control"
                                                        placeholder="Hello@example.com" readOnly name="email" onChange={handleChange} value={values?.email==="null" ? '' : values?.email} onBlur={validateOne} />
                                                </div>
                                                </div>
                                                <div className="mb-3 col-xl-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Date of birth</label>
                                                    <div>
                                                    <DatePicker 
                                                    isClearable={true}
                                                    withPortal
                                                    value={startDate}
                                                    showIcon placeholderText='Select the dob' selected={startDate} onBlur={validateOne}  className='form-control' name="date_of_birth" onChange={(date) => setStartDate(date)} />
                                                    </div>
                                                    </div>
                                                </div>
                                                <div className="mb-3 col-xl-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Present Address</label>
                                                    <input type="text" className="form-control"
                                                        placeholder="Please enter the present address" onChange={handleChange} value={values?.present_address=="null" ? '' : values?.present_address } onBlur={validateOne} name="present_address"/>
                                                </div>
                                                </div>
                                                <div className="mb-3 col-xl-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Permanent Address</label>
                                                    <input type="text" name="permanent_address" onChange={handleChange} value={values?.permanent_address=="null" ? '' : values?.permanent_address} onBlur={validateOne} className="form-control"
                                                        placeholder="Please enter the permanent address"
                                                       />
                                                       </div>
                                                </div>
                                                <div className="mb-3 col-xl-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">City</label>
                                                    <input type="text" className="form-control" onChange={handleChange} value={values?.city==="null" ? '' : values?.city} onBlur={validateOne} placeholder="Please enter the city"
                                                        name="city"/>
                                                </div>
                                                </div>
                                                <div className="mb-3 col-xl-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Postal Code</label>
                                                    <input type="text" className="form-control" maxLength={6} onChange={handleChange} value={values?.postel_code==="null" ? '' : values?.postel_code} onBlur={validateOne} placeholder="Please enter the postel code"
                                                        name="postel_code"/>
                                                        </div>
                                                </div>
                                                <div className="mb-3 col-xl-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Country</label>
                                                    <select className="form-control" onChange={handleChange} value={values?.country==="null" ? '' : values?.country} onBlur={validateOne} name="country">
                                                        <option value="Select the country">Select the country</option>
                                                        <option value="Afghanistan">Afghanistan</option>
                                                        <option value="Åland Islands">Åland Islands</option>
                                                        <option value="Albania">Albania</option>
                                                        <option value="Algeria">Algeria</option>
                                                        <option value="American Samoa">American Samoa</option>
                                                        <option value="Andorra">Andorra</option>
                                                        <option value="Angola">Angola</option>
                                                        <option value="Anguilla">Anguilla</option>
                                                        <option value="Antarctica">Antarctica</option>
                                                        <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                                        <option value="Argentina">Argentina</option>
                                                        <option value="Armenia">Armenia</option>
                                                        <option value="Aruba">Aruba</option>
                                                        <option value="Australia">Australia</option>
                                                        <option value="Austria">Austria</option>
                                                        <option value="Azerbaijan">Azerbaijan</option>
                                                        <option value="Bahamas">Bahamas</option>
                                                        <option value="Bahrain">Bahrain</option>
                                                        <option value="Bangladesh">Bangladesh</option>
                                                        <option value="Barbados">Barbados</option>
                                                        <option value="Belarus">Belarus</option>
                                                        <option value="Belgium">Belgium</option>
                                                        <option value="Belize">Belize</option>
                                                        <option value="Benin">Benin</option>
                                                        <option value="Bermuda">Bermuda</option>
                                                        <option value="Bhutan">Bhutan</option>
                                                        <option value="Bolivia">Bolivia</option>
                                                        <option value="Bosnia and Herzegovina">Bosnia and Herzegovina
                                                        </option>
                                                        <option value="Botswana">Botswana</option>
                                                        <option value="Bouvet Island">Bouvet Island</option>
                                                        <option value="Brazil">Brazil</option>
                                                        <option value="British Indian Ocean Territory">British Indian
                                                            Ocean Territory</option>
                                                        <option value="Brunei Darussalam">Brunei Darussalam</option>
                                                        <option value="Bulgaria">Bulgaria</option>
                                                        <option value="Burkina Faso">Burkina Faso</option>
                                                        <option value="Burundi">Burundi</option>
                                                        <option value="Cambodia">Cambodia</option>
                                                        <option value="Cameroon">Cameroon</option>
                                                        <option value="Canada">Canada</option>
                                                        <option value="Cape Verde">Cape Verde</option>
                                                        <option value="Cayman Islands">Cayman Islands</option>
                                                        <option value="Central African Republic">Central African
                                                            Republic</option>
                                                        <option value="Chad">Chad</option>
                                                        <option value="Chile">Chile</option>
                                                        <option value="China">China</option>
                                                        <option value="Christmas Island">Christmas Island</option>
                                                        <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands
                                                        </option>
                                                        <option value="Colombia">Colombia</option>
                                                        <option value="Comoros">Comoros</option>
                                                        <option value="Congo">Congo</option>
                                                        <option value="Congo, The Democratic Republic of The">Congo, The
                                                            Democratic Republic of The</option>
                                                        <option value="Cook Islands">Cook Islands</option>
                                                        <option value="Costa Rica">Costa Rica</option>
                                                        <option value="Cote D'ivoire">Cote D'ivoire</option>
                                                        <option value="Croatia">Croatia</option>
                                                        <option value="Cuba">Cuba</option>
                                                        <option value="Cyprus">Cyprus</option>
                                                        <option value="Czech Republic">Czech Republic</option>
                                                        <option value="Denmark">Denmark</option>
                                                        <option value="Djibouti">Djibouti</option>
                                                        <option value="Dominica">Dominica</option>
                                                        <option value="Dominican Republic">Dominican Republic</option>
                                                        <option value="Ecuador">Ecuador</option>
                                                        <option value="Egypt">Egypt</option>
                                                        <option value="El Salvador">El Salvador</option>
                                                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                        <option value="Eritrea">Eritrea</option>
                                                        <option value="Estonia">Estonia</option>
                                                        <option value="Ethiopia">Ethiopia</option>
                                                        <option value="Falkland Islands (Malvinas)">Falkland Islands
                                                            (Malvinas)</option>
                                                        <option value="Faroe Islands">Faroe Islands</option>
                                                        <option value="Fiji">Fiji</option>
                                                        <option value="Finland">Finland</option>
                                                        <option value="France">France</option>
                                                        <option value="French Guiana">French Guiana</option>
                                                        <option value="French Polynesia">French Polynesia</option>
                                                        <option value="French Southern Territories">French Southern
                                                            Territories</option>
                                                        <option value="Gabon">Gabon</option>
                                                        <option value="Gambia">Gambia</option>
                                                        <option value="Georgia">Georgia</option>
                                                        <option value="Germany">Germany</option>
                                                        <option value="Ghana">Ghana</option>
                                                        <option value="Gibraltar">Gibraltar</option>
                                                        <option value="Greece">Greece</option>
                                                        <option value="Greenland">Greenland</option>
                                                        <option value="Grenada">Grenada</option>
                                                        <option value="Guadeloupe">Guadeloupe</option>
                                                        <option value="Guam">Guam</option>
                                                        <option value="Guatemala">Guatemala</option>
                                                        <option value="Guernsey">Guernsey</option>
                                                        <option value="Guinea">Guinea</option>
                                                        <option value="Guinea-bissau">Guinea-bissau</option>
                                                        <option value="Guyana">Guyana</option>
                                                        <option value="Haiti">Haiti</option>
                                                        <option value="Heard Island and Mcdonald Islands">Heard Island
                                                            and Mcdonald Islands</option>
                                                        <option value="Holy See (Vatican City State)">Holy See (Vatican
                                                            City State)</option>
                                                        <option value="Honduras">Honduras</option>
                                                        <option value="Hong Kong">Hong Kong</option>
                                                        <option value="Hungary">Hungary</option>
                                                        <option value="Iceland">Iceland</option>
                                                        <option value="India">India</option>
                                                        <option value="Indonesia">Indonesia</option>
                                                        <option value="Iran, Islamic Republic of">Iran, Islamic Republic
                                                            of</option>
                                                        <option value="Iraq">Iraq</option>
                                                        <option value="Ireland">Ireland</option>
                                                        <option value="Isle of Man">Isle of Man</option>
                                                        <option value="Israel">Israel</option>
                                                        <option value="Italy">Italy</option>
                                                        <option value="Jamaica">Jamaica</option>
                                                        <option value="Japan">Japan</option>
                                                        <option value="Jersey">Jersey</option>
                                                        <option value="Jordan">Jordan</option>
                                                        <option value="Kazakhstan">Kazakhstan</option>
                                                        <option value="Kenya">Kenya</option>
                                                        <option value="Kiribati">Kiribati</option>
                                                        <option value="Korea, Democratic People's Republic of">Korea,
                                                            Democratic People's Republic of</option>
                                                        <option value="Korea, Republic of">Korea, Republic of</option>
                                                        <option value="Kuwait">Kuwait</option>
                                                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                        <option value="Lao People's Democratic Republic">Lao People's
                                                            Democratic Republic</option>
                                                        <option value="Latvia">Latvia</option>
                                                        <option value="Lebanon">Lebanon</option>
                                                        <option value="Lesotho">Lesotho</option>
                                                        <option value="Liberia">Liberia</option>
                                                        <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya
                                                        </option>
                                                        <option value="Liechtenstein">Liechtenstein</option>
                                                        <option value="Lithuania">Lithuania</option>
                                                        <option value="Luxembourg">Luxembourg</option>
                                                        <option value="Macao">Macao</option>
                                                        <option value="Macedonia, The Former Yugoslav Republic of">
                                                            Macedonia, The Former Yugoslav Republic of</option>
                                                        <option value="Madagascar">Madagascar</option>
                                                        <option value="Malawi">Malawi</option>
                                                        <option value="Malaysia">Malaysia</option>
                                                        <option value="Maldives">Maldives</option>
                                                        <option value="Mali">Mali</option>
                                                        <option value="Malta">Malta</option>
                                                        <option value="Marshall Islands">Marshall Islands</option>
                                                        <option value="Martinique">Martinique</option>
                                                        <option value="Mauritania">Mauritania</option>
                                                        <option value="Mauritius">Mauritius</option>
                                                        <option value="Mayotte">Mayotte</option>
                                                        <option value="Mexico">Mexico</option>
                                                        <option value="Micronesia, Federated States of">Micronesia,
                                                            Federated States of</option>
                                                        <option value="Moldova, Republic of">Moldova, Republic of
                                                        </option>
                                                        <option value="Monaco">Monaco</option>
                                                        <option value="Mongolia">Mongolia</option>
                                                        <option value="Montenegro">Montenegro</option>
                                                        <option value="Montserrat">Montserrat</option>
                                                        <option value="Morocco">Morocco</option>
                                                        <option value="Mozambique">Mozambique</option>
                                                        <option value="Myanmar">Myanmar</option>
                                                        <option value="Namibia">Namibia</option>
                                                        <option value="Nauru">Nauru</option>
                                                        <option value="Nepal">Nepal</option>
                                                        <option value="Netherlands">Netherlands</option>
                                                        <option value="Netherlands Antilles">Netherlands Antilles
                                                        </option>
                                                        <option value="New Caledonia">New Caledonia</option>
                                                        <option value="New Zealand">New Zealand</option>
                                                        <option value="Nicaragua">Nicaragua</option>
                                                        <option value="Niger">Niger</option>
                                                        <option value="Nigeria">Nigeria</option>
                                                        <option value="Niue">Niue</option>
                                                        <option value="Norfolk Island">Norfolk Island</option>
                                                        <option value="Northern Mariana Islands">Northern Mariana
                                                            Islands</option>
                                                        <option value="Norway">Norway</option>
                                                        <option value="Oman">Oman</option>
                                                        <option value="Pakistan">Pakistan</option>
                                                        <option value="Palau">Palau</option>
                                                        <option value="Palestinian Territory, Occupied">Palestinian
                                                            Territory, Occupied</option>
                                                        <option value="Panama">Panama</option>
                                                        <option value="Papua New Guinea">Papua New Guinea</option>
                                                        <option value="Paraguay">Paraguay</option>
                                                        <option value="Peru">Peru</option>
                                                        <option value="Philippines">Philippines</option>
                                                        <option value="Pitcairn">Pitcairn</option>
                                                        <option value="Poland">Poland</option>
                                                        <option value="Portugal">Portugal</option>
                                                        <option value="Puerto Rico">Puerto Rico</option>
                                                        <option value="Qatar">Qatar</option>
                                                        <option value="Reunion">Reunion</option>
                                                        <option value="Romania">Romania</option>
                                                        <option value="Russian Federation">Russian Federation</option>
                                                        <option value="Rwanda">Rwanda</option>
                                                        <option value="Saint Helena">Saint Helena</option>
                                                        <option value="Saint Kitts and Nevis">Saint Kitts and Nevis
                                                        </option>
                                                        <option value="Saint Lucia">Saint Lucia</option>
                                                        <option value="Saint Pierre and Miquelon">Saint Pierre and
                                                            Miquelon</option>
                                                        <option value="Saint Vincent and The Grenadines">Saint Vincent
                                                            and The Grenadines</option>
                                                        <option value="Samoa">Samoa</option>
                                                        <option value="San Marino">San Marino</option>
                                                        <option value="Sao Tome and Principe">Sao Tome and Principe
                                                        </option>
                                                        <option value="Saudi Arabia">Saudi Arabia</option>
                                                        <option value="Senegal">Senegal</option>
                                                        <option value="Serbia">Serbia</option>
                                                        <option value="Seychelles">Seychelles</option>
                                                        <option value="Sierra Leone">Sierra Leone</option>
                                                        <option value="Singapore">Singapore</option>
                                                        <option value="Slovakia">Slovakia</option>
                                                        <option value="Slovenia">Slovenia</option>
                                                        <option value="Solomon Islands">Solomon Islands</option>
                                                        <option value="Somalia">Somalia</option>
                                                        <option value="South Africa">South Africa</option>
                                                        <option value="South Georgia and The South Sandwich Islands">
                                                            South Georgia and The South Sandwich Islands</option>
                                                        <option value="Spain">Spain</option>
                                                        <option value="Sri Lanka">Sri Lanka</option>
                                                        <option value="Sudan">Sudan</option>
                                                        <option value="Suriname">Suriname</option>
                                                        <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen
                                                        </option>
                                                        <option value="Swaziland">Swaziland</option>
                                                        <option value="Sweden">Sweden</option>
                                                        <option value="Switzerland">Switzerland</option>
                                                        <option value="Syrian Arab Republic">Syrian Arab Republic
                                                        </option>
                                                        <option value="Taiwan, Province of China">Taiwan, Province of
                                                            China</option>
                                                        <option value="Tajikistan">Tajikistan</option>
                                                        <option value="Tanzania, United Republic of">Tanzania, United
                                                            Republic of</option>
                                                        <option value="Thailand">Thailand</option>
                                                        <option value="Timor-leste">Timor-leste</option>
                                                        <option value="Togo">Togo</option>
                                                        <option value="Tokelau">Tokelau</option>
                                                        <option value="Tonga">Tonga</option>
                                                        <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                                        <option value="Tunisia">Tunisia</option>
                                                        <option value="Turkey">Turkey</option>
                                                        <option value="Turkmenistan">Turkmenistan</option>
                                                        <option value="Turks and Caicos Islands">Turks and Caicos
                                                            Islands</option>
                                                        <option value="Tuvalu">Tuvalu</option>
                                                        <option value="Uganda">Uganda</option>
                                                        <option value="Ukraine">Ukraine</option>
                                                        <option value="United Arab Emirates">United Arab Emirates
                                                        </option>
                                                        <option value="United Kingdom">United Kingdom</option>
                                                        <option value="United States">United States</option>
                                                        <option value="United States Minor Outlying Islands">United
                                                            States Minor Outlying Islands</option>
                                                        <option value="Uruguay">Uruguay</option>
                                                        <option value="Uzbekistan">Uzbekistan</option>
                                                        <option value="Vanuatu">Vanuatu</option>
                                                        <option value="Venezuela">Venezuela</option>
                                                        <option value="Viet Nam">Viet Nam</option>
                                                        <option value="Virgin Islands, British">Virgin Islands, British
                                                        </option>
                                                        <option value="Virgin Islands, U.S.">Virgin Islands, U.S.
                                                        </option>
                                                        <option value="Wallis and Futuna">Wallis and Futuna</option>
                                                        <option value="Western Sahara">Western Sahara</option>
                                                        <option value="Yemen">Yemen</option>
                                                        <option value="Zambia">Zambia</option>
                                                        <option value="Zimbabwe">Zimbabwe</option>
                                                    </select>
                                                    </div>
                                                </div>

                                                <div className="mb-3 col-xl-6 col-md-6">
                                                    <div className="d-flex align-items-center mb-3">
                                                        <img className="me-3 rounded-circle me-0 me-sm-3" draggable={false} src={image?image:populate?.profile_image ? populate?.profile_image : dummy} width="100" height="100" alt="profile_images" />
                                                        <div className="flex-grow-1 ml-2">
                                                            <h5 className="mb-0">{populate?.first_name === "null" ? 'N/A' :capitalizeFirstLowercaseRest(populate?.first_name) || 'N/A'}</h5>
                                                            <p className="mb-0">Max file size is 1mb</p>
                                                        </div>
                                                    </div>
                                                   
                                                    <div className="file-upload-wrapper" data-text="Change Photo">
                                                        <input name="profile_image" onChange={handleImageChange} type="file" className="file-upload-field"/>
                                                    </div>
                                                </div>
                                                <div className="mb-3 col-12">
                                                    <button type="submit" className="btn btn-success px-4">{updateProfile}</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OrderPlace/>
                </div>
            </div>
        </div>

            <Footer2 />
            </LoadingOverlay>
        </>
    )
}

export default Settings;