import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import BasicService from "./basicInfoService";

const initialState = {
    isLoading:false,
    isRegister:false,
    isLogin:false,
    registerDetail:"",
    loginDetail:"",
    isError:false,
    message:"",
    isForgotPassword:false,
    forgotDetail:"",
    resetPasswordDetail:"",
    isResetPassword:false,
    isChangedPassword:false,
    changedPasswordDetail:"",
    isUserProfile:false,
    ProfileDetail:"",
    isProfileGet:false,
    UserProfileGetting:"",
    allDepartment:"",
    isDepartmentCall:false,
    isMakeTicket:false,
    createTicketDetail:"",
    supportListing:"",
    isSupport:false,
    isThread:false,
    postThreadDetail:"",
    isAllThreadGet:false,
    getAllThreadSupport:"",
    isAdminThread:false,
    adminThreadDetail:"",
    isSupportTicket:false,
    getSupportTicketDetail:"",
    isVerifiedOtp:false,
    verificationDetail:"",
    isMultiFactor:false,
    multiFactorDetail:"",
    isTwoFactor:false,
    twoFactorDetail:"",
    isKyc:false,
    kycDetail:"",
    isKycForm:false,
    kycFormDetail:"",
    isBankDetail:false,
    bankDetail:"",
    isSubmitBankDetail:false,
    submitBankDetail:"",
    isCurrencyStatus:false,
    gettingCurrencyDetail:"",
    isKycAutoPopulate:false,
    kycPopulateData:"",
    isEditKyc:false,
    editKycDetail:"",
    isDepositBankByAdmin:"",
    DepositBankByAdmin:"",
    isCryptoCurrency:false,
    cryptoCurrencyDetail:"",
    isWalletAddress:"",
    WalletAddressDetail:"",
    isDepositStatus:false,
    depositDetail:"",
    isFiatDeposit:false,
    gettingFiatDepositDetail:"",
    isTransactionStatus:false,
    cryptoTransactionDetail:""
}


export const createRegister =createAsyncThunk ('post/signup',async (userData,thunkApi) => {
    try {
        return await BasicService.CryptoRegister(userData)
    } catch (error) {
        const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
    }
});

export const createLogin = createAsyncThunk ('post/login',async (userData,thunkApi) => {
    try {
        return await BasicService.makelogin(userData);
    } catch (error) {
        const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
    }
});
export const makeForgotPassword =createAsyncThunk("post/forgot",async (userData,thunkApi) => {
    try {
        return await BasicService.forgotPasswordMake(userData);
    } catch (error) {
        const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
    }
});
export const resetPassword = createAsyncThunk("post/resetPassword",async (userData,thunkApi) => {
    try {
        let token=userData.token;
       
        delete userData?.token
        return await BasicService.makerResetPassword(token,userData);
    } catch (error) {
        const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
    }
});

export const changePasswordUser = createAsyncThunk("post/changePassword",async (userData,thunkApi) => {
    try {
        let token=await JSON.parse(localStorage.getItem("authLogger"))
        console.log("getting token is here",token)
        const tokenDetails={headers:{Authorization:`Bearer ${token}`}}
        return await BasicService.makeChagePassword(tokenDetails,userData);
    } catch (error) {
        const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
    }
});

export const profileUser = createAsyncThunk("post/userProfile",async (userData,thunkApi) => {
    try {
        let token=await JSON.parse(localStorage.getItem("authLogger"))
        const tokenDetails={
            headers:{
                'Content-Type': 'multipart/form-data',
                Authorization:`Bearer ${token}`
            }
        }
        return await BasicService.UserProfile(tokenDetails,userData);
    } catch (error) {
        const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
    }
});

export const getUserProfile  = createAsyncThunk("post/authProfile",async (_,thunkApi) => {
    try {
        let token=await JSON.parse(localStorage.getItem("authLogger"))
        const tokenDetails={
            headers:{
                Authorization:`Bearer ${token}`
            }
        }
        return await BasicService.getAuthProfile(tokenDetails);
    } catch (error) {
        console.log("_________________________error is here**************",error)
        const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
    }
});

export const getDepartments  = createAsyncThunk("post/departments",async (_,thunkApi) => {
    try {
        return await BasicService.getAllDepartment();
    } catch (error) {
        console.log("_________________________error is here**************",error)
        const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
    }
});


export const createTicket = createAsyncThunk("post/makeTicket",async (userData,thunkApi) => {
    try {
        let token=await JSON.parse(localStorage.getItem("authLogger"))
        const tokenDetails={
            headers:{
                Accept:'application/json',
                Authorization:`Bearer ${token}`
            }
        }
        return await BasicService.createTicketSupport(tokenDetails,userData);
    } catch (error) {
        const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
    }
});

export const allSupportDetail  = createAsyncThunk("post/support",async (_,thunkApi) => {
    try {
        let token=await JSON.parse(localStorage.getItem("authLogger"))
        const tokenDetails={
            headers:{
                Authorization:`Bearer ${token}`
            }
        }
        return await BasicService.getListingSupport(tokenDetails);
    } catch (error) {
        console.log("_________________________error is here**************",error)
        const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
    }
});


//create thread is here
export const makeThreadMessages = createAsyncThunk("post/makeThread",async (userData,thunkApi) => {
    try {
        let token=await JSON.parse(localStorage.getItem("authLogger"))
        const tokenDetails={
            headers:{
                Authorization:`Bearer ${token}`
            }
        }
        return await BasicService.createThreadSupport(tokenDetails,userData);
    } catch (error) {
        const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
    }
});

//get all thread based on ticket id
export const getAllSupportedThread  = createAsyncThunk("post/getThreadDetail",async (userData,thunkApi) => {
    try {
        let token=await JSON.parse(localStorage.getItem("authLogger"))
        const tokenDetails={
            headers:{
                Authorization:`Bearer ${token}`
            }
        }
        return await BasicService.getAllThreadMessages(tokenDetails,userData);
    } catch (error) {
        console.log("_________________________error is here**************",error)
        const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
    }
});

//get all admin thread is here
export const getAllAdminThread  = createAsyncThunk("post/adminThread",async (userData,thunkApi) => {
    try {
        let token=await JSON.parse(localStorage.getItem("authLogger"))
        const tokenDetails={
            headers:{
                Authorization:`Bearer ${token}`
            }
        }
        return await BasicService.getAllAdminMessages(tokenDetails,userData);
    } catch (error) {
        console.log("_________________________error is here**************",error)
        const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
    }
});


//get all support theme is here
export const getAllSupportDetailForUser  = createAsyncThunk("post/allSupportTheme",async (userData,thunkApi) => {
    try {
        let token=await JSON.parse(localStorage.getItem("authLogger"))
        const tokenDetails={
            headers:{
                Authorization:`Bearer ${token}`
            }
        }
        return await BasicService.getAllSupportDetail(tokenDetails,userData);
    } catch (error) {
        const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
    }
});
export const verificationOtp = createAsyncThunk("post/verificationOtpForMulti",async (userData,thunkApi) => {
    try {
        let token=await JSON.parse(localStorage.getItem("authLogger"))
        const tokenDetails={
            headers:{
                Authorization:`Bearer ${token}`
            }
        }
        return await BasicService.verifyOtpForGoogleAuth(tokenDetails,userData);
    } catch (error) {
        const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
    }
});
export const showMultiFactorRecord = createAsyncThunk("post/showTwoFactor",async (userData,thunkApi) => {
    try {
        let token=await JSON.parse(localStorage.getItem("authLogger"))
        const tokenDetails={
            headers:{
                Authorization:`Bearer ${token}`
            }
        }
        return await BasicService.showMultiFactorData(tokenDetails,userData);
    } catch (error) {
        const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
    }
});


export const MultiFactorAuthenticate = createAsyncThunk("post/multiAuth",async (userData,thunkApi) => {
    try {
        let token=await JSON.parse(localStorage.getItem("authLogger"))
        const tokenDetails={
            headers:{
                Authorization:`Bearer ${token}`
            }
        }
        return await BasicService.postMultiFactorAuthenticate(tokenDetails,userData);
    } catch (error) {
        console.log(error)
        const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
    }
});

export const getListingOfKyc =createAsyncThunk ('post/gettingKyc',async (_,thunkApi) => {
    try {
        let token=await JSON.parse(localStorage.getItem("authLogger"))

        const tokenDetails={headers:{Authorization:`Bearer ${token}`}}
        return await BasicService.getKycDetail(tokenDetails);
    } catch (error) {
        const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
    }
});


export const makeCreateKycForm =createAsyncThunk ('post/makeKycForm',async (userData,thunkApi) => {
    try {
      
        let token=await JSON.parse(localStorage.getItem("authLogger"))
        const tokenDetails={
            headers:{
                Accept:'application/json',
                Authorization:`Bearer ${token}`
            }
        }
        return await BasicService.createKycForUser(tokenDetails,userData);
    } catch (error) {
        const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
    }
});

export const userCreateBankDetail =createAsyncThunk ('post/createBanking',async (userData,thunkApi) => {
    try {
        let token=await JSON.parse(localStorage.getItem("authLogger"))
        const tokenDetails={
            headers:{
                Accept:'application/json',
                Authorization:`Bearer ${token}`
            }
        }
        return await BasicService.createBankDetailForExchangeUser(userData,tokenDetails)
    } catch (error) {
        const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
    }
});



export const gettingBankingDetail =createAsyncThunk ('post/fetchBankingDetail',async (userData,thunkApi) => {
    try {
        let token=await JSON.parse(localStorage.getItem("authLogger"))
        const tokenDetails={
            headers:{
                Accept:'application/json',
                Authorization:`Bearer ${token}`
            }
        }
        return await BasicService.getBankingDetail(tokenDetails)
    } catch (error) {
        const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
    }
});



export const gettingFiatCurrencies =createAsyncThunk ('post/AllCurrencies',async (_,thunkApi) => {
    try {
        let token=await JSON.parse(localStorage.getItem("authLogger"))
        const tokenDetails={
            headers:{
                Accept:'application/json',
                Authorization:`Bearer ${token}`
            }
        }
        return await BasicService.getAllFiatCurrency(tokenDetails)
    } catch (error) {
        const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
    }
});


export const autoPopulateKycData =createAsyncThunk ('get/autoPopulate',async (userData,thunkApi) => {
    try {
        let token=await JSON.parse(localStorage.getItem("authLogger"))
        const tokenDetails={
            headers:{
                Accept:'application/json',
                Authorization:`Bearer ${token}`
            }
        }
        return await BasicService.autoPopulateKycField(tokenDetails)
    } catch (error) {
        const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
    }
});


//edit kyc for user
export const userEditKyc =createAsyncThunk ('post/editkyc',async (userData,thunkApi) => {
    try {
        let token=await JSON.parse(localStorage.getItem("authLogger"))
        const tokenDetails={
            headers:{
                Accept:'application/json',
                Authorization:`Bearer ${token}`
            }
        }
        return await BasicService.editKycUser(userData,tokenDetails)
    } catch (error) {
        const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
    }
});
export const gettingBankDetailForDepositAdmin =createAsyncThunk ('get/getBankDetailForAdminDeposit',async (_,thunkApi) => {
    try {
        let token=await JSON.parse(localStorage.getItem("authLogger"))
        const tokenDetails={
            headers:{
                Accept:'application/json',
                Authorization:`Bearer ${token}`
            }
        }
        return await BasicService.gettingBankDetailForAdmin(tokenDetails);
    } catch (error) {
        const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
    }
});

//get all crypto currency
export const gettingCryptoCurrencyForUser = createAsyncThunk('get/cryptoCurrency',async (_,thunkApi) => {
    try {
        let token=await JSON.parse(localStorage.getItem("authLogger"))
        const tokenDetails={
            headers:{
                Accept:'application/json',
                Authorization:`Bearer ${token}`
            }
        }
        return await BasicService.gettingCryptoCurrencyForDeposit(tokenDetails);
    } catch (error) {
        const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
    }
});

//consume the address from the wallet api is here
export const gettingAddressFromWalletApi = createAsyncThunk("post/gettingAddress", async (userData, thunkApi) => {
    try {
        let token=await JSON.parse(localStorage.getItem("authLogger"))
        const tokenDetails={
            headers:{
                Accept:'application/json',
                Authorization:`Bearer ${token}`
            }
        }
        return await BasicService.getWalletApiFetchedFromAddress(userData,tokenDetails);
    } catch (error) {
        const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
    }
});

export const createDepositForCryptoCurrencies = createAsyncThunk("post/createDeposit",async (userData,thunkApi) => {
    try {
        let token=await JSON.parse(localStorage.getItem("authLogger"))
        const tokenDetails={
            headers:{
                Accept:'application/json',
                Authorization:`Bearer ${token}`
            }
        }
        return await BasicService.createDepositForCryptoCurrency(userData,tokenDetails);
    } catch (error) {
        const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
    }
});

//getting all fiat currrencies deposit detail is here
export const gettingFiatDepositCurrenciesRecord = createAsyncThunk("get/gettingDeposit", async (_, thunkApi) => {
    try {
        let token=await JSON.parse(localStorage.getItem("authLogger"))
        const tokenDetails={
            headers:{
                Accept:'application/json',
                Authorization:`Bearer ${token}`
            }
        }
        return await BasicService.gettingDespositCurrencyBasedOnFiat(tokenDetails);
    } catch (error) {
        const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
    }
});

export const gettingTransactionForCryptoCurrency = createAsyncThunk("post/gettingTransaction",async (userData,thunkApi) => {
    try {
        let token=await JSON.parse(localStorage.getItem("authLogger"))
        const tokenDetails={
            headers:{
                Accept:'application/json',
                Authorization:`Bearer ${token}`
            }
        }
        return await BasicService.transactionBasedOnCrypotoCurrency(userData,tokenDetails);
    } catch (error) {
        const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
    }
});




export const BasicReducer=createSlice({
    name:'basic-info',
    initialState,
    reducers: {
        logout:(state) => {
            state.isLoading=false
            state.isRegister=false
            state.isLogin=false
            state.registerDetail=""
            state.loginDetail=""
            state.isError=false
            state.message=""
            state.isChangedPassword=false
            state.changedPasswordDetail=""
            state.isResetPassword=""
            state.resetPasswordDetail=""
            state.isForgotPassword=false
            state.forgotDetail=""
            state.isUserProfile=false
            state.ProfileDetail=""
            state.isProfileGet=false
            state.UserProfileGetting=""
            state.isDepartmentCall=false
            state.allDepartment=""
            state.isMakeTicket=false
            state.createTicketDetail=""
            state.supportListing=""
            state.isSupport=false
            state.isThread=false
            state.postThreadDetail=""
            state.isAllThreadGet=false
            state.getAllThreadSupport=""
            state.isAdminThread=false
            state.adminThreadDetail=""
            state.isSupportTicket=false
            state.getSupportTicketDetail=""
            state.isVerifiedOtp=false
            state.verificationDetail=""
            state.isMultiFactor=false
            state.multiFactorDetail=""
            state.isTwoFactor=false
            state.twoFactorDetail=""
            state.isKyc=false
            state.kycDetail=""
            state.isBankDetail=false
            state.bankDetail=""
            state.isSubmitBankDetail=false
            state.submitBankDetail=""
            state.isCurrencyStatus=false
            state.gettingCurrencyDetail=""
            state.isKycAutoPopulate=false
            state.kycPopulateData=""
            state.isEditKyc=false
            state.editKycDetail=""
            state.isDepositBankByAdmin=""
            state.DepositBankByAdmin=""
            state.isCryptoCurrency=false
            state.cryptoCurrencyDetail=""
            state.isWalletAddress=""
            state.WalletAddressDetail=""
            state.isDepositStatus=false
            state.depositDetail=""
            state.isFiatDeposit=false
            state.gettingFiatDepositDetail=""
            state.isTransactionStatus=false
            state.cryptoTransactionDetail=""
        }
    },
    extraReducers:(builder) => {
        builder.addCase(createRegister.pending,(state,action) => {
            state.isLoading=true
            state.isRegister=false
            state.isError=action.payload
            state.message=""
        }).addCase(createRegister.fulfilled,(state,action) => {
            state.isRegister=true
            state.isLoading=false
            state.registerDetail=action.payload
            state.message="User Register has been successfully"
        }).addCase(createRegister.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(createLogin.pending,(state,action) => {
            state.isLogin=false
            state.isLoading=false
            state.message=""
            state.loginDetail=""
            state.isError=action.payload
        }).addCase(createLogin.fulfilled,(state,action) => {
            state.isLoading=false
            state.isLogin=true
            state.loginDetail=action.payload
            state.message="User has been login successfully"
        }).addCase(createLogin.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(makeForgotPassword.pending,(state,action) => {
            state.isForgotPassword=true
            state.isLoading=false
            state.isError=action.payload
        }).addCase(makeForgotPassword.fulfilled,(state,action) => {
            state.isLoading=false
            state.isForgotPassword=true
            state.forgotDetail=action.payload
            state.message="Password has been forgot successfully"
        }).addCase(makeForgotPassword.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(resetPassword.pending,(state,action) => {
            state.isResetPassword=true
            state.isLoading=false
            state.isError=action.payload
        }).addCase(resetPassword.fulfilled,(state,action) => {
            state.isLoading=false
            state.isResetPassword=true
            state.resetPasswordDetail=action.payload
            state.message="Password has been reset successfully"
        }).addCase(resetPassword.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(changePasswordUser.pending,(state,action) => {
            state.isChangedPassword=true
            state.isLoading=false
            state.isError=action.payload
            state.message=""
        }).addCase(changePasswordUser.fulfilled,(state,action) => {
            state.isLoading=false
            state.isChangedPassword=true
            state.changedPasswordDetail=action.payload
            state.message="Password has been changed successfully"
        }).addCase(changePasswordUser.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(profileUser.pending,(state,action) => {
            state.isUserProfile=false
            state.isLoading=false
            state.isError=action.payload
            state.message=""
        }).addCase(profileUser.fulfilled,(state,action) => {
            state.isLoading=false
            state.isUserProfile=true
            state.ProfileDetail=action.payload
            state.message="User profile has been updated successfully"
        }).addCase(profileUser.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(getUserProfile.pending,(state,action) => {
            state.isProfileGet=false
            state.isLoading=false
            state.isError=action.payload
            state.message=""
        }).addCase(getUserProfile.fulfilled,(state,action) => {
            state.isLoading=false
            state.isProfileGet=true
            state.UserProfileGetting=action.payload
            state.message="User profile has been getting successfully"
        }).addCase(getUserProfile.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(getDepartments.pending,(state,action) => {
            state.isDepartmentCall=false
            state.isLoading=false
            state.isError=action.payload
            state.message=""
        }).addCase(getDepartments.fulfilled,(state,action) => {
            state.isLoading=false
            state.isDepartmentCall=true
            state.allDepartment=action.payload
            state.message="All department has been fetched"
        }).addCase(getDepartments.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(createTicket.pending,(state,action) => {
            state.isMakeTicket=false
            state.isLoading=false
            state.isError=action.payload
            state.message=""
        }).addCase(createTicket.fulfilled,(state,action) => {
            state.isLoading=false
            state.isMakeTicket=true
            state.createTicketDetail=action.payload
            state.message="Ticket has been created successfully"
        }).addCase(createTicket.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(allSupportDetail.pending,(state,action) => {
            state.isSupport=false
            state.isLoading=false
            state.isError=action.payload
            state.message=""
        }).addCase(allSupportDetail.fulfilled,(state,action) => {
            state.isLoading=false
            state.isSupport=true
            state.supportListing=action.payload
            state.message="supporting listing get Successfully"
        }).addCase(allSupportDetail.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(makeThreadMessages.pending,(state,action) => {
            state.isThread=false
            state.isLoading=false
            state.isError=action.payload
            state.message=""
        }).addCase(makeThreadMessages.fulfilled,(state,action) => {
            state.isLoading=false
            state.isThread=true
            state.postThreadDetail=action.payload
            state.message="thread has been created successfully"
        }).addCase(makeThreadMessages.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(getAllSupportedThread.pending,(state,action) => {
            state.isAllThreadGet=false
            state.isLoading=false
            state.isError=action.payload
            state.message=""
        }).addCase(getAllSupportedThread.fulfilled,(state,action) => {
            state.isLoading=false
            state.isAllThreadGet=true
            state.getAllThreadSupport=action.payload
            state.message="thread has fetched successfully"
        }).addCase(getAllSupportedThread.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(getAllAdminThread.pending,(state,action) => {
            state.isAdminThread=false
            state.isLoading=false
            state.isError=action.payload
            state.message=""
        }).addCase(getAllAdminThread.fulfilled,(state,action) => {
            state.isLoading=false
            state.isAdminThread=true
            state.adminThreadDetail=action.payload
            state.message="admin thread has fetched successfully"
        }).addCase(getAllAdminThread.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(getAllSupportDetailForUser.pending,(state,action) => {
            state.isSupportTicket=false
            state.isLoading=false
            state.isError=action.payload
            state.message=""
        }).addCase(getAllSupportDetailForUser.fulfilled,(state,action) => {   
            state.isLoading=false
            state.isSupportTicket=true
            state.getSupportTicketDetail=action.payload
            state.message="Support ticket fetched successfully"
        }).addCase(getAllSupportDetailForUser.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(verificationOtp.pending,(state,action) => {
            state.isVerifiedOtp=false
            state.isLoading=false
            state.isError=action.payload
            state.message=""
        }).addCase(verificationOtp.fulfilled,(state,action) => {   
            state.isLoading=false
            state.isVerifiedOtp=true
            state.verificationDetail=action.payload
            state.message="Otp has been verified Successfully"
        }).addCase(verificationOtp.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(showMultiFactorRecord.pending,(state,action) => {
            state.isMultiFactor=false
            state.isLoading=false
            state.isError=action.payload
            state.message=""
        }).addCase(showMultiFactorRecord.fulfilled,(state,action) => {   
            state.isLoading=false
            state.isMultiFactor=true
            state.multiFactorDetail=action.payload
            state.message="Multi factor auth successfully"
        }).addCase(showMultiFactorRecord.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(MultiFactorAuthenticate.pending,(state,action) => {
            state.isTwoFactor=false
            state.isLoading=false
            state.isError=action.payload
            state.message=""
        }).addCase(MultiFactorAuthenticate.fulfilled,(state,action) => {   
            state.isLoading=false
            state.isTwoFactor=true
            state.twoFactorDetail=action.payload
            state.message="Multi factor authentication has been successfully"
        }).addCase(MultiFactorAuthenticate.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(getListingOfKyc.pending,(state,action) => {
            state.isKyc=false
            state.isLoading=false
            state.isError=action.payload
            state.message=""
        }).addCase(getListingOfKyc.fulfilled,(state,action) => {   
            state.isLoading=false
            state.isKyc=true
            state.kycDetail=action.payload
            state.message="kyc Listing has been fetched successfully"
        }).addCase(getListingOfKyc.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(makeCreateKycForm.pending,(state,action) => {
            state.isKycForm=false
            state.isLoading=false
            state.isError=action.payload
            state.message=""
        }).addCase(makeCreateKycForm.fulfilled,(state,action) => {   
            state.isLoading=false
            state.isKycForm=true
            state.kycFormDetail=action.payload
            state.message="kyc form has been submit successfully"
        }).addCase(makeCreateKycForm.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(userCreateBankDetail.pending,(state,action) => {
            state.isSubmitBankDetail=false
            state.isLoading=false
            state.isError=action.payload
            state.message=""
        }).addCase(userCreateBankDetail.fulfilled,(state,action) => {   
            state.isLoading=false
            state.isSubmitBankDetail=true
            state.submitBankDetail=action.payload
            state.message="Bank detail has been created successfully"
        }).addCase(userCreateBankDetail.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(gettingBankingDetail.pending,(state,action) => {
            state.isBankDetail=false
            state.isLoading=false
            state.isError=action.payload
            state.message=""
        }).addCase(gettingBankingDetail.fulfilled,(state,action) => {
            state.isLoading=false
            state.isBankDetail=true
            state.bankDetail=action.payload
            state.message="Bank detail fetched successfully"
        }).addCase(gettingBankingDetail.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(gettingFiatCurrencies.pending,(state,action) => {
            state.isCurrencyStatus=false
            state.isLoading=false
            state.isError=action.payload
            state.message=""
        }).addCase(gettingFiatCurrencies.fulfilled,(state,action) => {
            state.isLoading=false
            state.isCurrencyStatus=true
            state.gettingCurrencyDetail=action.payload
            state.message="Currency has been fetched successfully"
        }).addCase(gettingFiatCurrencies.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(autoPopulateKycData.pending,(state,action) => {
            state.isKycAutoPopulate=false
            state.isLoading=false
            state.isError=action.payload
            state.message=""
        }).addCase(autoPopulateKycData.fulfilled,(state,action) => {
            state.isLoading=false
            state.isKycAutoPopulate=true
            state.kycPopulateData=action.payload
            state.message="kyc populate data fetched successfully"
        }).addCase(autoPopulateKycData.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(userEditKyc.pending,(state,action) => {
            state.isEditKyc=false
            state.isLoading=false
            state.isError=action.payload
            state.message=""
        }).addCase(userEditKyc.fulfilled,(state,action) => {
            state.isLoading=false
            state.isEditKyc=true
            state.editKycDetail=action.payload
            state.message="kyc has been updated successfully"
        }).addCase(userEditKyc.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(gettingBankDetailForDepositAdmin.pending,(state,action) => {
            state.isDepositBankByAdmin=false
            state.isLoading=false
            state.isError=action.payload
            state.message=""
        }).addCase(gettingBankDetailForDepositAdmin.fulfilled,(state,action) => {
            state.isLoading=false
            state.isDepositBankByAdmin=true
            state.DepositBankByAdmin=action.payload
            state.message="deposit bank detail has been fetched successfully"
        }).addCase(gettingBankDetailForDepositAdmin.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(gettingCryptoCurrencyForUser.pending,(state,action) => {
            state.isCryptoCurrency=false
            state.isLoading=false
            state.isError=action.payload
            state.message=""
        }).addCase(gettingCryptoCurrencyForUser.fulfilled,(state,action) => {
            state.isLoading=false
            state.isCryptoCurrency=true
            state.cryptoCurrencyDetail=action.payload
            state.message="crypto currency has been fetched successfully"
        }).addCase(gettingCryptoCurrencyForUser.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(gettingAddressFromWalletApi.pending,(state,action) => {
            state.isWalletAddress=false
            state.isLoading=false
            state.isError=action.payload
            state.message=""
        }).addCase(gettingAddressFromWalletApi.fulfilled,(state,action) => {
            state.isLoading=false
            state.isWalletAddress=true
            state.WalletAddressDetail=action.payload
            state.message="wallet address has been fetched successfully"
        }).addCase(gettingAddressFromWalletApi.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(createDepositForCryptoCurrencies.pending,(state,action) => {
            state.isDepositStatus=false
            state.isLoading=false
            state.isError=action.payload
            state.message=""
        }).addCase(createDepositForCryptoCurrencies.fulfilled,(state,action) => {
            state.isLoading=false
            state.isDepositStatus=true
            state.depositDetail=action.payload
            state.message="Deposit has been created successfully"
        }).addCase(createDepositForCryptoCurrencies.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(gettingFiatDepositCurrenciesRecord.pending,(state,action) => {
            state.isFiatDeposit=false
            state.isLoading=false
            state.isError=action.payload
            state.message=""
        }).addCase(gettingFiatDepositCurrenciesRecord.fulfilled,(state,action) => {
            state.isLoading=false
            state.isFiatDeposit=true
            state.gettingFiatDepositDetail=action.payload
            state.message=""
        }).addCase(gettingFiatDepositCurrenciesRecord.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(gettingTransactionForCryptoCurrency.pending,(state,action) => {
            state.isTransactionStatus=false
            state.isLoading=false
            state.isError=action.payload
            state.message=""
        }).addCase(gettingTransactionForCryptoCurrency.fulfilled,(state,action) => {
            state.isLoading=false
            state.isTransactionStatus=true
            state.cryptoTransactionDetail=action.payload
            state.message=""
        }).addCase(gettingTransactionForCryptoCurrency.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
            
        })
    }
});

//
// isTransactionStatus:false,
// cryptoTransactionDetail:""

export const {logout}=BasicReducer.actions;

export default BasicReducer.reducer;
