import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import PlaceOrderService from "./orderMachingService";

//set intialized on fullfilled state
const initialState = {
	isLoading:false,
	isError:false,
	orderPlaceDetail:"",
	isOrderPlaceStatus:false,
	message:"",
	isOrderCancel:false,
	orderCancelDetail:"",
	isOrderBookStatus:false,
	getOrderBookDetail:"",
	isOrderHistoryStatus:false,
	orderHistoryDetail:"",
	isAllCurrenciesPairListing:false,
	getAllCurrenciesPair:"",
	isPairCurrencyTabStatus:false,
	getPairCurrencyTabDetail:"",
	isBuyOrderStatus:false,
	getBuyActiveOrder:"",
	isSellOrderStatus:false,
	getSellOrderDetail:"",
	isInnerHeaderOrderStatus:false,
	getInnerHeaderOrderDetail:"",
	isUserBalance:false,
	userBalanceDetail:"",
	isRunningPairId:false,
	runningPairIdDetail:""
}

//order would be placed for user end
export const orderPlaceForExchange = createAsyncThunk("post/placeOrder",async (userData,thunkApi) => {
	try{
		let token=await JSON.parse(localStorage.getItem("authLogger"))
        const tokenDetails={headers:{Authorization:`Bearer ${token}`}}
		return await PlaceOrderService.placeOrderForExchangeOrder(tokenDetails,userData)
	} catch(error){
		const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
	}
});

//this will fire when user want to order cancel
export const cancelOrderExchange = createAsyncThunk("post/cancelOrder",async (userData,thunkApi) => {
	try{
		let token=await JSON.parse(localStorage.getItem("authLogger"))
        const tokenDetails={headers:{Authorization:`Bearer ${token}`}}
		return await PlaceOrderService.cancelOrderForExchange(tokenDetails,userData)
	} catch(error){
		const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
	}
});

//get Order book based on multiple cases
export const getDetailForOrderBook = createAsyncThunk("post/orderBook",async (userData,thunkApi) => {
	try{
		let token=await JSON.parse(localStorage.getItem("authLogger"))
        const tokenDetails={headers:{Authorization:`Bearer ${token}`}}
		return await PlaceOrderService.getOrderBookForExchange(tokenDetails,userData)
	} catch(error){
		const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
	}
});

//get Order book based on multiple cases
export const getOrderHistoryByPairId = createAsyncThunk("post/orderHistory",async (userData,thunkApi) => {
	try{
		// let token=await JSON.parse(localStorage.getItem("authLogger"))
        // const tokenDetails={headers:{Authorization:`Bearer ${token}`}}
		return await PlaceOrderService.getTopOrderHistoryShow(userData)
	} catch(error){
		const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
	}
});


//get Order book based on multiple cases
export const getAllCurrenciesByPair = createAsyncThunk("post/allCurrenceisPair",async (userData,thunkApi) => {
	try{
		return await PlaceOrderService.getAllCurrenciesByPairs(userData);
	} catch(error){
		const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
	}
});

//get pair currency tabs here
export const getTabAllCurrencyPair = createAsyncThunk("get/allTabCurrenceisPair",async (_,thunkApi) => {
	try{
		return await PlaceOrderService.getAllPairCurrenciesTabs();
	} catch(error){
		const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
	}
});

//get top ten active order whose type is buy and order by asc
export const getActiveTopTenBuyOrder = createAsyncThunk("get/topTenBuyOrders",async (userData,thunkApi) => {
	try{
		return await PlaceOrderService.getActiveOrderByTopTen(userData);
	} catch(error){
		const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
	}
});

//get top ten active order whose type is sell and order by DESC
export const getActiveTopTenSellOrder = createAsyncThunk("get/topTenSellOrders",async (userData,thunkApi) => {
	try{
		return await PlaceOrderService.getActiveSellOrderByTopTen(userData);
	} catch(error){
		const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
	}
});

//get inner header order detai lis her
export const getInnerHeaderDetailExchange = createAsyncThunk("get/getInnerHeaderOrderDetail",async (userData,thunkApi) => {
	try{
		return await PlaceOrderService.getInnerHeaderDetailForExchange(userData);
	} catch(error){
		const message=error.data?.data || error.data || error?.data?.message || error.string();
        return thunkApi.rejectWithValue(message);
	}
});

//get user balance along with currencies
export const getUserBalanceAlongWithCurrenciesDetail = createAsyncThunk("get/balanceDetailWithCurrenciesDetail", async (_,thunkApi) => {
	try {
		let token=await JSON.parse(localStorage.getItem("authLogger"))
        const tokenDetails={headers:{Authorization:`Bearer ${token}`}}
		return await PlaceOrderService.getUserBalanceByUser(tokenDetails);	
	} catch (error) {
		const message = error.data?.data || error.data || error?.data.message || error.string();
		return thunkApi.rejectWithValue(message);
	} 
});

export const getDefaultFlagId = createAsyncThunk("get/getDefaultFlagId", async (_,thunkApi) => {
	try {
		let token=await JSON.parse(localStorage.getItem("authLogger"))
        const tokenDetails={headers:{Authorization:`Bearer ${token}`}}
		return await PlaceOrderService.getDefaultRunningPairId(tokenDetails);	
	} catch (error) {
		const message = error.data?.data || error.data || error?.data.message || error.string();
		return thunkApi.rejectWithValue(message);
	}
});

//make an slice for reducer and advance reducer
export const OrderPlaceReducer=createSlice({
	name:'order-place',
	initialState,
	reducers:{
		logout:(state) => {
			state.isLoading=false
			state.isError=false
			state.orderPlaceDetail=""
			state.isOrderPlaceStatus=false
			state.message=""
			state.isOrderCancel=false
			state.orderCancelDetail=""
			state.isOrderBookStatus=false
			state.getOrderBookDetail=""
			state.isOrderHistoryStatus=false
			state.orderHistoryDetail=""
			state.isAllCurrenciesPairListing=false
			state.getAllCurrenciesPair=""
			state.isPairCurrencyTabStatus=false
			state.getPairCurrencyTabDetail=""
			state.isBuyOrderStatus=false
			state.getBuyActiveOrder=""
			state.isSellOrderStatus=false
			state.getSellOrderDetail=""
			state.isInnerHeaderOrderStatus=false
			state.getInnerHeaderOrderDetail=""
			state.isUserBalance=false
			state.userBalanceDetail=""
			state.isRunningPairId=false
			state.runningPairIdDetail=""
		}
	},
	extraReducers:(builder) => {
		builder.addCase(orderPlaceForExchange.pending,(state,action) => {
			state.isLoading=true
			state.isOrderPlaceStatus=false
			state.isError=action.payload
			state.message=""
		}).addCase(orderPlaceForExchange.fulfilled,(state,action) => {
            state.isOrderPlaceStatus=true
            state.isLoading=false
            state.orderPlaceDetail=action.payload
            state.message="order has been placed successfully"
        }).addCase(orderPlaceForExchange.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(cancelOrderExchange.pending,(state,action) => {
			state.isLoading=true
			state.isOrderCancel=false
			state.isError=action.payload
			state.message=""
		}).addCase(cancelOrderExchange.fulfilled,(state,action) => {
            state.isOrderCancel=true
            state.isLoading=false
            state.orderCancelDetail=action.payload
            state.message="order has been cancelled successfully"
        }).addCase(cancelOrderExchange.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(getDetailForOrderBook.pending,(state,action) => {
			state.isLoading=true
			state.isOrderBookStatus=false
			state.getOrderBookDetail=""
			state.isError=action.payload
			state.message=""
		}).addCase(getDetailForOrderBook.fulfilled,(state,action) => {
            state.isOrderBookStatus=true
            state.isLoading=false
            state.getOrderBookDetail=action.payload
            state.message="order book has been fetched successfully"
        }).addCase(getDetailForOrderBook.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(getOrderHistoryByPairId.pending,(state,action) => {
			state.isLoading=true
			state.isOrderHistoryStatus=false
			state.isError=action.payload
			state.message=""
		}).addCase(getOrderHistoryByPairId.fulfilled,(state,action) => {
            state.isOrderHistoryStatus=true
            state.isLoading=false
            state.orderHistoryDetail=action.payload
            state.message="order history has been fetched successfully"
        }).addCase(getOrderHistoryByPairId.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(getAllCurrenciesByPair.pending,(state,action) => {
			state.isLoading=true
			state.isAllCurrenciesPairListing=false
			state.isError=action.payload
			state.getAllCurrenciesPair=""
			state.message=""
		}).addCase(getAllCurrenciesByPair.fulfilled,(state,action) => {
            state.isAllCurrenciesPairListing=true
            state.isLoading=false
            state.getAllCurrenciesPair=action.payload
            state.message="All pair currencies fetched successfully"
        }).addCase(getAllCurrenciesByPair.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(getTabAllCurrencyPair.pending,(state,action) => {
			state.isLoading=true
			state.isPairCurrencyTabStatus=false
			state.isError=action.payload
			state.message=""
		}).addCase(getTabAllCurrencyPair.fulfilled,(state,action) => {
            state.isPairCurrencyTabStatus=true
            state.isLoading=false
            state.getPairCurrencyTabDetail=action.payload
            state.message="All Tab pair currencies fetched successfully"
        }).addCase(getTabAllCurrencyPair.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(getActiveTopTenBuyOrder.pending,(state,action) => {
			state.isLoading=true
			state.isBuyOrderStatus=false
			state.isError=action.payload
			state.message=""
		}).addCase(getActiveTopTenBuyOrder.fulfilled,(state,action) => {
            state.isBuyOrderStatus=true
            state.isLoading=false
            state.getBuyActiveOrder=action.payload
            state.message="Top ten buy active orders fetched successfully"
        }).addCase(getActiveTopTenBuyOrder.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(getActiveTopTenSellOrder.pending,(state,action) => {
			state.isLoading=true
			state.isSellOrderStatus=false
			state.isError=action.payload
			state.message=""
		}).addCase(getActiveTopTenSellOrder.fulfilled,(state,action) => {
            state.isSellOrderStatus=true
            state.isLoading=false
            state.getSellOrderDetail=action.payload
            state.message="Top ten sell active orders fetched successfully"
        }).addCase(getActiveTopTenSellOrder.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(getInnerHeaderDetailExchange.pending,(state,action) => {
			state.isLoading=true
			state.isInnerHeaderOrderStatus=false
			state.isError=action.payload
			state.message=""
		}).addCase(getInnerHeaderDetailExchange.fulfilled,(state,action) => {
            state.isInnerHeaderOrderStatus=true
            state.isLoading=false
            state.getInnerHeaderOrderDetail=action.payload
            state.message="Market fetched along with pair detail"
        }).addCase(getInnerHeaderDetailExchange.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        }).addCase(getUserBalanceAlongWithCurrenciesDetail.pending,(state,action) => {
			state.isLoading=true
			state.isUserBalance=false
			state.isError=action.payload
			state.message=""
		}).addCase(getUserBalanceAlongWithCurrenciesDetail.fulfilled,(state,action) => {
            state.isUserBalance=true
            state.isLoading=false
            state.userBalanceDetail=action.payload
            state.message="User balance fetched successfully"
        }).addCase(getUserBalanceAlongWithCurrenciesDetail.rejected,(state,action) => {
            state.isLoading=true
            state.isError=action.payload
        })
	} 
}); 



export const {logout}=OrderPlaceReducer.actions;
//call reducer export for going to root reducer...
export default OrderPlaceReducer.reducer;