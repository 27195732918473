import React, { useRef } from "react";
import Header2 from "../../layout/header2";
import Sidebar from "../../layout/sidebar";
import PageTitle from "../../element/page-title";
import ToasterContainer from "../../component/ToasterContainer";
import { capitalizeFirstLowercaseRest } from "../../component/helper";
import dummy from "../../../util/images/dummy.png"
import BootstrapTable from "react-bootstrap-table-next";
import useDocumentTitle from "../../../helper/useTitle"
import { useState } from "react";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from "react-router-dom";
import { gettingFiatCurrencies, gettingCryptoCurrencyForUser } from "../../../store/BasicInfo/basicInfoSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Inr from "../../../util/images/INR.png"
import OrderPlace from "../../component/placeOrder/orderPlace";

export default function Funds() {
    useDocumentTitle('Funds | Exchange')
    const [userData, setUserData] = useState("");
    const [cryptoData, setCryptoData] = useState("")
    const dispatch = useDispatch();
    const { isCurrencyStatus, gettingCurrencyDetail, isCryptoCurrency, cryptoCurrencyDetail } = useSelector((state) => state.basic);

    //hitting the apis for getting crypto currencies
    useEffect(() => {
        dispatch(gettingCryptoCurrencyForUser())
    },[])

    //hitting the apis for getting fiat currencies
    useEffect(() => {
        dispatch(gettingFiatCurrencies())
    }, []);

    //fill the record to the state based on fiat currency
    useEffect(() => {
        if (isCurrencyStatus && gettingCurrencyDetail) {
            setUserData(gettingCurrencyDetail?.data)
        }
    }, [isCurrencyStatus, gettingCurrencyDetail]);

    //fill the record to the state based on crypto currency
    useEffect(() => {
        if(isCryptoCurrency && cryptoCurrencyDetail) {
            setCryptoData(cryptoCurrencyDetail?.data)
        }
    },[isCryptoCurrency,cryptoCurrencyDetail]) 

    const columns = [
        {
            dataField: "name",
            text: "Currency",
            sort: true,
            formatter: (cell, row) => {
                return (
                    <>
                        <div className="currency-group">
                            <img src={Inr} alt="" height="" width="" className="mr-2" />
                            <span class="mr-1">{row?.symbol ? row?.symbol : 'N/A'}</span>
                            <small>{row?.name ? row?.name : 'N/A'}</small>
                        </div>
                    </>
                )
                //return (<>{cell ? cell : 'N/A'}</>)
            }
        },
        {
            dataField: "amount",
            text: "Amount",
            formatter: (cell, row) => {
                return <>{row?.UserBalance?.amount ? row?.UserBalance?.amount + (row?.symbol ? " " + row?.symbol : '') : 'N/A'}</>
            }
        },
        {
            dataField: "",
            text: "",
            headerFormatter: () => {
                return (<span>Action</span>);
            },
            formatter: (data, allData) => {
                return (
                    <>
                    <Link to={{ pathname:`/deposit/${allData?.symbol}` }} state={{currency_id:allData?.id}} >Deposit</Link>
                    {/* <Link to={`/deposit/${allData?.symbol}`} >History</Link> */}
                    </>
                )
            }
        }
    ];
     
    const columns1 = [
        {
            dataField: "name",
            text: "Currency",
            sort: true,
            formatter: (cell, row) => {
                return (
                    <>
                        <div className="currency-group">
                            <img src={Inr} alt="" height="" width="" className="mr-2" />
                            <span class="mr-1">{row?.symbol ? row?.symbol : 'N/A'}</span>
                            <small>{row?.name ? row?.name : 'N/A'}</small>
                        </div>
                    </>
                )
                //return (<>{cell ? cell : 'N/A'}</>)
            }
        },
        {
            dataField: "amount",
            text: "Amount",
            formatter: (cell, row) => {
                return <>{row?.UserBalance?.amount ? row?.UserBalance?.amount + (row?.symbol ? " " + row?.symbol : '') : 'N/A'}</>
            }
        },
        {
            dataField: "",
            text: "",
            headerFormatter: () => {
                return (<span>Action</span>);
            },
            formatter: (data, allData) => {
                return (
                    <><Link to={'/accountDepositCrypto'} state={{currency_id:allData}}>Deposit</Link></>
                )
            }
        }
    ];

    return (
        <>
            <Header2 />
            <Sidebar />
            <PageTitle />
            <ToasterContainer />
            <div className="content-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-9">
                            <div class="row">
                            <div className="col-md-12 mt-4 mb-5" id="dashboard">
                                <h5 style={{ color: '#5d78ff' }}>Fiat</h5>
                                <div className="open-position-table" style={{height:"auto"}}>
                                    
                                    {
                                        // isLoading ? (<div className="custom-loading-func"><LoadingOverlay style={{position:'reset'}} className="custom-loading" active={true} spinner text='Loading...'></LoadingOverlay> </div>):
                                        <BootstrapTable keyField='id' classes="custom-ticket-code" noDataIndication={() => { return 'No Data Found' }} columns={columns} data={userData && userData} bordered={false} />
                                    }
                                </div>
                            </div>

                             <div class="col-md-12">
                            <h5 style={{ color: '#5d78ff' }}>Crypto</h5>
                            <div className="open-position-table">
                                {
                                    // isLoading ? (<div className="custom-loading-func"><LoadingOverlay style={{position:'reset'}} className="custom-loading" active={true} spinner text='Loading...'></LoadingOverlay> </div>):
                                    <BootstrapTable keyField='id' classes="custom-ticket-code" noDataIndication={() => { return 'No Data Found' }} columns={columns1} data={cryptoData && cryptoData} bordered={false} pagination={cryptoData && paginationFactory({
                                        page: 1,
                                        sizePerPage: 10,
                                        firstPageText: '>>',
                                        nextPageText: '<<',
                                        prePageText: '<',
                                        showTotal: true,
                                        alwaysShowAllBtns: true,
                                        onPageChange: function (page, sizePerPage) {
                                            console.log("page is here", page)
                                        },
                                        onSizePerPageChange: function (page, sizePerPage) {
                                            console.log("page", page)
                                        }
                                    })} />
                                }
                            </div>
                            </div>
                            </div>
                            
                        </div>
                        
                            <OrderPlace/>
                        
                        
                    </div>
                </div>
            </div>

           
            
        </>
    )
}