import axios from "axios";
import { BASE_URL_URL, register, login, forgotPassword, resetPassword, changePassword, editProfile, getProfile, getAllSupportDepartment, getAllSupport, createTicket, createThread, getAllThread, getAdminThread, verifyOtp,multiAuth,getMultiAuth, getKyc, createKyc, getBankDetail, createBankDetail, getFiatCurrencies, getCryptoCurrencies , autoPopulateKyc, editKyc, gettingBankDetailByAdmin, saveCryptoDepositAddress, createDeposit, gettingDeposit, gettingTransactionBasedOnCurrency } from "../../util/constant";
import { toast } from "react-toastify"

const CryptoRegister = async (userData) => {
    try {
        const response = await axios.post(BASE_URL_URL + register, userData);
        if (response) {
            toast.success(response?.data?.message)
        }
        return response?.data;
    } catch (error) {
        if (error) {
            toast.error(error?.response?.data?.message)
        }
        throw error;
    }
}
const makelogin = async (userData) => {
    try {
        const response = await axios.post(BASE_URL_URL + login, userData);     
        if (response?.data?.data?.token) {
            localStorage.setItem("authLogger", JSON.stringify(response?.data?.data?.token))
            toast.success(response?.data?.message)
        }
        return response?.data;
    } catch (error) {
        console.log("________________________________________Error", error);
        if (error) {
            toast.error(error?.response?.data?.message)
        }
        throw error;
    }
}

const forgotPasswordMake = async (userData) => {
    try {
        const response = await axios.post(BASE_URL_URL + forgotPassword, userData);
      
        if (response) {
            toast.success(response?.data?.message)
        }
        return response?.data;
    } catch (error) {
        console.log("________________________________________Error", error);
        if (error) {
            toast.error(error?.response?.data?.message)
        }
        throw error;
    }
}
const makerResetPassword = async (token, userData) => {
    try {
        const response = await axios.post(BASE_URL_URL + resetPassword + "/" + token, userData);
        if (response) {
            toast.success(response?.data?.message);
        }
        return response?.data;
    } catch (error) {
        if (error) {
            toast.error(error?.response?.data?.message)
        }
        console.log("_______________________________________error", error);
        throw error;
    }
}


const makeChagePassword = async (tokenDetail, userData) => {
    try {
        const response = await axios.post(BASE_URL_URL + changePassword, userData, tokenDetail);
        if (response) {
            toast.success(response?.data?.message)
        }
        return response?.data;
    } catch (error) {
        console.log("________________________________________Error", error);
        if (error) {
            toast.error(error?.response?.data?.message)
        }
        throw error;
    }
}
const UserProfile = async (tokenDetail, userData) => {
    try {
        let formData = new FormData();
        formData.append("username", userData.username);
        // formData.append("email", userData.email);
        formData.append("date_of_birth", userData.date_of_birth);
        formData.append("present_address", userData.present_address);
        formData.append("permanent_address", userData.permanent_address);
        formData.append("city", userData.city);
        formData.append("postel_code", userData.postel_code);
        formData.append("country", userData.country);
        formData.append("profile_image", userData.profile_image);
     
        const response = await axios.post(BASE_URL_URL + editProfile, formData, tokenDetail);
     
        if (response) {
            toast.success(response.data.message)
        }
        return response?.data;
    } catch (error) {
        console.log("___________________________________________________error", error);
        throw (error);
    }
}
const getAuthProfile = async (tokenDetail) => {
    try {
        const response = await axios.get(BASE_URL_URL + getProfile, tokenDetail);
        return response?.data;
    } catch (error) { 
        throw (error);
    }
}

const getListingSupport = async (tokenDetail) => {
    try {
        const response = await axios.get(BASE_URL_URL + getAllSupport,tokenDetail);
    
        return response?.data;
    } catch (error) {
        throw (error);
    }
}
const createTicketSupport = async(tokenDetail,userData) => {
    try {
     
        let formData=new FormData();
        formData.append("enquiry",userData.enquiry);
        formData.append("department",userData.department);
        formData.append("description",userData.description);
        formData.append("upload_document", userData.imageName);

        const response = await axios.post(BASE_URL_URL + createTicket,formData,tokenDetail);
        if(response){
            toast.success(response?.data?.message)
        }
        return response?.data;
    } catch (error) {
        if(error){
            toast.error(error?.data?.message)
        }
        console.log("___________________________________________________error", error);
        throw (error);
    }
}

const getAllDepartment = async () => {
    try {
        const response = await axios.get(BASE_URL_URL + getAllSupportDepartment);
  
        return response?.data;
    } catch (error) {
        console.log("___________________________________________________error", error);
        throw (error);
    }
}
const createThreadSupport = async (tokenDetail, userData) => {
    try {
        const response = await axios.post(BASE_URL_URL + createThread, userData, tokenDetail);
        if (response) {
            toast.success(response?.data?.message)
        }
        return response?.data;
    } catch (error) {
        console.log("________________________________________Error", error);
        if (error) {
            toast.error(error?.response?.data?.message)
        }
        throw error;
    }
}

const getAllThreadMessages = async (tokenDetail, userData) => {
    try {
        const response = await axios.post(BASE_URL_URL + getAllThread, userData, tokenDetail);
        return response?.data;
    } catch (error) {
        console.log("________________________________________Error", error);
        if (error) {
            toast.error(error?.response?.data?.message)
        }
        throw error;
    }
}



const getAllAdminMessages = async (tokenDetail, userData) => {
    try {
        const response = await axios.post(BASE_URL_URL + getAdminThread, userData, tokenDetail);
        return response?.data;
    } catch (error) {
        console.log("________________________________________Error", error);
        if (error) {
            toast.error(error?.response?.data?.message)
        }
        throw error;
    }
}


const getAllSupportDetail = async (tokenDetail, userData) => {
    try {
        const response = await axios.post(BASE_URL_URL + getAllSupport, userData, tokenDetail);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

const verifyOtpForGoogleAuth = async (tokenDetail, userData) => {
    try {
        const response = await axios.post(BASE_URL_URL + verifyOtp, userData, tokenDetail);
        if (response) {
            toast.success(response?.data?.message)
        }
        return response?.data;
    } catch (error) {
        if (error) {
            toast.error(error?.response?.data?.message)
        }
        throw error;
    }
}

const showMultiFactorData = async (tokenDetail, userData) => {
    try {
        const response = await axios.get(BASE_URL_URL + getMultiAuth, tokenDetail);

        // if (response?.data?.data?.token) {
        //     localStorage.setItem("multiAuthSession", JSON.stringify(response?.data?.data?.token))
        //     toast.success(response?.data?.message)
        // }
        return response?.data;
    } catch (error) {
        throw error;
    }
}

const postMultiFactorAuthenticate = async (tokenDetail, userData) => {
    try {
        const response = await axios.post(BASE_URL_URL + multiAuth, userData, tokenDetail);
        if (response) {
            toast.success(response?.data?.message)
        }
        return response?.data;
    } catch (error) {
        if (error) {
            toast.error(error?.response?.data?.message)
        }
        throw error;
    }
}

const getKycDetail = async (tokenDetail) => {
    try {
        const response = await axios.get(BASE_URL_URL + getKyc, tokenDetail);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

const createKycForUser = async(tokenDetail,userData) => {
    try {
        //return 
        let formData=new FormData();
        formData.append("firstname",userData.firstname);
        formData.append("lastname",userData.lastname);
        formData.append("country_code",userData.country_code);
        formData.append("mobile_no", userData.mobile_no);
        formData.append("email", userData.email);
        formData.append("city", userData.city);
        formData.append("state", userData.state);
        formData.append("pancard", userData.pancard);
        formData.append("nationality", userData.nationality);
        formData.append("postal_code", userData.postel_code);
        formData.append("residential_address", userData.residential_address);
        formData.append("aadhar_card_front", userData.aadhar_card_front);
        formData.append("aadhar_card_back", userData.aadhar_card_back);
        formData.append("identity_proof", userData.identity_proof);

        const response = await axios.post(BASE_URL_URL + createKyc,formData,tokenDetail);
        if(response){
            toast.success(response?.data?.message)
        }
        return response?.data;
    } catch (error) {
        if(error){
            toast.error(error?.response?.data?.message)
        }
        throw (error);
    }
}


const getBankingDetail = async (tokenDetail) => {
    try {
        const response = await axios.get(BASE_URL_URL + getBankDetail, tokenDetail);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

const createBankDetailForExchangeUser = async(userData,tokenDetail) => {
    try {
        let formData=new FormData();
        formData.append("account_holder_name",userData.account_holder_name);
        formData.append("account_number",userData.account_number);
        formData.append("ifsc_code",userData.ifsc_code);
        formData.append("bank_passbook", userData.bank_passbook);
        formData.append("bank_name", userData.bank_name);

        const response = await axios.post(BASE_URL_URL + createBankDetail,formData,tokenDetail);
        if(response){
            toast.success(response?.data?.message)
        }
        return response?.data;

    } catch (error) {
        if(error){
            toast.error(error?.response?.data?.message)
        }
        throw (error);
    }
};


//getting all currencies based on fiat and crypto
const getAllFiatCurrency = async (tokenDetail) => {
    try {
        const response = await axios.get(BASE_URL_URL + getFiatCurrencies,tokenDetail);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

const autoPopulateKycField = async (tokenDetail) => {
    try {
        const response = await axios.get(BASE_URL_URL + autoPopulateKyc,tokenDetail);
        return response?.data;
    } catch (error) {
        throw error;
    }
}


const editKycUser = async (userData,tokenDetail) => {
    try {
        let formData=new FormData();
        formData.append("firstname",userData.firstname);
        formData.append("lastname",userData.lastname);
        formData.append("country_code",userData.country_code);
        formData.append("mobile_no", userData.mobile_no);
        formData.append("email", userData.email);
        formData.append("city", userData.city);
        formData.append("state", userData.state);
        formData.append("pancard", userData.pancard);
        formData.append("nationality", userData.nationality);
        formData.append("postal_code", userData.postel_code);
        formData.append("residential_address", userData.residential_address);
        formData.append("aadhar_card_front", userData.aadhar_card_front);
        formData.append("aadhar_card_back", userData.aadhar_card_back);
        formData.append("identity_proof", userData.identity_proof);

        const response = await axios.post(BASE_URL_URL + editKyc, formData,tokenDetail);
        if(response){
            toast.success(response?.data?.message)
        }
        return response?.data;
    } catch (error) {
        throw error;
    }
}


//getting all currencies based on fiat and crypto
const gettingBankDetailForAdmin = async (tokenDetail) => {
    try {
        const response = await axios.get(BASE_URL_URL + gettingBankDetailByAdmin,tokenDetail);
        return response?.data;
    } catch (error) {
        throw error;
    }
}


//getting all crypto currency based on crypto
const gettingCryptoCurrencyForDeposit = async (tokenDetail) => {
    try {
        const response = await axios.get(BASE_URL_URL + getCryptoCurrencies,tokenDetail);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

//wallet api fetched from address
const getWalletApiFetchedFromAddress = async (userData,tokenDetail) => {
    try {
        const response = await axios.post(BASE_URL_URL + saveCryptoDepositAddress,userData,tokenDetail);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

//create deposit for crypto currency
const createDepositForCryptoCurrency = async (userData,tokenDetail) => {
    try {
        let formData= new FormData();
        formData.append("currency_id",userData?.currency_id);
        formData.append("amount",userData.amount);
        formData.append("remark",userData.remark);
        formData.append("attachment",userData.attachment)
        formData.append("bank_detail",userData.bank_detail);
        const response = await axios.post(BASE_URL_URL + createDeposit,formData,tokenDetail);
        if(response){
            toast.success(response?.data?.message)
        }
         return response?.data;
    } catch (error) {
        throw error;
    }
}

//getting all deposit based on fiat currency is below
const gettingDespositCurrencyBasedOnFiat = async (tokenDetail) => {
    try {
        const response = await axios.get(BASE_URL_URL + gettingDeposit,tokenDetail);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

//getting all deposit based on fiat currency is below
const transactionBasedOnCrypotoCurrency = async (userData,tokenDetail) => {
    try {
        const response = await axios.post(BASE_URL_URL + gettingTransactionBasedOnCurrency,userData,tokenDetail);
        return response?.data;
    } catch (error) {
        throw error;
    }
}


const BasicService = {
    CryptoRegister,
    makelogin,
    forgotPasswordMake,
    makerResetPassword,
    makeChagePassword,
    UserProfile,
    getAuthProfile,
    getAllDepartment,
    getListingSupport,
    createTicketSupport,
    createThreadSupport,
    getAllThreadMessages,
    getAllAdminMessages,
    getAllSupportDetail,
    verifyOtpForGoogleAuth,
    showMultiFactorData,
    postMultiFactorAuthenticate,
    getKycDetail,
    createKycForUser,
    createBankDetailForExchangeUser,
    getBankingDetail,
    getAllFiatCurrency,
    autoPopulateKycField,
    editKycUser,
    gettingBankDetailForAdmin,
    gettingCryptoCurrencyForDeposit,
    getWalletApiFetchedFromAddress,
    createDepositForCryptoCurrency,
    gettingDespositCurrencyBasedOnFiat,
    transactionBasedOnCrypotoCurrency
}
export default BasicService;